import Http from './http';
import { useConfiguracoesProdutos } from '../store/configuracoesProdutos';

export class ConfiguracoesProdutos {

    static get = function() {
        return Http.get('/Serv/ConfiguracoesProdutos/ConfiguracoesProdutos.php', {
            params: {
                'operacao': 'retornar',
            }
        });
    };

    static save = function(dados) {
        return Http.post('/Serv/ConfiguracoesProdutos/ConfiguracoesProdutos.php?operacao=gravar', dados);
    };

    static async inicializa() {
        const configuracoesProdutos = useConfiguracoesProdutos();
        try {
            let response = await Http.get('/Serv/ConfiguracoesProdutos/ConfiguracoesProdutos.php', {
                params: {
                    'operacao': 'retornar',
                }});
            if (response.status == 200) {
                configuracoesProdutos.setMargemPorcentagem(response.data.data.margemPorcentagem);
                configuracoesProdutos.setJaCarregado(true);                
            }
    
        }
        catch (error) {
            console.error(error);
        }
    }
}
