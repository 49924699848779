<script setup>
import { ref, onMounted, inject, defineEmits, defineProps, watch } from "vue";
import { PedidoCompra } from "../../resources/pedidoCompraResource";
import {Produto} from "../../resources/produtoResource";
import { useField, useForm } from "vee-validate";
import { string, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import ProdutoCreate from "../Produtos/ProdutoCreate.vue";
import ProdutoBusca from "../Produtos/ProdutoBusca.vue";
import { useCasasDecimais } from "../../store/casasDecimais";
import Moeda from "../Utils/moeda.vue";
import CodigoProduto from "../Utils/codigoProduto.vue";
import Quantidade from "../Utils/quantidade.vue";
import { Converte } from "../../utils/converte";
import UpdateProdutoPedidoCompra from "./UpdateProdutoPedidoCompra.vue";
import UltimasEntradas from "../MovimentacaoEstoque/UltimasEntradas.vue";
import { useRoute } from "vue-router";

const props = defineProps({
    isReadOnlyDadosProduto: {
        type: Boolean,
    },
});


const dialogProduto = ref(false);
const dialogBuscaProduto = ref(false);
const dialogUpdateProdutoPedidoCompra = ref(false);
const dialogUltimasEntradas = ref(false);
let showBtnGravar = ref(true);
const casasDecimaisStore = useCasasDecimais();
const auxProdutoPedidoCompra = ref({});
const route = useRoute();
const emit = defineEmits(['add-produto']);

const snackBarApp = inject("snackBarApp");
const isReadOnlyDadosProduto = ref(props.isReadOnlyDadosProduto);

const permissoesStore = usePermissoes();

watch(() => props.isReadOnlyDadosProduto, (newValue) => {
    isReadOnlyDadosProduto.value = newValue; // Update the value in the ref if needed
});


addMethod(string, "quantidade_maior_zero", function quantidadeMaiorZero(message) {
    return string().test(
        "quantidade-maior_zero", // Name
        message, // Msg
        function (quantidade) {
            if(Converte.stringToFloat(quantidade) > 0) {
                return true;
            }
            return false;
        }
    );
});


const validationSchema = object({
    produtoPedidoCompra: object({
        idPedidoCompra: undefined,
        idProduto: undefined, 
        codigoProduto: string().required("O código do produto é obrigatório").label("O código do produto"),
        codigoBarras: undefined,
        descricaoProduto: undefined,
        unidadeProduto: undefined,
        estoqueMinimo: undefined,
        estoqueMaximo: undefined,
        quantidadeAtual: undefined,
        maximoMenosAtual: undefined,
        quantidade: string().quantidade_maior_zero("A quantidade deve ser maior que 0."),
        valorUnitario: string().required("O valor unitário é obrigatório").label("O valor unitário"),
        valorTotal: string().required("O valor total é obrigatório").label("O valor total"),
    }).required(),
});
const { handleSubmit, errors } = useForm({ validationSchema });

const produtoPedidoCompra = ref({
    idPedidoCompra: useField("produtoPedidoCompra.idPedidoCompra").value,
    idProduto: useField("produtoPedidoCompra.idProduto").value,
    codigoProduto: useField("produtoPedidoCompra.codigoProduto").value,
    codigoBarras: useField("produtoPedidoCompra.codigoBarras").value,
    descricaoProduto: useField("produtoPedidoCompra.descricaoProduto").value,
    unidadeProduto: useField("produtoPedidoCompra.unidadeProduto").value,
    estoqueMinimo: useField("produtoPedidoCompra.estoqueMinimo").value,
    estoqueMaximo: useField("produtoPedidoCompra.estoqueMaximo").value,
    quantidadeAtual: useField("produtoPedidoCompra.quantidadeAtual").value,
    maximoMenosAtual: useField("produtoPedidoCompra.maximoMenosAtual").value,
    quantidade: useField("produtoPedidoCompra.quantidade").value,
    valorUnitario: useField("produtoPedidoCompra.valorUnitario").value,
    valorTotal: useField("produtoPedidoCompra.valorTotal").value,
});



const submit = handleSubmit((values, actions) => {
    addProduto(values, actions);
});

onMounted(() => {
    
    cleanFormProduto();

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});


function cleanFormProduto() {
    produtoPedidoCompra.value.idPedidoCompra = null;
    produtoPedidoCompra.value.idProduto = null;
    produtoPedidoCompra.value.codigoProduto = "";
    produtoPedidoCompra.value.codigoBarras = "";
    produtoPedidoCompra.value.descricaoProduto = "";
    produtoPedidoCompra.value.unidadeProduto = "";
    produtoPedidoCompra.value.estoqueMinimo = casasDecimaisStore.quantidadeProdutoZero;
    produtoPedidoCompra.value.estoqueMaximo = casasDecimaisStore.quantidadeProdutoZero;
    produtoPedidoCompra.value.quantidadeAtual = casasDecimaisStore.quantidadeProdutoZero;
    produtoPedidoCompra.value.maximoMenosAtual = casasDecimaisStore.quantidadeProdutoZero;
    produtoPedidoCompra.value.quantidade = casasDecimaisStore.quantidadeProdutoUm;
    produtoPedidoCompra.value.valorUnitario = casasDecimaisStore.pCustoProdutoZero;
    produtoPedidoCompra.value.valorTotal = 'R$ 0,00';
}


async function addProduto(values) {
    let idPedidoCompra = route.params.id;

    values.produtoPedidoCompra.idPedidoCompra = idPedidoCompra;

    if (values.produtoPedidoCompra.idProduto == null) {
        snackBarApp.value.open("Selecione um produto para incluir no pedido de compra.", 6000);
        return;
    }

    let dados = new FormData();
    dados.append("dados", JSON.stringify(values.produtoPedidoCompra));

    try {
        const response = await PedidoCompra.addProduto(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O produto não pode ser incluído no pedido de compra, mensagem: " + response.data.message, 6000);
                return;
            }

            snackBarApp.value.open("Produto incluído com sucesso no pedido de compra.", 1500);

            values.produtoPedidoCompra.idProdutoPedidoCompra = response.data.idProdutoPedidoCompra;

            values.produtoPedidoCompra.valorTotal = Converte.stringToFloat(values.produtoPedidoCompra.valorTotal).toString();
            values.produtoPedidoCompra.quantidade = Converte.stringToFloat(values.produtoPedidoCompra.quantidade).toString();
            emit('add-produto', values.produtoPedidoCompra);

            cleanFormProduto();

            document.getElementById("codigoProduto").focus();
        }
    } catch (err) {
        console.log(err);
    }
}


async function getProduto(campo) {

    let valor = "";

    if(campo == 'codigoBarras') {
        valor = produtoPedidoCompra.value.codigoBarras;
        if(valor == "") {
            return;
        }
    } else
    {
        valor = produtoPedidoCompra.value.codigoProduto;
    }

    if(valor.trim() == '') {
        
        produtoPedidoCompra.value.codigoBarras = '';
        produtoPedidoCompra.value.descricaoProduto = '';
        produtoPedidoCompra.value.unidadeProduto = '';
        produtoPedidoCompra.value.quantidadeAtual = casasDecimaisStore.quantidadeProdutoZero;
        if(campo == 'codigo') {
            produtoPedidoCompra.value.idProduto =  null;
        }
        calculaTotal();
        return;
    }

    if(campo == 'codigo') {
        valor = Converte.codigoProduto(valor);
    }

    produtoPedidoCompra.value.idProduto = null;
    produtoPedidoCompra.value.descricaoProduto = "";
    produtoPedidoCompra.value.unidadeProduto = "";
    if(campo == "codigo") {
        produtoPedidoCompra.value.codigoBarras = "";
    }
    else {
        produtoPedidoCompra.value.codigoProduto = "";
    }


    produtoPedidoCompra.value.quantidadeAtual = casasDecimaisStore.produtoQuantidadeZero;

    let dados = new FormData();
    dados.append('codigoCodigoBarras', valor);
    dados.append('campo', campo);
    dados.append("codigoModulo", "03.13");

    try {
        let response = await Produto.getByCodigoCodigoBarras(dados);
        if (response.status == 200) {
            if(!response.data.data) {
                if(campo == 'codigo') {
                    produtoPedidoCompra.value.codigoProduto = '';
                    document.getElementById("codigoProduto").focus();
                }
                if(campo == 'codigoBarras') {
                    produtoPedidoCompra.value.codigoBarras = '';
                    document.getElementById("codigoBarras").focus();
                }
                snackBarApp.value.open("Produto não encontrado, tente outro código ou faça uma busca.", 6000);
                calculaTotal();
                return;
            }

            if(response.data.data.ativoInativoBloqueado == 'I') {
                snackBarApp.value.open("Produto inativo. O produto '"+ (response.data.data.codigo).trim()+"/" + response.data.data.descricao+ 
                       "' está inativo, um produto inativo não pode ser incluído em pedidos de compras.", 6000);
                if(campo == 'codigo') {
                    produtoPedidoCompra.value.codigoProduto = '';
                    document.getElementById("codigoProduto").focus();
                }
                if(campo == 'codigoBarras') {
                    produtoPedidoCompra.value.codigoBarras = '';
                    document.getElementById("codigoBarras").focus();
                }

                calculaTotal();
                return;
            }
            if(response.data.data.ativoInativoBloqueado == 'B') {

                snackBarApp.value.open("Produto bloqueado. O produto '"+ (response.data.data.codigo).trim()+"/" + response.data.data.descricao+
                    "' está bloqueado, um produto bloqueado não pode ser incluído em pedidos de compras.", 6000);
                if(campo == 'codigo') {
                    produtoPedidoCompra.value.codigoProduto = '';
                    document.getElementById("codigoProduto").focus();
                }
                if(campo == 'codigoBarras') {
                    produtoPedidoCompra.value.codigoBarras = '';
                    document.getElementById("codigoBarras").focus();
                }

                calculaTotal();
                return;
            }

            produtoPedidoCompra.value.idProduto = response.data.data.idProduto;
            produtoPedidoCompra.value.codigoProduto = response.data.data.codigo;
            produtoPedidoCompra.value.descricaoProduto = response.data.data.descricao;
            produtoPedidoCompra.value.codigoBarras = response.data.data.codigoBarras;

            produtoPedidoCompra.value.unidadeProduto = response.data.data.unidade;
            produtoPedidoCompra.value.quantidadeAtual = Converte.numberToQuantidadeProduto(response.data.data.quantidade);
            produtoPedidoCompra.value.estoqueMinimo = Converte.numberToQuantidadeProduto(response.data.data.estoqueMinimo);
            produtoPedidoCompra.value.estoqueMaximo = Converte.numberToQuantidadeProduto(response.data.data.estoqueMaximo);
            produtoPedidoCompra.value.maximoMenosAtual = Converte.numberToQuantidadeProduto(response.data.data.estoqueMaximo - response.data.data.quantidade);
            produtoPedidoCompra.value.valorUnitario = Converte.numberToPCustoProduto(response.data.data.pCusto);
            calculaTotal();
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouProduto(produto) {
    if (produto == null) {
        return;
    }

    produtoPedidoCompra.value.codigoProduto = produto.codigo ;
    getProduto('codigo');

    document.getElementById("quantidade").focus();

    dialogBuscaProduto.value = false;
}


function calculaTotal() {
    let quantidade = Converte.stringToFloat(produtoPedidoCompra.value.quantidade);
    let valorUnitario = Converte.stringToFloat(produtoPedidoCompra.value.valorUnitario);
    let total = quantidade * valorUnitario;
    produtoPedidoCompra.value.valorTotal = Converte.numberToCurrency(total);
}

function cadastrouProduto(codigoProduto) {
    if (codigoProduto == null) {
        return;
    }
    produtoPedidoCompra.value.codigoProduto = codigoProduto;
    getProduto('codigo');
}


</script>

<template>


        <v-card class="mb-8" elevation="16" >
            <v-card-title>
                <h3>Inclusão de Produtos no Pedido de Compra</h3>
            </v-card-title>
            <form ref="formProdutoPedidoCompra" @submit.prevent="submit">
                <v-card-text>
                    <v-row>
                        <v-col cols="2">
                            <CodigoProduto
                                label="Código do Produto"
                                id="codigoProduto"
                                type="text"
                                v-model="produtoPedidoCompra.codigoProduto"
                                :error-messages="errors['produtoPedidoCompra.codigoProduto']"
                                maxlength="15"
                                :readOnly="isReadOnlyDadosProduto"
                                @blur="getProduto('codigo')"
                                v-tooltip="'F5-Abre a busca de produtos; F7-Cadastra um novo produto.'"
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                label="Código de Barras"
                                id="codigoBarras"
                                type="text"
                                v-model="produtoPedidoCompra.codigoBarras"
                                :error-messages="errors['produtoPedidoCompra.codigoBarras']"
                                maxlength="14"
                                :readOnly="isReadOnlyDadosProduto"
                                @blur="getProduto('codigoBarras')"
                                v-tooltip="'F5-Abre a busca de produtos; F7-Cadastra um novo produto.'"
                            />
                        </v-col>
                        <v-col cols="8">
                            <v-text-field label="Descrição do Produto" id="descricaoProduto" type="text" v-model="produtoPedidoCompra.descricaoProduto" readonly tabindex="-1">
                                <template v-slot:append v-if="route.name == 'PedidosCompras.Create' || route.name == 'PedidosCompras.Continuar'">
                                    <v-tooltip text="Faz a busca de um produto pela descrição, referência.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" color="primary" @click="dialogBuscaProduto = true" id="btnBuscaProduto">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                    <v-dialog v-model="dialogBuscaProduto" transition="dialog-top-transition">
                                        <ProdutoBusca 
                                            :select-inativo-bloqueado=false
                                            @selecionouProduto="selecionouProduto" 
                                            @close-dialog="dialogBuscaProduto = false;" />
                                    </v-dialog>
                                    &nbsp;
                                    <v-tooltip text="Cadastra um novo produto.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" color="primary" @click="dialogProduto = true" id="btnNovoProduto" >
                                                <v-icon>mdi-invoice-text-plus</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                    <v-dialog v-model="dialogProduto" transition="dialog-top-transition">
                                        <ProdutoCreate @cadastrouProduto="cadastrouProduto" @close-dialog="dialogProduto = false" />
                                    </v-dialog>
                                    &nbsp;
                                    <v-tooltip text="Consulta as últimas entradas do produto.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" color="primary" @click="dialogUltimasEntradas = true" id="btnUltimasEntradas">
                                                <v-icon>mdi-invoice-list-outline</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                    <v-dialog v-model="dialogUltimasEntradas" transition="dialog-top-transition">
                                        <UltimasEntradas :idProduto="produtoPedidoCompra.idProduto"
                                            :codigoProduto = "produtoPedidoCompra.codigoProduto"
                                            :descricaoProduto = "produtoPedidoCompra.descricaoProduto" 
                                            @close-dialog="dialogUltimasEntradas = false" />
                                    </v-dialog>
                                </template>                    
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-text-field label="Unid." id="unidade" type="text" v-model="produtoPedidoCompra.unidadeProduto" readonly tabindex="-1">
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field label="Estoque Mínimo" id="estoqueMinimo" 
                                type="text" v-model="produtoPedidoCompra.estoqueMinimo" 
                                tipo="quantidadeProduto" readonly tabindex="-1">
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field label="Estoque Máximo" id="estoqueMaximo" 
                                type="text" v-model="produtoPedidoCompra.estoqueMaximo" 
                                tipo="quantidadeProduto" readonly tabindex="-1">
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field label="Quantidade Atual" id="quantidadeAtual" 
                                type="text" v-model="produtoPedidoCompra.quantidadeAtual" 
                                tipo="quantidadeProduto" readonly tabindex="-1">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-text-field label="Máximo - Atual" id="maximoMenosAtual" 
                                type="text" v-model="produtoPedidoCompra.maximoMenosAtual" 
                                tipo="quantidadeProduto" readonly tabindex="-1">
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <Quantidade label="Quantidade" id="quantidade" type="text" v-model="produtoPedidoCompra.quantidade"  tipo="quantidadeProduto"
                                :error-messages="errors['produtoPedidoCompra.quantidade']" maxlength="15"
                                :readOnly="isReadOnlyDadosProduto"
                                @blur="calculaTotal()" />
                        </v-col>
                        <v-col cols="3">
                            <Moeda label="Valor Unitário" id="valorUnitario" type="text" v-model="produtoPedidoCompra.valorUnitario" 
                                :error-messages="errors['produtoPedidoCompra.valorUnitario']" maxlength="15" tipo="pCustoProduto"
                                :readOnly="isReadOnlyDadosProduto"
                                @blur="calculaTotal()" />
                        </v-col>
                        <v-col cols="3">
                            <Moeda label="Valor Total" id="valorTotal" type="text" v-model="produtoPedidoCompra.valorTotal" 
                                :error-messages="errors['produtoPedidoCompra.valorTotal']" maxlength="15" readonly tabindex="-1" />
                        </v-col>
                    </v-row>    

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" v-show="$route.name !== 'PedidosCompras.Show' && showBtnGravar" 
                        variant="elevated" 
                        type="submit" 
                        id="btnAddProdutoGravar" 
                        :disabled="isReadOnlyDadosProduto">Incluir Produto</v-btn>
                </v-card-actions>
            </form>

            <v-dialog v-model="dialogUpdateProdutoPedidoCompra" transition="dialog-top-transition">
                <UpdateProdutoPedidoCompra :produtoPedidoCompra = auxProdutoPedidoCompra 
                    @close-dialog="dialogUpdateProdutoPedidoCompra = false" 
                    @alterou-item="filtrar()" />
            </v-dialog> 
        </v-card>
</template>

