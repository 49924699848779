<script setup>
import { ref, onMounted } from "vue";
import {Login} from '../resources/loginResource';
import {CasasDecimais} from "../resources/casasDecimaisResource";
import {ConfiguracoesContasReceber} from "../resources/configuracoesContasReceberResource";
import {Permissoes} from "../resources/permissoesResource";
import {PermissaoService} from "../services/permissaoService";
import {ConfiguracoesOrdensServicos} from "../resources/configuracoesOrdensServicosResource";
import {ConfiguracoesProdutos} from "../resources/configuracoesProdutosResource";
import { useRouter } from "vue-router";
import TelaDemo from "../components/Telas/TelaDemo.vue";
import TelaDemo2 from "../components/Telas/TelaDemo2.vue";
import TelaPaga from "../components/Telas/TelaPaga.vue";

import { usePermissoes } from "../store/permissoes";
import { useLogado} from "../store/logado";
import { usePermissoesNegadas } from "../store/permissoesNegadas";


const permissoesStore = usePermissoes();
const logadoStore = useLogado();
const permissoesNegadasStore = usePermissoesNegadas();
const router = useRouter();
const dataValidade = ref('');

const dialogTelaDemo = ref(false);
const dialogTelaDemo2 = ref(false);
const dialogTelaPaga = ref(false);

const login = ref({ email: "", login: "", senha: "", lembrar: true });

onMounted(() => {
    logadoStore.setLogado(false);

    if(localStorage.getItem('email')) {
        login.value.email = localStorage.getItem('email');
    }
    if(localStorage.getItem('login')) {
        login.value.login = localStorage.getItem('login');
    }

});

async function logar() {
    const dados = new FormData();
    dados.append('emailCnpj', login.value.email);
    dados.append('login', login.value.login);
    dados.append('senha', login.value.senha);
    try {
        const response = await  Login.efetuarLogin(dados);
        if(response.data.success == false) {
            alert(response.data.message);
            return;
        }
        if(response.data.success == true) {
            logadoStore.setLogado(true);
            permissoesStore.setTipo(response.data.tipo);
            permissoesStore.setValidade(response.data.validade);
            permissoesStore.setDataValidade(response.data.dataValidade);
            let tipo = response.data.tipo;
            let validade = response.data.validade;
            dataValidade.value = response.data.dataValidade
            CasasDecimais.inicializa();
            ConfiguracoesContasReceber.inicializa();
            ConfiguracoesOrdensServicos.inicializa();
            ConfiguracoesProdutos.inicializa();

            const responsePermissoes = await Permissoes.listarNegadas();
            let permissoes = responsePermissoes.data.data;
            permissoesNegadasStore.setPermissoesNegadas(permissoes);
            PermissaoService.setaPermissoes();

            if(tipo == 'D' && validade == 'N') {
                abreTelaDemo();
                return;
            }

            if(tipo == 'D' && validade == 'S') {
                abreTelaDemo2();
                return;
            }

            if(tipo == 'P' && validade == 'N') {
                abreTelaPaga();
                return;
            }

            if(login.value.lembrar)  {
                localStorage.setItem('email', login.value.email);
                localStorage.setItem('login', login.value.login);

            }
            else {
                localStorage.removeItem('email');
                localStorage.removeItem('login');
            }

            router.push({name: 'Home'});
            return;
        }

        alert('Não foi possível logar no sistema');
    } catch (err) {
            console.log(err);
    }
}

function  abreTelaDemo() {
    dialogTelaDemo.value = true;
}

function  abreTelaDemo2() {
    dialogTelaDemo2.value = true;
}

function abreTelaPaga() {
    dialogTelaPaga.value = true;
}

</script>

<template>
    <div class="d-flex align-center flex-column">
        <v-card elevation="16"  max-width="500">
            <form ref="formLogin" @submit.prevent="submit">
                <v-card-title>
                    <h4>Login/Novo Cadastro do Sistema OS Gold</h4>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Email ou CPF/CNPJ"
                                id="email"
                                type="text"
                                v-model="login.email"
                                autofocus
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Login"
                                id="login"
                                type="text"
                                v-model="login.login"
                            />

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Senha"
                                id="senha"
                                type="password"
                                v-model="login.senha"
                            />

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-checkbox label="Lembrar Email/CNPJ e Login" v-model="login.lembrar" />
                        </v-col>
                    </v-row>


                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" variant="elevated" @click="logar">
                        Login
                    </v-btn>
                    <v-btn color="primary" variant="elevated" :to="{name: 'NovoCadastro.NovoCadastro'}">Cadastre-se</v-btn>
                </v-card-actions>
            </form>
        </v-card>

        <v-dialog v-model="dialogTelaDemo" transition="dialog-top-transition" persistent max-width="800">
            <TelaDemo @close-dialog="dialogTelaDemo = false;   router.push({name: 'Home'});" />
        </v-dialog>


        <v-dialog v-model="dialogTelaDemo2" transition="dialog-top-transition" persistent max-width="800">
            <TelaDemo2 :dataValidade="dataValidade" @close-dialog="dialogTelaDemo2 = false;   router.push({name: 'Home'});" />
        </v-dialog>

        <v-dialog v-model="dialogTelaPaga" transition="dialog-top-transition" persistent max-width="800">
            <TelaPaga :dataValidade="dataValidade" @close-dialog="dialogTelaPaga = false;   router.push({name: 'Home'});" />
        </v-dialog>


    </div>
</template>