import Http from './http';

export class OrdemServicos {
    static listar = function(start, limit, sort, filtrarPor, dataInicial, dataFinal, abertas, canceladas, finalizadas, idCliente, filtro, tipoFiltro, nomeCampo) {
        return Http.get('/Serv/OrdemServicos/OrdemServicos.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'sort':sort,
                'filtrarPor': filtrarPor,
                'dataInicial': dataInicial,
                'dataFinal': dataFinal,
                'abertas': abertas,
                'canceladas': canceladas,
                'finalizadas': finalizadas,
                'idCliente': idCliente,
                'filtro': filtro,
                'tipoFiltro': tipoFiltro,
                'nomeCampo': nomeCampo,
            }
        });
    };

    static gravar = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=gravar', dados);
    };

    static proximoCodigo = function() {
        return Http.get('/Serv/OrdemServicos/OrdemServicos.php?operacao=proximocodigo');
    };

    static codigoExiste = function(codigo, idOrdemServicos) {
        let dados = new FormData();
        dados.append('codigo', codigo);
        dados.append('idOrdemServicos', idOrdemServicos);
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=codigoexiste', dados);
    };

    static getById = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=getbyid', dados);
    };

    static excluir = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=excluir', dados);
    };

    static cancelar = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=cancelar', dados);
    };

    static addProduto = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=addproduto', dados);
    };

    static listarProdutos = function(idOrdemServicos) {
        return Http.get('/Serv/OrdemServicos/OrdemServicos.php?operacao=listarprodutos', {
            params: {
                'idOrdemServicos':idOrdemServicos,
            }
        });
    };

    static addServico = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=addservico', dados);
    };

    static listarServicos = function(idOrdemServicos) {
        return Http.get('/Serv/OrdemServicos/OrdemServicos.php?operacao=listarservicos', {
            params: {
                'idOrdemServicos':idOrdemServicos,
            }
        });
    };


    static totais = function(idOrdemServicos) {
        return Http.get('/Serv/OrdemServicos/OrdemServicos.php?operacao=totais', {
            params: {
                'idOrdemServicos':idOrdemServicos,
            }
        });
    };

    static removeProduto = function(idProdutoOrdemServicos) {
        let dados = new FormData();
        dados.append('idProdutoOrdemServicos', idProdutoOrdemServicos);

        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=removeproduto', dados);
    };

    static removeServico = function(idServicoOrdemServicos) {
        let dados = new FormData();
        dados.append('idServicoOrdemServicos', idServicoOrdemServicos);

        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=removeservico', dados);
    };
    static updateProduto = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=updateproduto', dados);
    };
    static updateServico = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=updateservico', dados);
    };
    static getObservacoes = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=getobservacoes', dados);
    };
    static gravarObservacoes = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=gravarobservacoes', dados);
    };

    static getObservacoesInternas = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=getobservacoesinternas', dados);
    };
    static gravarObservacoesInternas = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=gravarobservacoesinternas', dados);
    };
    static getAdiantamento = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=getadiantamento', dados);
    };
    static gravarAdiantamento = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=gravaradiantamento', dados);
    };
    static finalizaAVista = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=finalizaavista', dados);
    };
    static finalizaAPrazo = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=finalizaaprazo', dados);
    };
    static getParcelas = function(idOrdemServicos) {
        return Http.get('/Serv/OrdemServicos/OrdemServicos.php', {
            params: {
                'operacao': 'getparcelas',
                'idOrdemServicos':idOrdemServicos,
            }
        });
    };

    static getCheques = function(idOrdemServicos) {
        return Http.get('/Serv/OrdemServicos/OrdemServicos.php', {
            params: {
                'operacao': 'getcheques',
                'idOrdemServicos':idOrdemServicos,
            }
        });
    };
    static finalizaCheque = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=finalizacheque', dados);
    };
    static finalizaCartao = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=finalizacartao', dados);
    };

    static duplica = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=duplica', dados);
    };

    static alterarCodigo = function(dados) {
        return Http.post('/Serv/OrdemServicos/OrdemServicos.php?operacao=alterarcodigo', dados);
    };



}
