<script setup>
import { ref, onMounted, inject, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { OrcamentoOrdemServicos } from "../../resources/orcamentoOrdemServicosResource";
import { Cliente } from "../../resources/clienteResource";
import { Vendedor } from "../../resources/vendedorResource";
import { Relatorio } from "../../resources/relatoriosResource";
import config from "../../services/config";
import { useField, useForm } from "vee-validate";
import { string, number, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import ClienteCreate from "../Clientes/ClienteCreate.vue";
import ClienteBusca from "../Clientes/ClienteBusca.vue";
import VendedorBusca from "../Vendedores/VendedorBusca.vue";
import ProdutoOrcamentoOrdemServicos from "./ProdutoOrcamentoOrdemServicos.vue";
import ServicoOrcamentoOrdemServicos from "./ServicoOrcamentoOrdemServicos.vue";
import { Converte } from "../../utils/converte";
import ObservacoesOrcamentoOrdemServicos from "./ObservacoesOrcamentoOrdemServicos.vue";
import ObservacoesInternasOrcamentoOrdemServicos from "./ObservacoesInternasOrcamentoOrdemServicos.vue";
import CondicoesPagamentoOrcamentoOrdemServicos from "./CondicoesPagamentoOrcamentoOrdemServicos.vue";
import DescontosOrcamentoOrdemServicos from "./DescontosOrcamentoOrdemServicos.vue";
import { quantidadeProdutoFormat, quantidadeServicoFormat, valorFormat } from "../../services/filtros";
import { useConfiguracoesOrdensServicos } from "../../store/configuracoesOrdensServicos";
import UpdateProdutoOrcamentoOrdemServicos from "./UpdateProdutoOrcamentoOrdemServicos.vue";
import UpdateServicoOrcamentoOrdemServicos from "./UpdateServicoOrcamentoOrdemServicos.vue";
import Data from "../Utils/data.vue";


const configuracoesOrdensServicosStore = useConfiguracoesOrdensServicos();
const isRealValidation = ref(null);
const validationCodigo = ref(null);
const isReadOnly = ref(false);
const route = useRoute();
const router = useRouter();
const dialogCliente = ref(false);
const dialogBuscaCliente = ref(false);
const dialogBuscaVendedor = ref(false);
const dialogObservacoes = ref(false);
const dialogObservacoesInternas = ref(false);
const dialogProdutoOrcamentoOrdemServicos = ref(false);
const dialogServicoOrcamentoOrdemServicos = ref(false);
const dialogUpdateProdutoOrcamentoOrdemServicos = ref(false);
const dialogUpdateServicoOrcamentoOrdemServicos = ref(false);
const dialogDescontos = ref(false);
const dialogCondicoesPagamento = ref(false);
const operacao = ref("");

let showBtnGravar = ref(true);
const produtosOrcamentoOrdemServicos = ref([]);
const servicosOrcamentoOrdemServicos = ref([]);

const situacao = ref("");
const totalProdutos = ref(0);
const totalServicos = ref(0);
const total = ref(0);
const valorDescontoProdutos = ref(0);
const valorDescontoServicos = ref(0);
const valorTotalDescontos = ref(0);
const valorTotalComDesconto = ref(0);

const labelCampo1 = ref(configuracoesOrdensServicosStore.labelCampo1);
const labelCampo2 = ref(configuracoesOrdensServicosStore.labelCampo2);
const labelCampo3 = ref(configuracoesOrdensServicosStore.labelCampo3);
const labelCampo4 = ref(configuracoesOrdensServicosStore.labelCampo4);
const labelCampo5 = ref(configuracoesOrdensServicosStore.labelCampo5);
const jaCarregado = ref(configuracoesOrdensServicosStore.jaCarregado);

const snackBarApp = inject("snackBarApp");

const permissoesStore = usePermissoes();

addMethod(number, "codigo_orcamento_ordem_servicos_existe", function codigoOrcamentoOrdemServicosExiste(message) {
    return number().test(
        "codigo-orcamento_ordem-servicos-existe", // Name
        message, // Msg
        function (codigo) {
            if (isRealValidation.value == null) {
                return true;
            }
            if (!isRealValidation.value) {
                return validationCodigo.value;
            }
            isRealValidation.value = false;
            validationCodigo.value = false;

            return new Promise((resolve) => {
                OrcamentoOrdemServicos.codigoExiste(codigo, orcamentoOrdemServicos.value.idOrcamentoOrdemServicos).then((res) => {
                    if (res.data == false) {
                        validationCodigo.value = true;
                        resolve(true);
                    }
                    resolve(false);
                });
            });
        }
    );
});

const validationSchema = object({
    orcamentoOrdemServicos: object({
        idOrcamentoOrdemServicos: undefined,
        codigo: number()
            .min(1)
            .max(999999999)
            .codigo_orcamento_ordem_servicos_existe("Esse código já está sendo usado por outro orçamento de ordem de serviços, tente outro código.")
            .typeError("O código deve ser um número")
            .label("O código"),
        data: string().required("A data é obrigatória").label("A data"),
        validade: undefined,
        idCliente: undefined,
        codigoCliente: undefined,
        nomeCliente: undefined,
        idVendedor: undefined,
        codigoVendedor: undefined,
        nomeVendedor: undefined,
        campo1: string().max(50),
        campo2: string().max(50),
        campo3: string().max(50),
        campo4: string().max(50),
        campo5: string().max(50),
        situacao: undefined,
        prazo: number().min(0).max(9999).typeError("O prazo deve ser um número").label("O prazo"),
    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const orcamentoOrdemServicos = ref({
    idOrcamentoOrdemServicos: useField("orcamentoOrdemServicos.idOrcamentoOrdemServicos").value,
    codigo: useField("orcamentoOrdemServicos.codigo").value,
    data: useField("orcamentoOrdemServicos.data").value,
    validade: useField("orcamentoOrdemServicos.validade").value,
    idCliente: useField("orcamentoOrdemServicos.idCliente").value,
    codigoCliente: useField("orcamentoOrdemServicos.codigoCliente").value,
    nomeCliente: useField("orcamentoOrdemServicos.nomeCliente").value,
    idVendedor: useField("orcamentoOrdemServicos.idVendedor").value,
    codigoVendedor: useField("orcamentoOrdemServicos.codigoVendedor").value,
    nomeVendedor: useField("orcamentoOrdemServicos.nomeVendedor").value,
    campo1: useField("orcamentoOrdemServicos.campo1").value,
    campo2: useField("orcamentoOrdemServicos.campo2").value,
    campo3: useField("orcamentoOrdemServicos.campo3").value,
    campo4: useField("orcamentoOrdemServicos.campo4").value,
    campo5: useField("orcamentoOrdemServicos.campo5").value,
    situacao: useField("orcamentoOrdemServicos.situacao").value,
    prazo: useField("orcamentoOrdemServicos.prazo").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});


onMounted(() => {
    if (jaCarregado.value == false) {
        setTimeout(() => {
            labelCampo1.value = configuracoesOrdensServicosStore.labelCampo1;
            labelCampo2.value = configuracoesOrdensServicosStore.labelCampo2;
            labelCampo3.value = configuracoesOrdensServicosStore.labelCampo3;
            labelCampo4.value = configuracoesOrdensServicosStore.labelCampo4;
            labelCampo5.value = configuracoesOrdensServicosStore.labelCampo5;
        }, 1000);
    }

    cleanForm();
    isReadOnly.value = false;

    if (route.name == "OrcamentosOrdensServicos.Continuar" || route.name == "OrcamentosOrdensServicos.Show") {
        getOrcamentoOrdemServicos(route.params.id);
    } else {
        document.getElementById("codigo").focus();
    }

    if (route.name == "OrcamentosOrdensServicos.Show") {
        isReadOnly.value = true;
        operacao.value = 'C';
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name == "OrcamentosOrdensServicos.Create") {
        orcamentoOrdemServicos.value.idOrcamentoOrdemServicos = null;
        proximoCodigo();
    }

    orcamentoOrdemServicos.value.data = new Date().toLocaleDateString("pt-BR");
    orcamentoOrdemServicos.value.validade = "";
    orcamentoOrdemServicos.value.idCliente = null;
    orcamentoOrdemServicos.value.codigoCliente = "";
    orcamentoOrdemServicos.value.nomeCliente = "";
    orcamentoOrdemServicos.value.idVendedor = null;
    orcamentoOrdemServicos.value.codigoVendedor = "";
    orcamentoOrdemServicos.value.nomeVendedor = "";
    orcamentoOrdemServicos.value.situacao = "A";
    orcamentoOrdemServicos.value.campo1 = "";
    orcamentoOrdemServicos.value.campo2 = "";
    orcamentoOrdemServicos.value.campo3 = "";
    orcamentoOrdemServicos.value.campo4 = "";
    orcamentoOrdemServicos.value.campo5 = "";
    orcamentoOrdemServicos.value.prazo = 0;
}


async function gravar(values) {
    let dados = new FormData();

    values.orcamentoOrdemServicos.data = Converte.datePtBrToIso(values.orcamentoOrdemServicos.data);

    if (values.orcamentoOrdemServicos.idCliente == "") {
        values.orcamentoOrdemServicos.idCliente = null;
    }

    let validade = '';

    if (values.orcamentoOrdemServicos.validade == '') {
        validade = null;
    } else {
        validade = Converte.datePtBrToIso(values.orcamentoOrdemServicos.validade);
    }

    values.orcamentoOrdemServicos.validade = validade;

    dados.append("dados", JSON.stringify(values.orcamentoOrdemServicos));

    if (route.name == 'OrcamentosOrdensServicos.Create' || route.name == 'OrcamentosOrdensServicos.Continuar') {
        try {
            const response = await OrcamentoOrdemServicos.gravar(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open("O orçamento de ordem de serviços não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                    return;
                }


                if (route.name == 'OrcamentosOrdensServicos.Create') {
                    let idOrcamentoOrdemServicos = response.data.idOrcamentoOrdemServicos;
                    orcamentoOrdemServicos.value.idOrcamentoOrdemServicos = idOrcamentoOrdemServicos;
                    snackBarApp.value.open("Novo orçamento de ordem de serviços gravado com sucesso.");
                    router.push({ name: 'OrcamentosOrdensServicos.Continuar', params: { id: idOrcamentoOrdemServicos } });

                    setTimeout(function () {

                    }, 200);

                }

                if (route.name == 'OrcamentosOrdensServicos.Continuar') {
                    snackBarApp.value.open("Orçamento de ordem de serviços gravado com sucesso.");
                }
            }
        } catch (err) {
            snackBarApp.value.open("Erro, mensagem de erro: " + err);
        }
    }
}

async function getOrcamentoOrdemServicos(id) {
    let dados = new FormData();

    if (route.name == "OrcamentosOrdensServicos.Continuar") {
        dados.append("codigoModulo", "03.05.02");
    } else if (route.name == "OrcamentosOrdensServicos.Show") {
        dados.append("codigoModulo", "03.05.03");
    }

    dados.append("idOrcamentoOrdemServicos", id);

    try {
        let response = await OrcamentoOrdemServicos.getById(dados)
        if (response.status == 200) {

            response.data.data.data = Converte.dateIsoToPtBr(response.data.data.data);
            response.data.data.validade = Converte.dateIsoToPtBr(response.data.data.validade);
            response.data.data.valorTotal = Converte.numberToCurrency(response.data.data.valorTotal);

            let sit = response.data.data.situacao;

            if(route.name == 'OrcamentosOrdensServicos.Continuar' && sit == 'T') {
                snackBarApp.value.open('Orçamento de ordem de serviços já transformado em ordem de serviços não pode ser alterada, '+
                    'entrando na consulta desse orçamento de ordem de serviços.');
                router.push({ name: 'OcamentosOrdensServicos.Show', params: { id: id } });
            }

            if (sit == "A") {
                situacao.value = "Situação do orçamento de ordem de serviços: Em Aberto";

            } else if (sit == 'T') {
                situacao.value = "Situação do orçamento de ordem de serviços: Transformado em Ordem de Serviços";

            } else if (sit == 'C') {
                situacao.value = "Situação do orçamento de ordem de serviços: Cancelado";
            }

            setValues({ orcamentoOrdemServicos: response.data.data });
            getProdutos(response.data.data.idOrcamentoOrdemServicos);
            getServicos(response.data.data.idOrcamentoOrdemServicos);
        }
    }
    catch (err) {
        console.log(err);
    }
}

async function proximoCodigo() {
    try {
        const response = await OrcamentoOrdemServicos.proximoCodigo();
        if (response.status == 200) {
            orcamentoOrdemServicos.value.codigo = response.data;
        }
    } catch (err) {
        console.log(err);
    }
}

async function getCliente() {
    orcamentoOrdemServicos.value.idCliente = null;
    orcamentoOrdemServicos.value.nomeCliente = "";

    if (orcamentoOrdemServicos.value.codigoCliente == null || orcamentoOrdemServicos.value.codigoCliente.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", orcamentoOrdemServicos.value.codigoCliente);
    dados.append("getInativos", false);
    dados.append("codigoModulo", "03.05");

    try {
        let response = await Cliente.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    orcamentoOrdemServicos.value.codigoCliente = "";
                    document.getElementById("codigoCliente").focus();
                    snackBarApp.value.open("Um cliente inativo não pode gerar novos orçamentos de ordens de serviços.", 6000);
                    return;
                }
                if (response.data.message == "bloqueado") {
                    orcamentoOrdemServicos.value.codigoCliente = "";
                    document.getElementById("codigoCliente").focus();
                    snackBarApp.value.open("Um cliente bloqueado não pode gerar novos orçamentos de ordens de serviços.", 6000);
                    return;
                }
                orcamentoOrdemServicos.value.codigoCliente = "";
                document.getElementById("codigoCliente").focus();
                snackBarApp.value.open("Cliente não encontrado, tente outro código ou faça uma busca.", 6000);
                return;
            }
            orcamentoOrdemServicos.value.idCliente = response.data.data.idCliente;
            orcamentoOrdemServicos.value.nomeCliente = response.data.data.nome;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouCliente(cliente) {
    if (cliente == null) {
        return;
    }

    orcamentoOrdemServicos.value.codigoCliente = cliente.codigo;
    orcamentoOrdemServicos.value.nomeCliente = cliente.nome;
    orcamentoOrdemServicos.value.idCliente = cliente.idCliente;

    getCliente();
    dialogBuscaCliente.value = false;

    setTimeout(() => {
        document.getElementById("codigoVendedor").focus();
    }, 100);
}

async function getVendedor() {
    orcamentoOrdemServicos.value.idVendedor = null;
    orcamentoOrdemServicos.value.nomeVendedor = "";

    if (orcamentoOrdemServicos.value.codigoVendedor == null || orcamentoOrdemServicos.value.codigoVendedor.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", orcamentoOrdemServicos.value.codigoVendedor);
    dados.append("getInativos", false);
    dados.append("codigoModulo", "03.05");

    try {
        let response = await Vendedor.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    orcamentoOrdemServicos.value.codigoVendedor = "";
                    document.getElementById("codigoVendedor").focus();
                    snackBarApp.value.open("Um vendedor/funcionário inativo não pode gerar novos orçamentos de ordens de serviços.", 6000);
                    return;
                }
                if (response.data.message == "bloqueado") {
                    orcamentoOrdemServicos.value.codigoVendedor = "";
                    document.getElementById("codigoVendedor").focus();
                    snackBarApp.value.open("Um vendedor/funcionário bloqueado não pode gerar novos orçamentos de ordens de serviços.", 6000);
                    return;
                }

                orcamentoOrdemServicos.value.codigoVendedor = "";
                document.getElementById("codigoVendedor").focus();
                snackBarApp.value.open("Vendedor/Funcionário não encontrado, tente outro código ou faça uma busca.", 6000);
                return;
            }
            orcamentoOrdemServicos.value.idVendedor = response.data.data.idVendedor;
            orcamentoOrdemServicos.value.nomeVendedor = response.data.data.nome;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouVendedor(vendedor) {
    if (vendedor == null) {
        return;
    }

    orcamentoOrdemServicos.value.codigoVendedor = vendedor.codigo;
    orcamentoOrdemServicos.value.nomeVendedor = vendedor.nome;
    orcamentoOrdemServicos.value.idVendedor = vendedor.idVendedor;

    getVendedor();
    dialogBuscaVendedor.value = false;

    setTimeout(() => {
        document.getElementById("campo1").focus();
    }, 100);
}

function cadastrouCliente(codigoCliente) {
    if (codigoCliente == null) {
        return;
    }

    orcamentoOrdemServicos.value.codigoCliente = codigoCliente;

    getCliente();
}

async function totais(idOrcamentoOrdemServicos) {
    try {
        const response = await OrcamentoOrdemServicos.totais(idOrcamentoOrdemServicos);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("Erro ao calcular os totais do orçamento de ordem de serviços, mensagem: " + response.data.message, 6000);
                return;
            }

            totalProdutos.value = response.data.data.totalProdutos;
            totalServicos.value = response.data.data.totalServicos;
            valorDescontoProdutos.value = response.data.data.valorDescontoProdutos;
            valorDescontoServicos.value = response.data.data.valorDescontoServicos;
            valorTotalDescontos.value = Converte.numberToCurrency(parseFloat(valorDescontoProdutos.value) + parseFloat(valorDescontoServicos.value));

            valorTotalComDesconto.value = response.data.data.totalComDescontos;
            total.value = response.data.data.total;

        }
    } catch (err) {
        console.log(err);
    }
}

async function transformarOrcamentoOrdemServicos() {
    let dados = new FormData();
    dados.append('idOrcamentoOrdemServicos', orcamentoOrdemServicos.value.idOrcamentoOrdemServicos);
    dados.append('codigoModulo', '03.05.02');

    try {
        let response = await OrcamentoOrdemServicos.transformar(dados);
        if (response.status == 200) {
            if(response.data.success == true) {
                snackBarApp.value.open('Orçamento de ordem de serviços transformado em ordem de serviços. Código da ordem de serviços: ' +
                    response.data.codigo, 6000);
                cleanForm();
                orcamentoOrdemServicos.value.idOrcamentoOrdemServicos = null;
                produtosOrcamentoOrdemServicos.value = [];
                servicosOrcamentoOrdemServicos.value = [];
                document.getElementById('codigo').focus();
                proximoCodigo();
                totalProdutos.value = 0;
                totalServicos.value = 0;
                total.value = 0;
                router.push({"name":"OrcamentosOrdensServicos.Create"});
                return;
            }
            else {
                snackBarApp.value.open('Erro ao transformar o orçamento de ordem de serviços em ordem de serviços, mensagem: '+response.data.message, 6000);
            }
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao transformar o orçamento de ordem de serviços em ordem de serviços.');
        console.log(err);
    }
}

function addProduto(produtoOrcamentoOrdemServicos) {
    produtoOrcamentoOrdemServicos.valorUnitario = Converte.stringToFloat(produtoOrcamentoOrdemServicos.valorUnitario).toString();
    produtosOrcamentoOrdemServicos.value.push(produtoOrcamentoOrdemServicos);
    totais(route.params.id);
}

function addServico(servicoOrcamentoOrdemServicos) {
    servicoOrcamentoOrdemServicos.valorUnitario = Converte.stringToFloat(servicoOrcamentoOrdemServicos.valorUnitario).toString();
    servicosOrcamentoOrdemServicos.value.push(servicoOrcamentoOrdemServicos);
    totais(route.params.id);
}

async function getProdutos(idOrcamentoOrdemServicos) {
    try {
        const response = await OrcamentoOrdemServicos.listarProdutos(idOrcamentoOrdemServicos);
        if (response.status == 200) {
            produtosOrcamentoOrdemServicos.value = response.data.data;
            totais(idOrcamentoOrdemServicos);
        }
    } catch (err) {
        console.log(err);
    }
}

async function getServicos(idOrcamentoOrdemServicos) {
    try {
        const response = await OrcamentoOrdemServicos.listarServicos(idOrcamentoOrdemServicos);
        if (response.status == 200) {
            servicosOrcamentoOrdemServicos.value = response.data.data;
            totais(idOrcamentoOrdemServicos);
        }
    } catch (err) {
        console.log(err);
    }
}


async function removeProduto(idProdutoOrcamentoOS) {
    try {
        const response = await OrcamentoOrdemServicos.removeProduto(idProdutoOrcamentoOS);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O item não pode ser excluído do orçamento de ordem de serviços, mensagem: " + response.data.message, 6000);
                return;
            }

            snackBarApp.value.open("Item excluído com sucesso do orçamento de ordem de serviços.", 1500);
            getProdutos(route.params.id);
            totais(route.params.id);
        }
    } catch (err) {
        console.log(err);
    }
}

async function removeServico(idServicoOrcamentoOrdemServicos) {
    try {
        const response = await OrcamentoOrdemServicos.removeServico(idServicoOrcamentoOrdemServicos);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O item não pode ser excluído do orçamento de ordem de serviços, mensagem: " + response.data.message, 6000);
                return;
            }

            snackBarApp.value.open("Item excluído com sucesso do orçamento de ordem de serviços.", 1500);
            getServicos(route.params.id);
            totais(route.params.id);
        }
    } catch (err) {
        console.log(err);
    }
}

async function imprimir() {
    let nomeRel;
    nomeRel = 'OrcamentoOrdemServicos';
    try {
        let response = await Relatorio.get(nomeRel, route.params.id);

        if (response.status == 200) {
            if(response.data.success == true) {
                let nomeArquivo = response.data.nomeArquivo;
                let url = config.baseUrlRel+nomeArquivo;
                window.open(url, '_blank');

                return;

            }
            snackBarApp.value.open('Erro ao imprimir o orçamento de ordem de serviços, mensagem: '+response.data.message);
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao imprimir o orçamento de ordem de serviços.');
        console.log(err);
    }
}

function descontoAlterado() {
    totais(route.params.id);
}

const keydownHandler = (event) => {
    if(event.key === 'F5') {
        event.preventDefault();
    }

    if(event.key === 'F7') {
        event.preventDefault();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoCliente' && document.getElementById('codigoCliente').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnBuscaCliente').click();
    }

    if (event.key === 'F7' && document.activeElement.id === 'codigoCliente' && document.getElementById('codigoCliente').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnNovoCliente').click();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoVendedor' && document.getElementById('codigoVendedor').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnBuscaVendedor').click();
    }
};

document.addEventListener('keydown', keydownHandler);

onUnmounted(() => {
    document.removeEventListener('keydown', keydownHandler);
});

</script>

<template>
    <v-card elevetion="16">
        <v-card-title>
            <h2 v-show="$route.name == 'OrcamentosOrdensServicos.Create'">Novo Orçamento de Ordem de Serviços</h2>
        </v-card-title>
        <v-card-text>
            <v-card elevation="16" class="mb-8">
                <v-card-title >
                    <h3 class="d-flex" style="word-break: break-word">Dados do Orçamento de Ordem de Serviços</h3>
                </v-card-title>
                <form ref="formOrcamentoOrdemServicos" @submit.prevent="submit">
                    <v-card-text> 
                        <v-row>
                            <v-col cols="3">
                                <v-text-field label="Código" id="codigo" type="text" v-model="orcamentoOrdemServicos.codigo"
                                    :error-messages="errors['orcamentoOrdemServicos.codigo']" maxlength="9" @blur="(e) => {
                                        isRealValidation = true;
                                        orcamentoOrdemServicos.codigo = e.target.value;
                                    }
                                        " :readonly="isReadOnly" />
                            </v-col>
                            <v-col cols="3">
                                <Data label="Data" id="data" v-model="orcamentoOrdemServicos.data"
                                    :error-messages="errors['orcamentoOrdemServicos.data']" :readonly="isReadOnly"></Data>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Prazo Entrega(dias)" id="prazo" v-model="orcamentoOrdemServicos.prazo"
                                    :error-messages="errors['orcamentoOrdemServicos.prazo']" :readonly="isReadOnly" />
                            </v-col>
                            <v-col cols="3">
                                <Data label="Validade do Orçamento" id="validade" v-model="orcamentoOrdemServicos.validade"
                                    :error-messages="errors['orcamentoOrdemServicos.validade']" :readonly="isReadOnly"></Data>
                            </v-col>
                            
                        </v-row>

                        <v-row>
                            <v-col cols="3">
                                <v-text-field label="Código do Cliente" id="codigoCliente" type="text"
                                    v-model="orcamentoOrdemServicos.codigoCliente" maxlength="10"
                                    :error-messages="errors['orcamentoOrdemServicos.codigoCliente']" @blur="getCliente()"
                                    v-tooltip="isReadOnly ? 'código do cliente' : 'F5-Abre a busca de clientes; F7-Cadastra um novo cliente.'"
                                    :readonly="isReadOnly" />
                            </v-col>
                            <v-col>
                                <v-text-field label="Nome do Cliente" id="nomeCliente" type="text"
                                    v-model="orcamentoOrdemServicos.nomeCliente" maxlength="50"
                                    :error-messages="errors['orcamentoOrdemServicos.nomeCliente']" readonly tabindex="-1">
                                    <template v-slot:append
                                        v-if="route.name !== 'OrcamentosOrdensServicos.Show'">
                                        <v-tooltip text="Faz a busca de um cliente por nome/apelido/cpf">
                                            <template v-slot:activator="{ props }">
                                                <v-btn v-bind="props" color="primary" @click="dialogBuscaCliente = true"
                                                    id="btnBuscaCliente">
                                                    <v-icon>mdi-magnify</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                        <v-dialog v-model="dialogBuscaCliente" transition="dialog-top-transition">
                                            <ClienteBusca @selecionouCliente="selecionouCliente"
                                                @close-dialog="dialogBuscaCliente = false"
                                                :select-inativo-bloqueado=false />
                                        </v-dialog>
                                        &nbsp;
                                        <v-tooltip text="Cadastra um novo cliente.">
                                            <template v-slot:activator="{ props }">
                                                <v-btn v-bind="props" color="primary" @click="dialogCliente = true"
                                                    id="btnNovoCliente">
                                                    <v-icon>mdi-invoice-text-plus</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                        <v-dialog v-model="dialogCliente" transition="dialog-top-transition">
                                            <ClienteCreate @cadastrouCliente="cadastrouCliente"
                                                @close-dialog="dialogCliente = false" />
                                        </v-dialog>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3">
                                <v-text-field label="Código do Vendedor" id="codigoVendedor" type="text"
                                    v-model="orcamentoOrdemServicos.codigoVendedor" maxlength="10"
                                    :error-messages="errors['orcamentoOrdemServicos.codigoVendedor']" @blur="getVendedor()"
                                    v-tooltip="isReadOnly ? 'código do vendedor' : 'F5-Abre a busca de vendedores/funcionários.'"
                                    :readonly="isReadOnly" />
                            </v-col>
                            <v-col>
                                <v-text-field label="Nome do Vendedor" id="nomeVendedor" type="text"
                                    v-model="orcamentoOrdemServicos.nomeVendedor" maxlength="50"
                                    :error-messages="errors['orcamentoOrdemServicos.nomeVendedor']" readonly tabindex="-1">
                                    <template v-slot:append
                                        v-if="route.name !== 'OrcamentosOrdensServicos.Show'">
                                        <v-tooltip text="Faz a busca de um vendedor/funcionário por nome apelido/cpf">
                                            <template v-slot:activator="{ props }">
                                                <v-btn v-bind="props" color="primary"
                                                    @click="dialogBuscaVendedor = true" id="btnBuscaVendedor">
                                                    <v-icon>mdi-magnify</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                        <v-dialog v-model="dialogBuscaVendedor" transition="dialog-top-transition">
                                            <VendedorBusca @selecionouVendedor="selecionouVendedor"
                                                @close-dialog="dialogBuscaVendedor = false" />
                                        </v-dialog>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <v-text-field :label="labelCampo1" id="campo1" type="text"
                                    v-model="orcamentoOrdemServicos.campo1" maxlength="50"
                                    :error-messages="errors['orcamemtoOrdemServicos.campo1']" :readonly="isReadOnly" />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field :label="labelCampo2" id="campo2" type="text"
                                    v-model="orcamentoOrdemServicos.campo2" maxlength="50"
                                    :error-messages="errors['orcamentoOrdemServicos.campo2']" :readonly="isReadOnly" />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field :label="labelCampo3" id="campo3" type="text"
                                    v-model="orcamentoOrdemServicos.campo3" maxlength="50"
                                    :error-messages="errors['orcamentoOrdemServicos.campo3']" :readonly="isReadOnly" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field :label="labelCampo4" id="campo4" type="text"
                                    v-model="orcamentoOrdemServicos.campo4" maxlength="50"
                                    :error-messages="errors['orcamentoOrdemServicos.campo4']" :readonly="isReadOnly" />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :label="labelCampo5" id="campo5" type="text"
                                    v-model="orcamentoOrdemServicos.campo5" maxlength="50"
                                    :error-messages="errors['orcamentoOrdemServicos.campo5']" :readonly="isReadOnly" />
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" v-show="$route.name !== 'OrcamentosOrdensServicos.Show' && showBtnGravar"
                            variant="elevated" type="submit" id="btnOrcamentoOrdemServicosGravar">Gravar</v-btn>
                    </v-card-actions>
                </form>
            </v-card>

            <v-dialog v-model="dialogProdutoOrcamentoOrdemServicos" transition="dialog-top-transition">
                <ProdutoOrcamentoOrdemServicos :idOrcamentoOrdemServicos="orcamentoOrdemServicos.idOrcamentoOrdemServicos" @addProduto="addProduto"
                    @closeDialog="dialogProdutoOrcamentoOrdemServicos = false">
                </ProdutoOrcamentoOrdemServicos>
            </v-dialog>

            <v-card elevation="16" class="mb-8" v-show="route.name !== 'OrcamentosOrdensServicos.Create'">
                <v-card-title>
                    <h3>Produtos</h3>
                </v-card-title>
                <v-card-text>
                    <v-table density="compact">
                        <thead>
                            <tr>
                                <th class="text-center"
                                    v-show="route.name == 'OrcamentosOrdensServicos.Continuar'">
                                    Ações
                                </th>
                                <th>Cód.Produto</th>
                                <th>Descrição do Produto</th>
                                <th>Unid.</th>
                                <th>Quantidade</th>
                                <th>Valor Unitário</th>
                                <th>Valor Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="o in produtosOrcamentoOrdemServicos" :key="o">
                                <td
                                    v-show="route.name == 'OrcamentosOrdensServicos.Continuar'">
                                    <v-tooltip text="Altera o item.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn size="small" v-bind="props" variant="text"
                                                icon="mdi-invoice-edit-outline"
                                                @click.prevent="auxProdutoOrcamentoOrdemServicos = Object.assign({}, o); dialogUpdateProdutoOrcamentoOrdemServicos = true;">
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                    <v-tooltip text="Exclui o item.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn size="small" v-bind="props" variant="text" icon="mdi-trash-can"
                                                @click.prevent="removeProduto(o.idProdutoOrcamentoOS)">
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                </td>
                                <td>{{ o.codigoProduto }}</td>
                                <td>{{ o.descricaoProduto }}</td>
                                <td>{{ o.unidadeProduto }}</td>
                                <td>{{ quantidadeProdutoFormat(o.quantidade) }}</td>
                                <td>{{ valorFormat(o.valorUnitario) }}</td>
                                <td>{{ valorFormat(o.valorTotal) }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" variant="elevated" @click="dialogProdutoOrcamentoOrdemServicos = true"
                        v-show="route.name == 'OrcamentosOrdensServicos.Continuar'">
                        Adicionar Produto
                    </v-btn>
                    <v-spacer></v-spacer>
                    <h2 class="text-right">Valor Total dos Produtos: {{ valorFormat(totalProdutos) }}</h2>
                </v-card-actions>
            </v-card>

            <v-card elevation="16" class="mb-8" v-show="route.name !== 'OrcamentosOrdensServicos.Create'">
                <v-card-title>
                    <h3>Serviços</h3>
                </v-card-title>
                <v-card-text>
                    <v-table density="compact">
                        <thead>
                            <tr>
                                <th class="text-center"
                                    v-show="route.name == 'OrcamentosOrdensServicos.Continuar'">
                                    Ações
                                </th>
                                <th>Cód.Serviço</th>
                                <th>Descrição do Serviço</th>
                                <th>Unid.</th>
                                <th>Quantidade</th>
                                <th>Valor Unitário</th>
                                <th>Valor Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="o in servicosOrcamentoOrdemServicos" :key="o">
                                <td
                                    v-show="route.name == 'OrcamentosOrdensServicos.Continuar'">
                                    <v-tooltip text="Altera o item.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn size="small" v-bind="props" variant="text"
                                                icon="mdi-invoice-edit-outline"
                                                @click.prevent="auxServicoOrcamentoOrdemServicos = Object.assign({}, o);; dialogUpdateServicoOrcamentoOrdemServicos = true;">
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                    <v-tooltip text="Exclui o item.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn size="small" v-bind="props" variant="text" icon="mdi-trash-can"
                                                @click.prevent="removeServico(o.idServicoOrcamentoOS)">
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                </td>
                                <td>{{ o.codigoServico }}</td>
                                <td>{{ o.descricaoServico }}</td>
                                <td>{{ o.unidadeServico }}</td>
                                <td>{{ quantidadeServicoFormat(o.quantidade) }}</td>
                                <td>{{ valorFormat(o.valorUnitario) }}</td>
                                <td>{{ valorFormat(o.valorTotal) }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" variant="elevated" @click="dialogServicoOrcamentoOrdemServicos = true"
                        v-show="route.name == 'OrcamentosOrdensServicos.Continuar'">
                        Adicionar Serviço
                    </v-btn>
                    <v-spacer></v-spacer>
                    <h2 class="text-right">Valor Total dos Servicos: {{ valorFormat(totalServicos) }}</h2>
                </v-card-actions>
            </v-card>

            <v-dialog v-model="dialogUpdateProdutoOrcamentoOrdemServicos" transition="dialog-top-transition">
                <UpdateProdutoOrcamentoOrdemServicos :produtoOrcamentoOrdemServicos=auxProdutoOrcamentoOrdemServicos
                    @close-dialog="dialogUpdateProdutoOrcamentoOrdemServicos = false"
                    @alterou-produto="getProdutos(route.params.id); dialogUpdateProdutoOrcamentoOrdemServicos = false" />
            </v-dialog>


            <v-dialog v-model="dialogServicoOrcamentoOrdemServicos" transition="dialog-top-transition">
                <ServicoOrcamentoOrdemServicos
                    :idOrcamentoOrdemServicos="orcamentoOrdemServicos.idOrcamentoOrdemServicos" @addServico="addServico"
                    @closeDialog="dialogServicoOrcamentoOrdemServicos = false"> </ServicoOrcamentoOrdemServicos>
            </v-dialog>

            <v-dialog v-model="dialogUpdateServicoOrcamentoOrdemServicos" transition="dialog-top-transition">
                <UpdateServicoOrcamentoOrdemServicos :servicoOrcamentoOrdemServicos=auxServicoOrcamentoOrdemServicos
                    @close-dialog="dialogUpdateServicoOrcamentoOrdemServicos = false"
                    @alterou-servico="dialogUpdateServicoOrcamentoOrdemServicos = false; getServicos(route.params.id)" />
            </v-dialog>

            <v-dialog v-model="dialogObservacoes" transition="dialog-top-transition">
                <ObservacoesOrcamentoOrdemServicos @close-dialog="dialogObservacoes = false" />
            </v-dialog>

            <v-dialog v-model="dialogObservacoesInternas" transition="dialog-top-transition">
                <ObservacoesInternasOrcamentoOrdemServicos @close-dialog="dialogObservacoesInternas = false" />
            </v-dialog>

            <v-dialog v-model="dialogCondicoesPagamento" transition="dialog-top-transition">
                <CondicoesPagamentoOrcamentoOrdemServicos
                    @close-dialog="dialogCondicoesPagamento = false"/>
            </v-dialog>

            <v-dialog v-model="dialogDescontos" transition="dialog-top-transition">
                <DescontosOrcamentoOrdemServicos 
                    @close-dialog="dialogDescontos = false" 
                    @descontoAlterado="descontoAlterado"
                    :operacao = operacao
                    />
            </v-dialog>

            <v-row v-show="route.name !== 'OrcamentosOrdensServicos.Create'">
                <v-col cols="12">
                    <h2 class="text-right">Valor Total dos Produtos + Serviços: {{ valorFormat(total) }}</h2>
                </v-col>
                <v-col cols="12" v-show="valorDescontoProdutos > 0 || valorDescontoServicos > 0">
                    <h2 class="text-right">Descontos nos Produtos ({{ valorFormat(valorDescontoProdutos) }}) 
                        e Serviços ({{ valorFormat(valorDescontoServicos) }}): {{ valorTotalDescontos }} </h2>
                </v-col>
                <v-col cols="12" v-show="valorDescontoProdutos > 0 || valorDescontoServicos > 0">
                    <h2 class="text-right">Valor Total com Descontos: {{ valorFormat(valorTotalComDesconto) }}</h2>
                </v-col>
            </v-row>

            <v-row v-show="route.name !== 'OrcamentosOrdensServicos.Create'">
                <v-col cols="12">
                    <h2>{{ situacao }}</h2>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap">
            <v-btn class="pr-1 pl-1 mr-0" size="small" color="primary" variant="elevated" @click="dialogObservacoes = true"
                v-show="route.name !== 'OrcamentosOrdensServicos.Create'">
                Observações
            </v-btn>

            <v-btn class="pr-1 pl-1 mr-0" size="small" color="primary" variant="elevated" @click="dialogObservacoesInternas = true"
                v-show="route.name !== 'OrcamentosOrdensServicos.Create'">
                Observações Internas
            </v-btn>

            <v-btn class="pr-1 pl-1 mr-0" size="small" color="primary" variant="elevated" @click="dialogCondicoesPagamento = true" 
                v-show="route.name == 'OrcamentosOrdensServicos.Continuar'">
                Condições de Pagamento
            </v-btn>

            <v-btn class="pr-1 pl-1 mr-0" size="small" color="primary" variant="elevated" @click="dialogDescontos = true"
                v-show="route.name !== 'OrcamentosOrdensServicos.Create'">
                Descontos
            </v-btn>

            <v-btn class="pr-1 pl-1 mr-0" size="small" color="primary" variant="elevated" @click="transformarOrcamentoOrdemServicos()" 
                v-show="route.name == 'OrcamentosOrdensServicos.Continuar' &&
                (produtosOrcamentoOrdemServicos.length > 0 || servicosOrcamentoOrdemServicos.length > 0)">
                Transformar em O.S.
            </v-btn>

            <v-btn class="pr-1 pl-1 mr-0" size="small" color="primary" variant="elevated" @click="imprimir()" v-show="route.name == 'OrcamentosOrdensServicos.Continuar' &&
                orcamentoOrdemServicos.situacao !== 'C'">
                Imprimir
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="pr-1 pl-1 mr-0" size="small" color="primary" variant="elevated" :to="{ name: 'OrcamentosOrdensServicos.List' }">
                Voltar
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
