<script setup>
import { ref, computed, onMounted, inject, onUnmounted } from "vue";
import { PagamentoVendedor } from "@/resources/pagamentoVendedorResource";
import { Vendedor } from "../../resources/vendedorResource";
import {Relatorio} from '../../resources/relatoriosResource';
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import { usePagamentoVendedor } from "../../store/pagamentoVendedor";
import VendedorBusca from "../Vendedores/VendedorBusca";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";
import { dateFormat, valorFormat} from "../../services/filtros";


let msgConfirm = ref(null);
let snackBarApp = inject('snackBarApp');

const pagamentoVendedorStore = usePagamentoVendedor();
let lancamento = ref({});
let lancamentos = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(pagamentoVendedorStore.currentPage);
const dataInicial = ref(pagamentoVendedorStore.dataInicial);
const dataFinal = ref(pagamentoVendedorStore.dataFinal);
const idVendedor = ref(pagamentoVendedorStore.idVendedor);
const codigoVendedor = ref(pagamentoVendedorStore.codigoVendedor);
const nomeVendedor = ref(pagamentoVendedorStore.nomeVendedor);
const dialogVendedor = ref(false);

const direction = computed(() => {
    return pagamentoVendedorStore.sort[0].direction;
});

const orderBy = computed(() => {
    return pagamentoVendedorStore.sort[0].property;
});

onMounted(() => {
    filtrar();
});

async function destroy() {
    let dados = new FormData();
    dados.append("data", JSON.stringify(lancamento.value));

    try {
        const response = await PagamentoVendedor.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Pagamento a vendedor/funcionário excluído com sucesso.");
                filtrar();
            }
        }
    } catch (err) {
        console.log(err);
    }
}
function openModalDelete(lanc) {
    lancamento.value = lanc;
    msgConfirm.value
        .open(
            "Confirma Exclusão ?",
            "Confirma a exclusão do pagamento a vendedor/funcionário ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                destroy();
            }
        });
}


async function filtrar(mudouPagina = false) {
    let start = pagamentoVendedorStore.start;
    let limit = pagamentoVendedorStore.limit;
    let sort = JSON.stringify(pagamentoVendedorStore.sort);

    pagamentoVendedorStore.setDataInicial(dataInicial.value);
    pagamentoVendedorStore.setDataFinal(dataFinal.value);
    pagamentoVendedorStore.setIdVendedor(idVendedor.value);
    pagamentoVendedorStore.setCodigoVendedor(codigoVendedor.value);
    pagamentoVendedorStore.setNomeVendedor(nomeVendedor.value);

    let auxDataInicial = null;
    let auxDataFinal = null;

    if(dataInicial.value != '') {
        auxDataInicial = Converte.datePtBrToIso(dataInicial.value); 
    }

    if(dataFinal.value != '') {
        auxDataFinal = Converte.datePtBrToIso(dataFinal.value); 
    }


    if(mudouPagina == false) {
        handleCurrentChange(1);
        return;
    }


    try {
        const response = await PagamentoVendedor.get(start, limit, sort, idVendedor.value, auxDataInicial, auxDataFinal)
        lancamentos.value = response.data.data;
        total.value = parseInt(response.data.total);
    }
    catch(err) {
        console.log(err);
    }
}

function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    pagamentoVendedorStore.setCurrentPage(val);
    pagamentoVendedorStore.setStart(start);
    filtrar(true);
}

function setSort(property) {
    let sort = pagamentoVendedorStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [
        {
            property: property,
            direction: direction,
        },
    ];

    pagamentoVendedorStore.setSort(aSort);
    filtrar();
}

function selecionouVendedor(vendedor) {
    if (vendedor == null) {
        return;
    }

    codigoVendedor.value = vendedor.codigo;
    document.getElementById("codigoVendedor").focus();
    document.getElementById("codigoVendedor").blur();
    dialogVendedor.value = false;
}

async function getVendedor() {
    idVendedor.value = null;
    nomeVendedor.value = "";

    if (codigoVendedor.value.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", codigoVendedor.value);
    dados.append("getInativosBloqueados", true);
    dados.append("codigoModulo", "03.07");
    try {
        let response = await Vendedor.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                document.getElementById("codigoVendedor").focus();
                snackBarApp.value.open("O vendedor/funcionário não foi encontrado, tente outro código ou faça uma busca.");
                codigoVendedor.value = "";
                return;
            }
            idVendedor.value = response.data.data.idVendedor;
            codigoVendedor.value = response.data.data.codigo;
            nomeVendedor.value = response.data.data.nome;
        }
    } catch (err) {
        console.log("Erro ao buscar vendedor/funcionário: " + err);
    }
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

async function imprimir(idPagamentoVendedor) {
    let nomeRel = 'Recibo Pagamento Vendedor';

    let response = await Relatorio.get(nomeRel, idPagamentoVendedor);
    if (response.status == 200) {
        if(response.data.success == true) {
            let nomeArquivo = response.data.nomeArquivo;
            let url = config.baseUrlRel+nomeArquivo;

            window.open(url, '_blank');
            return;

        }
        if(response.data.success == false ) {
            snackBarApp.value.open(response.data.message);
            return;
        }

        snackBarApp.value.open("Não foi possível fazer a impressão do recibo, tente novamente.");
    }
}

document.addEventListener('keydown', handleKeydown);

function handleKeydown(event) {
    if(event.key === 'F5') {
        event.preventDefault();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoVendedor' ) {
        event.preventDefault();
        document.getElementById('btnBuscaVendedor').click();
    }
}

onUnmounted(() => {
    document.removeEventListener('keydown', handleKeydown);
});

</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-row>
        <v-col>
            <h1>Pagamentos a Vendedores/Funcionários</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.07.01')" color="primary" :to="{ name: 'PagamentosVendedores.Create' }">Novo Lançamento de Pagamentos a Vendedores/Funcionários</v-btn>
        </v-col>
    </v-row>
    <v-row >
        <v-col cols="12">
            <v-card elevation="16" density="compact">
                <v-card-title>Filtrar Por</v-card-title>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="2">
                                <Data label="Data Inicial" type="text" v-model="dataInicial"></Data>
                            </v-col>
                            <v-col cols="2">
                                <Data label="Data Final" type="text" v-model="dataFinal"></Data>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field id="codigoVendedor" label="Cód.Vendedor" type="text" 
                                    v-model="codigoVendedor" @blur="getVendedor()"
                                    v-tooltip="'F5-Abre a busca de vendedores/funcionários'"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Nome do Vendedor" type="text" v-model="nomeVendedor" readonly tabindex="-1">
                                    <template v-slot:append>
                                        <v-tooltip text="Faz a busca de um vendedor por nome.">
                                            <template v-slot:activator="{ props }">
                                                <v-btn v-bind="props" color="primary" @click="dialogVendedor = true" id="btnBuscaVendedor">
                                                    <v-icon>mdi-magnify</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                        <v-dialog v-model="dialogVendedor" transition="dialog-top-transition">
                                            <VendedorBusca 
                                                @selecionouVendedor="selecionouVendedor" 
                                                @close-dialog="dialogVendedor = false"
                                                :select-inativo-bloqueado=true />
                                        </v-dialog>

                                        &nbsp;&nbsp;
                                        <v-btn color="primary" @click.prevent="filtrar('vendedor')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    
    <v-card elevation="16" class="mt-3">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('data')">
                            Data
                            <v-icon v-if="orderBy == 'data'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigoVendedor')">
                            Cód.Vend./Func.
                            <v-icon v-if="orderBy == 'codigoVendedor'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('nomeVendedor')">
                            Nome Vendedor/Funcionário
                            <v-icon v-if="orderBy == 'nomeVendedor'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Histórico</th>
                    <th class="text-right">Valor</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in lancamentos" :key="o">
                    <td :class="o.situcao == 'C' ? 'text-red': ''">
                        <v-tooltip text="Exclui o lançamento de pagamentos a vendedores/funcionários.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-show="temPermissao('03.07.03')" size="small" v-bind="props" variant="text" @click.prevent="openModalDelete(o)" icon="mdi-trash-can">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Consulta o pagamento a vendedores/funcionários.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-tab-search"
                                    v-show="temPermissao('03.07.02')"
                                    :to="{ name: 'PagamentosVendedores.Show', params: { id: o.idPagamentoVendedor } }"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Imprime o recibo.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-printer-outline"
                                    v-show="temPermissao('03.07.04')"
                                    @click.prevent="imprimir(o.idPagamentoVendedor)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>


                    </td>
                    <td>{{ dateFormat(o.data) }}</td>
                    <td> {{ o.codigoVendedor }}</td>
                    <td>{{ o.nomeVendedor }}</td>
                    <td>{{ o.historico }}</td>
                    <td class="text-right">{{ valorFormat(o.valor) }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage" :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-btn v-show="temPermissao('03.07.01')" color="primary" :to="{ name: 'PagamentosVendedores.Create' }">Novo Lançamento de Pagamentos a Vendedores/Funcionários</v-btn>
</template>