<script setup>
    import { ref, computed, onMounted } from 'vue';
    import MainMenu from './components/Menu.vue';
    import {CasasDecimais} from './resources/casasDecimaisResource.js';
    import {ConfiguracoesContasReceber} from "./resources/configuracoesContasReceberResource";
    import {ConfiguracoesOrdensServicos} from "./resources/configuracoesOrdensServicosResource";
    import { ConfiguracoesProdutos } from './resources/configuracoesProdutosResource';
  //  import { usePermissoes } from './store/permissoes';
    import { setLocale}  from 'yup';
    import { pt } from 'yup-locale-pt';
    import { useLogado } from './store/logado';
    import SnackBar from './components/Utils/SnackBar.vue';
    setLocale(pt);

    import {Login} from './resources/loginResource.js';
    import { provide } from 'vue';
    const year =  new Date().getFullYear();
    const logadoStore = useLogado();
    const snackBarApp = ref(null);
    provide('snackBarApp', snackBarApp);

    // Inicializa casas decimais;
    CasasDecimais.inicializa();
    // Inicializa configuracoes de contas a receber
    
    // Inicializa configuracoes de ordens de serviços
    ConfiguracoesOrdensServicos.inicializa();

    // Inicializa configurações de contas a receber
    // Status do usuário;
    ConfiguracoesContasReceber.inicializa();

    // Inicializa configuracoes de produtos
    ConfiguracoesProdutos.inicializa();

    onMounted(() => {
      Login.status();
    });

    // eslint-disable-next-line 
    const logado = computed(() => {
      return logadoStore.logado;
    });

    // eslint-disable-next-line 
    function handleOpen(key, keyPath) {
      console.log(key, keyPath);
    }
    
    // eslint-disable-next-line 
    function handleClose(key, keyPath) {
        console.log(key, keyPath);
    }

    document.addEventListener('keydown', (event) => {
        if(event.key === 'F5') {
            event.preventDefault();
        }
    });

</script>

<template>
    <div id="app">     
        <v-app>
            <v-content>
                
                <SnackBar ref="snackBarApp"></SnackBar>
                <main-menu class="ml-3 mr-3"></main-menu>
                <br>
                <div class="ml-3 mr-3">
                    <router-view ></router-view>
                </div>
                <br><br>
            </v-content>                

            <v-footer app >
                <p class="text-muted ml-3 mr-3">@ {{ year }} Henning Informática - osgold@osgold.com.br - 41-3078-9507</p>
            </v-footer>
            
        </v-app>      
    </div>
</template>


<!-- <script>

    import MenuComponent from './components/Menu.vue';
    import {CasasDecimais} from './resources/casasDecimaisResource.js';
    import {ConfiguracoesContasReceber} from "./resources/configuracoesContasReceberResource";
    import {ConfiguracoesOrdensServicos} from "./resources/configuracoesOrdensServicosResource";
    import { usePermissoes } from './store/permissoes';
    import { setLocale}  from 'yup';
    import { pt } from 'yup-locale-pt';
    import { useLogado } from './store/logado';
    import SnackBar from './components/Utils/SnackBar.vue';
    setLocale(pt);

    import {Login} from './resources/loginResource.js';
 
    export default {
        components: {
            'main-menu':MenuComponent
        },
        created(){ 
            let permissoesStore = usePermissoes();
            console.log(permissoesStore.tipo);
            // Inicializa casas decimais;
            CasasDecimais.inicializa();
            // Inicializa configuracoes de contas a receber
            ConfiguracoesContasReceber.inicializa();
            // Inicializa configuracoes de ordens de serviços
            ConfiguracoesOrdensServicos.inicializa();
            // Status do usuário;
            Login.status();
        },
        computed: {
           logado() {
                
                return this.logadoStore.logado;

           }
        },
        
        data() {
            return {
                year: new Date().getFullYear(),
                isCollapse: true,
                logadoStore:useLogado(),
                snackBarApp: null,
            }
        },
        name: 'app',
        methods: {
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            }
        }
    }
</script> --> 


<style>

    /* #app{
        display:flex;
        min-height:100vh;
        flex-direction: column;
    }*/

    main{
        /*padding-top: 50px;*/
        flex: 1 0 auto;
        padding-bottom: 50px;
    }

/*    .footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        
        height: 30px;
        background-color: #f5f5f5;
    } */


     /* Para deixar a table com tamanho total das colunas e criar um scroll horizontal */
    .v-table {
      display: block; overflow-x: auto;    white-space: nowrap;
    }

     /* Para deixar a table zebrada */
    tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }
   
</style>
