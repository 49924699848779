<script setup>
import { ref, onMounted, defineEmits, inject, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { SubgrupoContasReceber } from "../../resources/subgrupoContasReceberResource";
import { PermissaoService } from "../../services/permissaoService";
import { GrupoContasReceber } from "../../resources/grupoContasReceberResource";
import { useField, useForm } from "vee-validate";
import { object, string, number } from "yup";
import { usePermissoes } from "../../store/permissoes";
import GrupoContasReceberCreate from "../GruposContasReceber/GrupoContasReceberCreate.vue";

const emit = defineEmits(["close-dialog", "cadastrou-subgrupo-contas-receber"]);
const snackBarApp = inject('snackBarApp');
const route = useRoute();
let dialog = ref(false);
let showBtnGravar = ref(true);
const permissoesStore = usePermissoes();

const validationSchema = object({
    subgrupoContasReceber: object({
        idSubgrupoContasReceber: undefined,
        idGrupoContasReceber: number().required('O grupo de contas a receber é obrigatório'),
        descricao: string().required("A descrição é obrigatória").max(50),
    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const gruposContasReceber = ref([]);

const subgrupoContasReceber = ref({
    idSubgrupoContasReceber: useField("subgrupoContasReceber.idSubgrupoContasReceber").value,
    idGrupoContasReceber: useField("subgrupoContasReceber.idGrupoContasReceber").value,
    descricao: useField("subgrupoContasReceber.descricao").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    getGruposContasReceber();
    if (route.name == "SubgrupoContasReceber.Update") {
        getSubgrupoContasReceber(route.params.id);
    } else {
        subgrupoContasReceber.value.idSubgrupoContasReceber = null;
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});
async function gravar(values, actions) {
    let dados = new FormData();
    dados.append("data", JSON.stringify(subgrupoContasReceber.value));

    try {
        const response = await SubgrupoContasReceber.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("Subgrupo de contas a receber não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            if (route.name == "SubgrupoContasReceber.Update") {
                snackBarApp.value.open("Subgrupo de contas a receber alterado com sucesso.");
            } else {
                snackBarApp.value.open("Novo subgrupo de contas a receber cadastrado com sucesso.");
                let idGrupoContasReceber = subgrupoContasReceber.value.idGrupoContasReceber;
                actions.resetForm();
                subgrupoContasReceber.value.idGrupoContasReceber = idGrupoContasReceber;
                subgrupoContasReceber.value.idSubgrupoContasReceber = null;
                document.getElementById("descricao").focus();
                emit("cadastrou-subgrupo-contas-receber");
            }
        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}
function closeModalCadastroSubgrupoContasReceber() {
    emit("close-dialog");
}
async function getGruposContasReceber() {
    let start = 0;
    let limit = 999999;
    let sort = null;

    await GrupoContasReceber.get(start, limit, "", sort)
        .then(function (response) {
            gruposContasReceber.value = response.data.data;

            if (subgrupoContasReceber.value.idGrupoContasReceber == null) {
                subgrupoContasReceber.value.idGrupoContasReceber = response.data.data[0].idGrupoContasReceber;
                return;
            }
        })
        .catch(function () {});
}

async function getSubgrupoContasReceber(id) {
    let dados = new FormData();
    dados.append("codigoModulo", "01.18.02");
    dados.append("idSubgrupoContasReceber", id);
    try {
        const response = await SubgrupoContasReceber.getById(dados);
        if (response.status == 200) {
            setValues({ subgrupoContasReceber: response.data.data });
            document.getElementById("descricao").focus();
        }
    } catch (err) {
        console.log(err);
    }
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

onUnmounted(() => {
    document.removeEventListener('keydown', handleKeydown);
});

function handleKeydown(event) {
    if (event.key === 'F7') {
        event.preventDefault();
    }

    if (event.key === 'F7' && document.activeElement.id === 'grupoContasReceber') {
        event.preventDefault();
        document.getElementById('btnNovoGrupoContasReceber').click();
    }
}

document.addEventListener('keydown', handleKeydown);

</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="$route.name !== 'SubgrupoContasReceber.Update'">Novo Subgrupo de Contas a Receber</h2>
            <h2 v-show="$route.name == 'SubgrupoContasReceber.Update'">Alteração de Subgrupo de Contas a Receber</h2>
        </v-card-title>
        <form ref="formSubgrupoContasReceber" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            :items="gruposContasReceber"
                            id="grupoContasReceber"
                            v-tooltip="'F7-Cadastra um novo grupo de contas a receber.'"
                            item-title="descricao"
                            item-value="idGrupoContasReceber"
                            label="Grupo de Contas a Receber"
                            v-model="subgrupoContasReceber.idGrupoContasReceber"
                            :error-messages="errors['subgrupoContasReceber.idGrupoContasReceber']"
                            no-data-text="Nenhum grupo de contas a receber cadastrado."
                        >
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo grupo de contas a receber.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialog = true" id="btnNovoGrupoContasReceber" v-show="temPermissao('01.18.01')">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialog" transition="dialog-top-transition">
                                    <GrupoContasReceberCreate @close-dialog="dialog = false" @cadastrouGrupoContasReceber="getGruposContasReceber()" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Descrição"
                            id="descricao"
                            type="text"
                            maxlength="50"
                            v-model="subgrupoContasReceber.descricao"
                            :error-messages="errors['subgrupoContasReceber.descricao']"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnSubgrupoContasReceberGravar">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="$router.go(-1)"
                    v-if="showBtnGravar"
                    v-show="$route.name == 'SubgrupoContasReceber.Create' || $route.name == 'SubgrupoContasReceber.Update'"
                    >Voltar</v-btn
                >
                <v-btn
                    color="primary"
                    variant="elevated"
                    v-if="showBtnGravar"
                    v-show="$route.name !== 'SubgrupoContasReceber.Create' && $route.name !== 'SubgrupoContasReceber.Update'"
                    @click.prevent="closeModalCadastroSubgrupoContasReceber()"
                    >Fechar</v-btn
                >
            </v-card-actions>
        </form>
    </v-card>
</template>
