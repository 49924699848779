<script setup>
import { ref, onMounted, inject } from 'vue';
import { ConfiguracoesProdutos } from '../../resources/configuracoesProdutosResource';
import { useField, useForm } from 'vee-validate'
import { object } from 'yup';
import { useConfiguracoesProdutos } from '../../store/configuracoesProdutos';

const snackBarApp = inject('snackBarApp');
const configuracoesProdutosStore = useConfiguracoesProdutos();

const itemsMargemPorcentagem = ref([
    { text: "Margem de Lucro", value: "M" },
    { text: "Porcentagem sobre o Preço de Custo", value: "P" },
]);

const validationSchema = object({
    configuracoesProdutos: object({
        margemPorcentagem: undefined,
    }).required()
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const configuracoesProdutos = ref({
    margemPorcentagem: useField('configuracoesProdutos.margemPorcentagem').value,
});

const submit = handleSubmit(() => {
    gravar();
});

onMounted(() => {
    getConfiguracoesProdutos();
});
async function gravar() {
    let dados = new FormData();
    dados.append('data', JSON.stringify(configuracoesProdutos.value));

    try {
        const response = await ConfiguracoesProdutos.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open('Não foi possível alterar as configurações de produtos, mensagem de erro: ' +
                    response.data.message, 6000);
                return;
            }

            configuracoesProdutosStore.setMargemPorcentagem(configuracoesProdutos.value.margemPorcentagem);
            ConfiguracoesProdutos.inicializa();

            snackBarApp.value.open('Configurações de produtos alteradas com sucesso.');
        }
    } catch (err) {
        snackBarApp.value.open('Erro, mensagem de erro: ' + err);
    }
}
async function getConfiguracoesProdutos() {
    try {
        const response = await ConfiguracoesProdutos.get();
        if (response.status == 200) {
            setValues({ 'configuracoesProdutos': response.data.data });

            document.getElementById("margemPorcentagem").focus();
        }
    } catch (err) {
        console.log(err);
    }
}

</script>


<template>
    <v-card elevation="16">
        <v-card-title>
            <h2>Configurações de Produtos</h2>
        </v-card-title>
        <form ref="formConfiguracoesProdutos" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            id="margemPorcentagem"
                            :items="itemsMargemPorcentagem"
                            label="Deseja calcular o preço de venda pela margem de lucro ou pela porcentagem sobre o preço de custo"
                            v-model="configuracoesProdutos.margemPorcentagem"
                            item-title="text"
                            item-value="value"
                            :error-messages="errors['configuracoesProdutos.margemPorcentagem']"
                        ></v-select>
                            
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit"
                    id="btnConfiguracoesProdutos">Salvar</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
