<script setup>
import { onMounted, inject, defineProps, defineEmits, ref } from "vue";

import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import Porcentagem from "../Utils/porcentagem.vue";
import { string, number, object, addMethod } from "yup";
import { useRoute } from "vue-router";
import { OrdemServicos } from "../../resources/ordemServicosResource";
import { Converte } from "../../utils/converte";
import AlterarParcela from "./OrdemServicosUpdateParcela.vue"
import { useField, useForm } from "vee-validate";
import { format, parse, addDays, isValid } from "date-fns";
import { valorFormat } from "../../services/filtros";

const snackBarApp = inject("snackBarApp");

const props = defineProps({
    propOrdemServicos: {
        type: Object,
    },
    operacao: {
        type: String,
    },
});

const realPorcentagem = ref([
    { text: "Reais", value: "R" },
    { text: "Porcentagem", value: "P" },
]);

const itemsND = ref([
    { text: "Número de Dias", value: "N" },
    { text: "Todo Dia", value: "D" },
]);

addMethod(string, "valor_maior_zero", function valorMaiorZero(message) {
    return string().test(
        "valor-maior-zero", // Name
        message, // Msg
        function (valor) {
            if (Converte.stringToFloat(valor) <= 0) {
                return false;
            }
            return true;
        }
    );
});

addMethod(number, "numero_dias", function numero_dias(message) {
    return number().test(
        "numero_dias", // Name
        message, // Msg
        function (dias) {
            if (ordemServicos.value.nroDiasTodoDia == "D" && (dias < 1 || dias > 31)) {
                return false;
            }
            return true;
        }
    );
});

const operacao = ref(props.operacao);
const route = useRoute();
const isReadOnly = ref(false);

let dialogAlterarParcela = ref(false);

let labelNroDiasTodoDia = ref('Número de Dias');

let lancamentoAlterar = ref(null);

const emit = defineEmits(['close-dialog', 'finalizouOrdemServicos']);

const validationSchema = object({
    ordemServicos: object({
        idOrdemServicos: undefined,
        dataFinalizacao: string().required("A data da finalização é obrigatória"),
        valorTotalPS: undefined,
        valorTotalP: undefined,
        descRPP: undefined,
        descontoP: undefined,
        valorTotalPComDesconto: undefined,
        valorTotalS: undefined,
        descRPS: undefined,
        descontoS: undefined,
        valorTotalSComDesconto: undefined,
        valorTotalComDesconto: undefined,
        parcelas: number().max(999).min(1).typeError("O número de parcelas deve ser maior ou igual a 1.").required("O número de parcelas é obrigatório"),
        nroDiasTodoDia: undefined,
        dias: number().numero_dias("O dia deve ser entre 1 e 31").required("O número de dias/todo dia é obrigatório").typeError("O número de dias/todo dia é obrigatório").label("O número de dias/todo dia"),
        carencia: undefined,
        juros: undefined,
        entrada: undefined,
        adiantamento: undefined,
        valorParcela: undefined,
        valorTotalComDescontoJuros: undefined,
        lancamentos: undefined,
    }).required(),
});

const { handleSubmit, errors } = useForm({ validationSchema });

const ordemServicos = ref({
    idOrdemServicos: useField("ordemServicos.idOrdemServicos").value,
    dataFinalizacao: useField("ordemServicos.dataFinalizacao").value,
    valorTotalPS: useField("ordemServicos.valorTotalPS").value,
    valorTotalP: useField("ordemServicos.valorTotalP").value,
    descRPP: useField("ordemServicos.descRPP").value,
    descontoP: useField("ordemServicos.descontoP").value,
    valorTotalPComDesconto: useField("ordemServicos.valorTotalPComDesconto").value,
    valorTotalS: useField("ordemServicos.valorTotalS").value,
    descRPS: useField("ordemServicos.descRPS").value,
    descontoS: useField("ordemServicos.descontoS").value,
    valorTotalSComDesconto: useField("ordemServicos.valorTotalSComDesconto").value,
    valorTotalComDesconto: useField("ordemServicos.valorTotalComDesconto").value,
    parcelas: useField("ordemServicos.parcelas").value,
    nroDiasTodoDia: useField("ordemServicos.nroDiasTodoDia").value,
    dias: useField("ordemServicos.dias").value,
    carencia: useField("ordemServicos.carencia").value,
    juros: useField("ordemServicos.juros").value,
    entrada: useField("ordemServicos.entrada").value,
    adiantamento: useField("ordemServicos.adiantamento").value,
    valorParcela: useField("ordemServicos.valorParcela").value,
    valorTotalComDescontoJuros: useField("ordemServicos.valorTotalComDescontoJuros").value,
    lancamentos: useField("ordemServicos.lancamentos").value,
});

const submit = handleSubmit((values, actions) => {
    gerarParcelas(values, actions);
});

onMounted(() => {
    cleanData();
    if (operacao.value == "C") {
        isReadOnly.value = true;
    }
    
    getOrdemServicos(props.propOrdemServicos.idOrdemServicos);
    
    setTimeout(() => {
        document.getElementById("dataFinalizacao").focus();
    }, 0);

    if(props.propOrdemServicos.idCliente <= 0 || props.propOrdemServicos.idCliente == null) {
        snackBarApp.value.open('Para finalizar uma ordem de serviços a prazo é necessário que o campo do cliente seja preenchido. '+
            'Preencha o campo do cliente e tente novamente.');
        emit('close-dialog');
    }   
});


function cleanData() {
    ordemServicos.value.idOrdemServicos = props.propOrdemServicos.idOrdemServicos;
    ordemServicos.value.dataFinalizacao = new Date().toLocaleDateString("pt-BR");
    ordemServicos.value.valorTotalPS = 'R$ 0,00';
    ordemServicos.value.valorTotalP = 'R$ 0,00';
    ordemServicos.value.descRPP = 'R';
    ordemServicos.value.descontoP = 'R$ 0,00';
    ordemServicos.value.valorTotalPComDesconto = 'R$ 0,00';
    ordemServicos.value.valorTotalS = 'R$ 0,00';
    ordemServicos.value.descRPS = 'R';
    ordemServicos.value.descontoS = 'R$ 0,00';
    ordemServicos.value.valorTotalSComDesconto = 'R$ 0,00';
    ordemServicos.value.parcelas = 1;
    ordemServicos.value.nroDiasTodoDia = 'N';
    ordemServicos.value.dias = 30;
    ordemServicos.value.carencia = 0;
    ordemServicos.value.juros = '0,0000 %';
    ordemServicos.value.entrada = 'R$ 0,00';
    ordemServicos.value.valorParcela = 'R$ 0,00';
    ordemServicos.value.valorTotalAPagar = props.valorTotal;

    ordemServicos.value.lancamentos = [];

    getTotais();

}

async function getTotais() {
    try {
        let response = await OrdemServicos.totais(ordemServicos.value.idOrdemServicos);
        if (response.status == 200) {
            ordemServicos.value.valorTotalPS = Converte.numberToCurrency(response.data.data.total);
            ordemServicos.value.valorTotalP = Converte.numberToCurrency(response.data.data.totalProdutos);
            ordemServicos.value.valorTotalS = Converte.numberToCurrency(response.data.data.totalServicos);
            ordemServicos.value.adiantamento = Converte.numberToCurrency(response.data.data.adiantamento);
            if (route.name !== 'OrdensServicos.Show') {
                ordemServicos.value.valorTotalPComDesconto = Converte.numberToCurrency(response.data.data.totalProdutos);
                ordemServicos.value.valorTotalSComDesconto = Converte.numberToCurrency(response.data.data.totalServicos);
                ordemServicos.value.valorTotalComDescontoJuros = Converte.numberToCurrency(response.data.data.totalAdiantamento);
                ordemServicos.value.valorTotalComDesconto = Converte.numberToCurrency(response.data.data.totalAdiantamento);
                ordemServicos.value.valorParcela = Converte.numberToCurrency(response.data.data.totalAdiantamento);
            }

            calculaValorAPagar();
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar os totais da ordem de serviços.');
        console.log(err);
    }
}

async function gravar() {
    let valorTotalPComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalPComDesconto);
    if (valorTotalPComDesconto < 0) {
        snackBarApp.value.open('O valor total dos produtos com desconto deve ser maior ou igual a 0. Corrija os valor dos descontos dos produtos.', 6000);
        return;
    }

    let valorTotalSComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalSComDesconto);
    if (valorTotalSComDesconto < 0) {
        snackBarApp.value.open('O valor total dos serviços com desconto deve ser maior ou igual a 0. Corrija os valor dos descontos dos serviços.', 6000);
        return;
    }

    let valorTotalComDescontoJuros = Converte.stringToFloat(ordemServicos.value.valorTotalComDescontoJuros);
    if (valorTotalComDescontoJuros < 0) {
        snackBarApp.value.open('O valor total a pagar deve ser maior ou igual a 0.', 6000);
        return;
    }


    let obj = {};
    obj.idOrdemServicos = ordemServicos.value.idOrdemServicos;
    obj.dataFinalizacao = Converte.datePtBrToIso(ordemServicos.value.dataFinalizacao);
    obj.descRPP = ordemServicos.value.descRPP;
    obj.descRPS = ordemServicos.value.descRPS;
    obj.descontoP = Converte.stringToFloat(ordemServicos.value.descontoP);
    obj.descontoS = Converte.stringToFloat(ordemServicos.value.descontoS);
    obj.valorTotalComDescontoJuros = Converte.stringToFloat(ordemServicos.value.valorTotalComDescontoJuros);
    obj.valorTotalPComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalPComDesconto);
    obj.valorTotalSComDesconto = Converte.stringToFloat(ordemServicos.value.valorTotalSComDesconto);
    obj.juros = Converte.stringToFloat(ordemServicos.value.juros);
    obj.entrada = Converte.stringToFloat(ordemServicos.value.entrada);
    obj.carencia = parseInt(ordemServicos.value.carencia);
    obj.numeroParcelas = ordemServicos.value.parcelas;
    obj.lancamentos = ordemServicos.value.lancamentos;
    obj.nroTodoDia = ordemServicos.value.nroDiasTodoDia;
    obj.dias = ordemServicos.value.dias;

    obj.idCliente = props.propOrdemServicos.idCliente;

    let dados = new FormData();
    dados.append('dados', JSON.stringify(obj));
    try {
        let response = await OrdemServicos.finalizaAPrazo(dados);
        if (response.status == 200) {

            if (response.data.success == false) {
                snackBarApp.value.open('Não foi possível finalizar a ordem de serviços a prazo, ' + response.data.message, 6000);
                return;
            }

            snackBarApp.value.open('Ordem de serviços finalizada com sucesso.');
            emit('finalizouOrdemServicos', ordemServicos.value.idOrdemServicos);

            emit('close-dialog');
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao finalizar a ordem de serviços a prazo, erro:' + err);
        console.log(err);
    }
}

function changeDescRPP() {
    if (ordemServicos.value.descRPP == 'P') {
        ordemServicos.value.descontoP = Converte.numberToPorcentagem(Converte.stringToFloat(ordemServicos.value.descontoP), 2);
    }
    if (ordemServicos.value.descRPP == 'R') {
        ordemServicos.value.descontoP = Converte.numberToCurrency(Converte.stringToFloat(ordemServicos.value.descontoP));
    }

    calculaValorAPagar();
}

function changeDescRPS() {
    if (ordemServicos.value.descRPS == 'P') {
        ordemServicos.value.descontoS = Converte.numberToPorcentagem(Converte.stringToFloat(ordemServicos.value.descontoS), 2);
    }
    if (ordemServicos.value.descRPS == 'R') {
        ordemServicos.value.descontoS = Converte.numberToCurrency(Converte.stringToFloat(ordemServicos.value.descontoS));
    }

    calculaValorAPagar();
}

function calculaValorAPagar() {
    let descontoP = Converte.stringToFloat(ordemServicos.value.descontoP);
    let descontoS = Converte.stringToFloat(ordemServicos.value.descontoS);
    let descRPP = ordemServicos.value.descRPP;
    let descRPS = ordemServicos.value.descRPS;
    let valorTotalP = Converte.stringToFloat(ordemServicos.value.valorTotalP);
    let valorTotalS = Converte.stringToFloat(ordemServicos.value.valorTotalS);
    let adiantamento = Converte.stringToFloat(ordemServicos.value.adiantamento);
    let valorTotalPComDesconto = 0;
    let valorTotalSComDesconto = 0;
    let juros = Converte.stringToFloat(ordemServicos.value.juros);
    let valorParcela = 0;
    let auxValorTotal = 0;
    let entrada = Converte.stringToFloat(ordemServicos.value.entrada);
    let parcelas = parseInt(ordemServicos.value.parcelas);

    if (descRPP == 'R') {
        valorTotalPComDesconto = valorTotalP - descontoP;
    } else {
        valorTotalPComDesconto = valorTotalP - (valorTotalP * descontoP / 100);
    }

    if (descRPS == 'R') {
        valorTotalSComDesconto = valorTotalS - descontoS;
    } else {
        valorTotalSComDesconto = valorTotalS - (valorTotalS * descontoS / 100);
    }

    let valorTotalComDesconto = valorTotalPComDesconto + valorTotalSComDesconto;

    auxValorTotal = valorTotalComDesconto - adiantamento - entrada;

    ordemServicos.value.valorTotalComDesconto = Converte.numberToCurrency(valorTotalComDesconto);
    ordemServicos.value.valorTotalPComDesconto = Converte.numberToCurrency(valorTotalPComDesconto);
    ordemServicos.value.valorTotalSComDesconto = Converte.numberToCurrency(valorTotalSComDesconto);

    if (juros == 0) {
        valorParcela = auxValorTotal / parcelas;
    }
    else {
        juros = juros / 100;
        let cf = juros / (1 - Math.pow((1 + juros), (parcelas * -1)));
        valorParcela = cf * auxValorTotal;
    }

    ordemServicos.value.valorParcela = Converte.numberToCurrency(valorParcela);
    ordemServicos.value.valorTotalComDescontoJuros = Converte.numberToCurrency((valorParcela * parcelas) + adiantamento + entrada);
}

async function getOrdemServicos(idOrdemServicos) {
    let dados = new FormData();
    dados.append('codigoModulo', '03.03.03');
    dados.append('idOrdemServicos', idOrdemServicos);
    try {
        let response = await OrdemServicos.getById(dados);
        if (response.status == 200) {

            ordemServicos.value.descRPP = response.data.data.descRPP;
            ordemServicos.value.descRPS = response.data.data.descRPS;

            if(ordemServicos.value.descRPP !== 'P') {
                ordemServicos.value.descRPP = 'R';
            }

            if(ordemServicos.value.descRPS !== 'P') {
                ordemServicos.value.descRPS = 'R';
            }

            if(ordemServicos.value.descRPP == 'R') {
                ordemServicos.value.descontoP = Converte.numberToCurrency(response.data.data.descontoP);
            }
            else {
                ordemServicos.value.descontoP = Converte.numberToPorcentagem(response.data.data.descontoP, 2);
            }

            if(ordemServicos.value.descRPS == 'R') {
                ordemServicos.value.descontoS = Converte.numberToCurrency(response.data.data.descontoS);
            }
            else {
                ordemServicos.value.descontoS = Converte.numberToPorcentagem(response.data.data.descontoS, 2);
            }

            if (route.name == 'OrdensServicos.Show') {
                ordemServicos.value.dataFinalizacao = Converte.dateIsoToPtBr(response.data.data.dataFinalizacao);
                ordemServicos.value.carencia = response.data.data.carencia;
                ordemServicos.value.entrada = Converte.numberToCurrency(response.data.data.entrada);
                ordemServicos.value.juros = Converte.numberToPorcentagem(response.data.data.juros, 4);
                ordemServicos.value.parcelas = response.data.data.numeroParcelas;
                ordemServicos.value.nroDiasTodoDia = response.data.data.nroTodoDia;
                ordemServicos.value.dias = response.data.data.dias;

                getParcelas();
            }
            else {
                let idCliente = response.data.data.idCliente;

                if (idCliente <= 0 || idCliente == null) {
                    snackBarApp.value.open('Cliente não preenchido', 'Para finalizar uma ordem de serviços a prazo é necessário que o campo do cliente seja preenchido. Preencha o campo do cliente e tente novamente.', 'warning');
                    emit('close-dialog');
                }
            }

            calculaValorAPagar();

        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar a ordem de serviços.');
        console.log(err);
    }
}


function gerarParcelas() {
    if (Converte.stringToFloat(ordemServicos.value.valorTotalComDescontoJuros) == 0) {
        snackBarApp.value.open('Para gerar as parcelas o valor total deve ser maior que R$ 0,00.', 6000);
        return;
    }


    let valorTotalComDescontoJuros = Converte.stringToFloat(ordemServicos.value.valorTotalComDescontoJuros)
    let entrada = Converte.stringToFloat(ordemServicos.value.entrada);
    let adiantamento = Converte.stringToFloat(ordemServicos.value.adiantamento);
    let parcelas = parseInt(ordemServicos.value.parcelas);
    let valorParcela = Converte.stringToFloat(ordemServicos.value.valorParcela);
    let nroDiasTodoDia = ordemServicos.value.nroDiasTodoDia;
    let dias = parseInt(ordemServicos.value.dias);
    let carencia = parseInt(ordemServicos.value.carencia);

    let dataVencimento = parse(Converte.datePtBrToIso(ordemServicos.value.dataFinalizacao), "yyyy-MM-dd", new Date());
    dataVencimento = addDays(dataVencimento, carencia);


    let valorTotalParcelas = valorTotalComDescontoJuros - entrada - adiantamento;

    ordemServicos.value.lancamentos = [];

    let auxValorParcela = 0;

    let i = 1;
    let mes = format(dataVencimento, "MM");
    let ano = format(dataVencimento, "yyyy");

    while (parcelas >= i) {
        if (parcelas == i) {
            auxValorParcela = valorTotalParcelas;

        } else {
            auxValorParcela = valorParcela;

        }

        if (nroDiasTodoDia == 'N') {
            dataVencimento = addDays(dataVencimento, dias);
        }
        else {
            let dia = parseInt(format(dataVencimento, "dd"));
            let auxString = '';
            let n = 0;

            if ((dia < dias) && (i == 1)) {
                auxString = dias + '/' + mes + '/' + ano;

                n = 1;
                while (isDate(auxString) == false) {
                    auxString = (parseInt(dias) - n) + '/' + mes + '/' + ano;
                    n = n + 1;
                }
            }
            else {
                if (i == 1) {
                    mes = parseInt(mes) + 1;
                    if (mes > 12) {
                        mes = 1;
                    }
                    mes = mes.toString();
                }

                if (parseInt(mes) == 12) {
                    auxString = dias + '/' + mes + '/' + ano;

                    n = 1;
                    while (isDate(auxString) == false) {
                        let auxD = parseInt(dias) - n;
                        auxD.toString();
                        auxString = auxD + '/12/' + ano.toString();
                        n = n + 1;
                    }
                    ano = parseInt(ano) + 1;
                    mes = '0';

                }
                else {
                    auxString = dias + '/' + mes + '/' + ano;

                    n = 1;
                    while (isDate(auxString) == false) {
                        let auxD = parseInt(dias) - n;
                        auxD.toString();
                        auxString = auxD + '/' + mes + '/' + ano;
                        n = n + 1;

                    }
                }
            }
            dataVencimento = auxString;
            mes = parseInt(mes) + 1;
            if (mes > 12) {
                mes = 1;
            }
            mes = mes.toString();
            dataVencimento = parse(dataVencimento, "dd/MM/yyyy", new Date());
        }

        let auxDataVencimento = dataVencimento;
        valorTotalParcelas = valorTotalParcelas - valorParcela;

        ordemServicos.value.lancamentos.push({
            'parcela': i,
            'valor': auxValorParcela.toString(),
            'dataVencimento': format(auxDataVencimento, "dd/MM/yyyy"),
            'documento': props.propOrdemServicos.codigo,
            'observacoes': ''
        });
        i++;
    }

    dataVencimento = null;
}



function isDate(date) {
    return isValid(parse(date, "dd/MM/yyyy", new Date()));
}

function changeNroDiasTodoDia() {
    if (ordemServicos.value.nroDiasTodoDia == "N") {
        labelNroDiasTodoDia.value = "Número de Dias";
    } else {
        labelNroDiasTodoDia.value = "Todo Dia";
    }
}

function alterouLancamento(lanc) {
    if (lanc == null) {
        return;
    }

    ordemServicos.value.lancamentos.forEach((item) => {
        if (item.parcela == lanc.parcela) {
            item.documento = lanc.documento;
            item.valor = Converte.stringToFloat(lanc.valor).toFixed(2);
            item.dataVencimento = lanc.dataVencimento;
            item.observacoes = lanc.observacoes;
        }
    });

    dialogAlterarParcela.value = false;
}

function openModalAlterarParcela(lanc) {
    lancamentoAlterar.value = Object.assign({}, lanc);
    dialogAlterarParcela.value = true;
}

async function getParcelas() {
    try {
        let response = await OrdemServicos.getParcelas(ordemServicos.value.idOrdemServicos);
        ordemServicos.value.lancamentos = response.data.data;
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar as parcelas da ordem de serviços.');
        console.log(err);
    }
}

</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="operacao !== 'C'">Finalização da Ordem de Serviços a Prazo</h2>
            <h2 v-show="operacao == 'C'">Ordem de Serviços Finalizada a Prazo</h2>
        </v-card-title>
        <form ref="formFinalizaOrdemServicosAPrazo" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <Data label="Data da Finalização" id="dataFinalizacao" v-model="ordemServicos.dataFinalizacao"
                            :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Valor Total Produtos+Serviços" id="valorTotalPS"
                            v-model="ordemServicos.valorTotalPS" readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Valor Total Produtos" id="valorTotalP" v-model="ordemServicos.valorTotalP"
                            readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-select :items="realPorcentagem" item-title="text" item-value="value"
                            label="Desc. em R$ ou em % Produtos" v-model="ordemServicos.descRPP" :readonly="isReadOnly"
                            @update:modelValue="changeDescRPP()"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" v-show="ordemServicos.descRPP == 'R'">
                        <Moeda label="Desconto nos Produtos em (R$)" id="descontoP" v-model="ordemServicos.descontoP"
                            @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3" v-show="ordemServicos.descRPP == 'P'">
                        <Porcentagem label="Desconto nos Produtos em (%)" id="descontoP"
                            v-model="ordemServicos.descontoP" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="V.Total dos Produtos com Descontos" id="valorTotalPComDesconto"
                            v-model="ordemServicos.valorTotalPComDesconto" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Valor Total Serviços" id="valorTotalS" v-model="ordemServicos.valorTotalS"
                            readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-select :items="realPorcentagem" item-title="text" item-value="value"
                            label="Desc.em R$ ou em % Serviços" v-model="ordemServicos.descRPS" :readonly="isReadOnly"
                            @update:modelValue="changeDescRPS()"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" v-show="ordemServicos.descRPS == 'R'">
                        <Moeda label="Desconto nos Serviços em (R$)" id="descontoS" v-model="ordemServicos.descontoS"
                            @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3" v-show="ordemServicos.descRPS == 'P'">
                        <Porcentagem label="Desconto nos Serviços em (%)" id="descontoS"
                            v-model="ordemServicos.descontoS" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Total dos Serviços com Descontos" id="valorTotalSComDesconto"
                            v-model="ordemServicos.valorTotalSComDesconto" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Total com Descontos" id="valorTotalComDesconto"
                            v-model="ordemServicos.valorTotalComDesconto" readonly tabindex="-1" />

                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Número de Parcelas" id="parcelas" type="text"
                            v-model="ordemServicos.parcelas" maxlength="9" :readonly="isReadOnly"
                            :error-messages="errors['ordemServicos.parcelas']" @blur="calculaValorAPagar()" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-select :items="itemsND" item-title="text" item-value="value" label="Nro.Dias/Todo Dia"
                            v-model="ordemServicos.nroDiasTodoDia" :readonly="isReadOnly"
                            :change="changeNroDiasTodoDia()"></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field :label=labelNroDiasTodoDia id="dias" type="text" v-model="ordemServicos.dias"
                            maxlength="9" :readonly="isReadOnly" :error-messages="errors['ordemServicos.dias']" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Carência de Dias 1ªParcela" id="carencia" type="text"
                            v-model="ordemServicos.carencia" maxlength="4" :readonly="isReadOnly"
                            :error-messages="errors['ordemServicos.carencia']" />
                    </v-col>
                    <v-col cols="3">
                        <Porcentagem label="Juros nas Parcelas" id="juros" type="text" tipo="juros" v-model="ordemServicos.juros"
                            maxlength="9" :readonly="isReadOnly" :error-messages="errors['ordemServicos.juros']"
                            @blur="calculaValorAPagar()"  />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <Moeda label="Entrada(R$)" id="entrada" v-model="ordemServicos.entrada"
                            @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Adiantamento(R$)" id="adiantamento" v-model="ordemServicos.adiantamento" readOnly
                            tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor de Cada Parcela" id="valorParcela" v-model="ordemServicos.valorParcela"
                            readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Total a Pagar" id="valorTotalComDescontoJuros"
                            v-model="ordemServicos.valorTotalComDescontoJuros" readonly tabindex="-1" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn v-show="operacao !== 'C'" color="primary" variant="elevated"
                            @click.prevent="gerarParcelas()" id="btnGerarParcelas">Gerar Parcelas</v-btn>
                    </v-col>
                </v-row>
                <v-card class="mt-8" v-show="ordemServicos.lancamentos && ordemServicos.lancamentos.length">
                    <v-card-text>
                        <v-table density="compact">
                            <thead>
                                <tr>
                                    <th class="text-center" v-show="!isReadOnly">Ações</th>
                                    <th>Parcela</th>
                                    <th>Data Vencimento</th>
                                    <th>Valor da Parcela</th>
                                    <th>Documento</th>
                                    <th>Observações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="o in ordemServicos.lancamentos" :key="o">
                                    <td v-show="!isReadOnly">
                                        <v-tooltip text="Altera o lançamento.">
                                            <template v-slot:activator="{ props }">
                                                <v-btn size="small" v-bind="props" variant="text" icon="mdi-pencil"
                                                    @click.prevent="openModalAlterarParcela(o)">
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                    </td>
                                    <td>{{ o.parcela }}</td>
                                    <td>{{ o.dataVencimento }}</td>
                                    <td>{{ valorFormat(o.valor) }}</td>
                                    <td>{{ o.documento }}</td>
                                    <td>{{ o.observacoes }}</td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-card-text>
                </v-card>
                <v-dialog v-model="dialogAlterarParcela" transition="dialog-top-transition">
                    <AlterarParcela :propLancamento="lancamentoAlterar" @close-dialog="dialogAlterarParcela = false"
                        @alterou-lancamento="alterouLancamento" />
                </v-dialog>
            </v-card-text>
            <v-card-actions>
                <v-btn v-show="operacao !== 'C' && ordemServicos.lancamentos && ordemServicos.lancamentos.length"
                    color="primary" variant="elevated" @click.prevent="gravar()"
                    id="btnConfirmarAPrazo">Confirmar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click.prevent="emit('close-dialog')">
                    Fechar
                </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>