<script setup>
import { onMounted, inject, defineProps, defineEmits, ref } from "vue";

import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import Porcentagem from "../Utils/porcentagem.vue";
import { useRoute } from "vue-router";
import { PedidoCompra } from "../../resources/pedidoCompraResource";
import { Conta } from "../../resources/contaResource";
import { Converte } from "../../utils/converte";
import { valorFormat } from "../../services/filtros";

const snackBarApp = inject("snackBarApp");

const props = defineProps({
    propPedidoCompra: {
        type: Object,
    },
    operacao: {
        type: String,
    },
});

const realPorcentagem = ref([
    { text: "Reais", value: "R" },
    { text: "Porcentagem", value: "P" },
]);

const operacao = ref(props.operacao);
const route = useRoute();
const isReadOnly = ref(false);
const isReadOnlyCheque = ref(false);
const pedidoCompra = ref({});
const cheque = ref({});
const contas = ref([]);

const detalhesConta = ref('');
const apelidoConta = ref('');
const banco = ref('');
const agencia = ref('');
const conta = ref('');

const emit = defineEmits(['close-dialog', 'faturou']);

onMounted(() => {
    cleanData();
    cleanCheque();

    document.getElementById("documento").focus();

    if(operacao.value == "C") {
       isReadOnly.value = true;
        getPedidoCompra(props.propPedidoCompra.idPedidoCompra);
    }
});

function cleanData() {
    pedidoCompra.value.idPedidoCompra = props.propPedidoCompra.idPedidoCompra;
    pedidoCompra.value.documento = props.propPedidoCompra.documento;
    pedidoCompra.value.dataEntrega = null;
    if(operacao.value == 'R') {
        pedidoCompra.value.dataEntrega = new Date().toLocaleDateString("pt-BR");
    }
    pedidoCompra.value.dataFaturamento = new Date().toLocaleDateString("pt-BR");
    pedidoCompra.value.dataEmissao = props.propPedidoCompra.data;
    pedidoCompra.value.valorTotalProdutos = 'R$ 0,00';
    pedidoCompra.value.descRP = 'R';
    pedidoCompra.value.desconto = 'R$ 0,00';
    pedidoCompra.value.acreRP = 'R';
    pedidoCompra.value.acrescimo = 'R$ 0,00';
    pedidoCompra.value.numeroParcelas = 1;
    pedidoCompra.value.entrada = 'R$ 0,00';
    pedidoCompra.value.valorTotalAPagar = props.valorTotal;

    pedidoCompra.value.cheques = [];

    getTotais();
}

function cleanCheque() {
    cheque.value.idConta = null;
    cheque.value.data = new Date().toLocaleDateString("pt-BR");
    cheque.value.bomPara = new Date().toLocaleDateString("pt-BR");
    cheque.value.valor = 'R$ 0,00';
    cheque.value.numero = '';
    cheque.value.observacoes = '';
    getContas();
}

async function getTotais() {
    try {
        let response = await PedidoCompra.totais(pedidoCompra.value.idPedidoCompra);
        if (response.status == 200) {
            pedidoCompra.value.valorTotalProdutos = Converte.numberToCurrency(response.data.data.totalProdutos);
            if (route.name !== 'PedidosCompras.Show') {
                pedidoCompra.value.valorTotalAPagar = Converte.numberToCurrency(response.data.data.valorTotalAPagar);
            }
            calculaValorAPagar();
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar os totais do pedido de compra.');
        console.log(err);
    }
}


async function gravar() {
    let valorTotalPComDesconto = Converte.stringToFloat(pedidoCompra.value.valorTotalComDesconto);
    if(valorTotalPComDesconto < 0 ) {
        snackBarApp.value.open('O valor total dos produtos com desconto mais acréscimo deve ser maior ou igual a 0. Corrija o valor dos descontos dos produtos.', 6000);
        return;
    }


    let obj =  {};
    obj.idPedidoCompra = pedidoCompra.value.idPedidoCompra;
    obj.documento = pedidoCompra.value.documento;
    obj.descRP = pedidoCompra.value.descRP;
    obj.acreRP = pedidoCompra.value.acreRP;
    obj.desconto = Converte.stringToFloat(pedidoCompra.value.desconto);
    obj.acrescimo = Converte.stringToFloat(pedidoCompra.value.acrescimo);
    obj.valorTotalComDesconto = Converte.stringToFloat(pedidoCompra.value.valorTotalComDesconto);
    obj.entrada = Converte.stringToFloat(pedidoCompra.value.entrada);
    obj.numeroParcelas = pedidoCompra.value.numeroParcelas;
    obj.cheques = pedidoCompra.value.cheques;
    obj.data = Converte.datePtBrToIso(pedidoCompra.value.data);

    obj.idFornecedor = props.propPedidoCompra.idFornecedor;

    if(pedidoCompra.value.dataEntrega == '' || pedidoCompra.value.dataEntrega == 'Invalid date' || pedidoCompra.value.dataEntrega == null) {
        obj.dataEntrega = null;
    } else{
        obj.dataEntrega = Converte.datePtBrToIso(pedidoCompra.value.dataEntrega);
    }

    if(operacao.value == 'R' && obj.dataEntrega == null) {
        snackBarApp.value.open('Para fazer o recebimento do pedido é necessário preencher a data de entrega.', 6000);
        return;
    }

    if(pedidoCompra.value.dataFaturamento == '' || pedidoCompra.value.dataFaturamento == 'Invalid date') {
        obj.dataFaturamento = null;
    } else{
        obj.dataFaturamento = Converte.datePtBrToIso(pedidoCompra.value.dataFaturamento);
    }

    let dados = new FormData();
    dados.append('dados', JSON.stringify(obj));

    try {
        let response = await PedidoCompra.faturaCheque(dados);
        if (response.status == 200) {

            if (response.data.success == false) {
                snackBarApp.value.open('Não foi possível faturar pedido de compra com cheque, '+response.data.message, 6000);
                return;
            }

            if(operacao.value == 'F') {
                snackBarApp.value.open('Pedido de compra faturado com cheque com sucesso.');
            }
            else {
                snackBarApp.value.open('Pedido de compra faturado e recebido com sucesso.');
            }
            

            emit('faturouPedidoCompra', 'CHE');
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao faturar pedido de compra com cheque.');
        console.log(err);
    }
}
     
function changeDescRP() {
    if(pedidoCompra.value.descRP == 'P') {
        pedidoCompra.value.desconto = Converte.numberToPorcentagem(Converte.stringToFloat(pedidoCompra.value.desconto),2);
    }
    if(pedidoCompra.value.descRP == 'R') {
        pedidoCompra.value.desconto = Converte.numberToCurrency(Converte.stringToFloat(pedidoCompra.value.desconto));
    }

    calculaValorAPagar();
}

function changeAcreRP() {
    if(pedidoCompra.value.acreRP == 'P') {
        pedidoCompra.value.acrescimo = Converte.numberToPorcentagem(Converte.stringToFloat(pedidoCompra.value.acrescimo),2);
    }
    if(pedidoCompra.value.acreRP == 'R') {
        pedidoCompra.value.acrescimo = Converte.numberToCurrency(Converte.stringToFloat(pedidoCompra.value.acrescimo));
    }

    calculaValorAPagar();
}

function calculaValorAPagar() {

    let valorAPagar = 0;
    let desconto = Converte.stringToFloat(pedidoCompra.value.desconto);
    let acrescimo = Converte.stringToFloat(pedidoCompra.value.acrescimo);
    let descRP = pedidoCompra.value.descRP;
    let acreRP = pedidoCompra.value.acreRP;
    let valorTotalP = Converte.stringToFloat(pedidoCompra.value.valorTotalProdutos);

    if(descRP == 'R') {
        valorAPagar = valorTotalP - desconto;
    } else {
        valorAPagar = valorTotalP - (valorTotalP * desconto / 100);
    }

    if(acreRP == 'R') {
        valorAPagar = valorAPagar + acrescimo;
    } else {
        valorAPagar = valorAPagar +  (valorTotalP * acrescimo / 100);
    }

    pedidoCompra.value.valorTotalComDesconto = Converte.numberToCurrency(valorAPagar);

    cheque.value.valor = valorRestante();
}

async function getPedidoCompra(idPedidoCompra) {
    let dados = new FormData();
    dados.append('codigoModulo', '03.13.02');
    dados.append('idPedidoCompra', idPedidoCompra);

    try {

        let response = await PedidoCompra.getById(dados);
        if (response.status == 200) {

            pedidoCompra.value.documento = response.data.data.documento;

            if (route.name == 'PedidosCompras.Show' || response.data.data.formaPagamento !== null) {

                pedidoCompra.value.dataFaturamento = Converte.dateIsoToPtBr(response.data.data.dataFaturamento);
                pedidoCompra.value.dataEntrega = Converte.dateIsoToPtBr(response.data.data.dataEntrega);
                pedidoCompra.value.descRP = response.data.data.descRP;
                if (response.data.data.descRP == 'R') {
                    pedidoCompra.value.desconto = Converte.numberToCurrency(response.data.data.desconto);
                } else {
                    pedidoCompra.value.desconto = Converte.numberToPorcentagem(response.data.data.desconto, 2);
                }

                pedidoCompra.value.acreRP = response.data.data.acreRP;
                if (response.data.data.acreRP == 'R') {
                    pedidoCompra.value.acrescimo = Converte.numberToCurrency(response.data.data.acrescimo);
                } else {
                    pedidoCompra.value.acrescimo = Converte.numberToPorcentagem(response.data.data.acrescimo, 2);
                }
                pedidoCompra.value.entrada  = Converte.numberToCurrency(response.data.data.entrada);
                pedidoCompra.value.numeroParcelas = response.data.data.numeroParcelas;
            }

            pedidoCompra.value.documento = response.data.data.documento;
            if(route.name !== 'PedidosCompras.Show' && operacao.value == 'R') {
                pedidoCompra.value.dataEntrega = new Date().toLocaleDateString("pt-BR");
            }

            calculaValorAPagar();

            getCheques();
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar o pedido de compra.');
        console.log(err);
    }
}

async function getCheques() {
    try {
        let response = await PedidoCompra.getCheques(pedidoCompra.value.idPedidoCompra);
        pedidoCompra.value.cheques = response.data.data;
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar os cheques do pedido de compra.');
        console.log(err);
    }
}


function incluirCheque() {
    let data = cheque.value.data;
    let bomPara = cheque.value.bomPara;

    if(data == null || data == "") {
        snackBarApp.value.open('A data de emissão do cheque precisa ser preenchida.', 6000);
        return;    
    }

    if(bomPara == null || bomPara == "") {
        snackBarApp.value.open('A data bom para do cheque precisa ser preenchida.', 6000);
        return;    
    }

    if(Converte.stringToFloat(cheque.value.valor) <= 0) {
        snackBarApp.value.open('O valor do cheque tem que ser maior que zero.', 6000);
        return;
    }

    if(cheque.value.idConta <= 0) {
        snackBarApp.value.open('A conta bancária deve ser selecionada, selecione uma conta bancária e tente novamente.', 6000);
        return;
    }

    pedidoCompra.value.cheques.push({
        'id':pedidoCompra.value.cheques.length + 1,
        'data':data,
        'bomPara':cheque.value.bomPara,
        'valor':cheque.value.valor,
        'idConta':cheque.value.idConta,
        'apelidoConta': apelidoConta.value,
        'banco': banco.value,
        'agencia': agencia.value,
        'conta': conta.value,
        'numero':cheque.value.numero,
        'observacoes':cheque.value.observacoes,
    });

    cheque.value.valor = valorRestante();
}

function valorRestante() {
    let valorTotalJaGerado = 0;
    let valor = 0;
    let valorTotal = Converte.stringToFloat(pedidoCompra.value.valorTotalComDesconto) - Converte.stringToFloat(pedidoCompra.value.entrada);
    let parcelas = pedidoCompra.value.numeroParcelas;

    for (var i=0; i < pedidoCompra.value.cheques.length; i++) {
        valorTotalJaGerado = valorTotalJaGerado + Converte.stringToFloat(pedidoCompra.value.cheques[i].valor);
    }

    if((parcelas -1)  == pedidoCompra.value.cheques.length) {
        valor = valorTotal - valorTotalJaGerado;
    } else if(parcelas == pedidoCompra.value.cheques.length) {
        valor = 0;
    }
    else {
        valor = (valorTotal - valorTotalJaGerado) / (parcelas - pedidoCompra.value.cheques.length)
    }

    if( pedidoCompra.value.cheques.length == pedidoCompra.value.numeroParcelas || valor <= 0 || operacao.value == 'C') {
        isReadOnlyCheque.value = true;
    }
    else {
        isReadOnlyCheque.value = false;
    }


    return Converte.numberToCurrency(valor);
}


async function getContas() {
    const response = await Conta.getContas();
    contas.value = response.data.data;

    if (contas.value.length > 0 && (pedidoCompra.value.idConta == null || pedidoCompra.value.idConta == 0)) {
        cheque.value.idConta = response.data.data[0].idConta;
    }

    changeConta();
}

async function changeConta() {
    let dados = new FormData();
    dados.append('codigoModulo', '03.09');
    dados.append('idConta', cheque.value.idConta);
    try {
        const response = await Conta.getById(dados);
        if (response.status == 200) {
            let auxConta = response.data.data;

            detalhesConta.value = 'Banco:'+auxConta.nomeBanco+' agência:'+auxConta.agencia+' nro.conta:'+auxConta.conta+
                ' saldo atual:'+Converte.numberToCurrency(auxConta.saldo);
            apelidoConta.value = auxConta.apelido;
            banco.value = auxConta.nomeBanco;
            agencia.value = auxConta.agencia;
            conta.value = auxConta.conta;

            if(auxConta == []) {
                detalhesConta.value = "";
            }

        }
    }
    catch (err) {
        console.log(err);
    }
}

function excluirCheque(auxCheque)
{
    let index = pedidoCompra.value.cheques.indexOf(auxCheque);
    if(index >= 0) {
        pedidoCompra.value.cheques.splice(index,1);
        cheque.value.valor = valorRestante();
    }
}

</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="operacao !== 'C'">Faturamento do Pedido de Compra com Cheque</h2>
            <h2 v-show="operacao == 'C'">Pedido de Compra Faturado com Cheque</h2>
        </v-card-title>
        <form ref="formFaturaPedidoCompraCheque" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Documento" id="documento" 
                            v-model="pedidoCompra.documento" maxlength="10" :readonly="isReadOnly"
                            />
                    </v-col>
                    <v-col cols="3">
                        <Data label="Data do Faturamento" id="dataFaturamento" v-model="pedidoCompra.dataFaturamento" :readonly="isReadOnly"/>
                    </v-col>    
                    <v-col cols="3">
                        <v-text-field label="Valor Total Produtos" id="valorTotalProdutos" v-model="pedidoCompra.valorTotalProdutos" readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-select
                                :items="realPorcentagem"
                                item-title="text"
                                item-value="value"
                                label="Desconto em R$ ou em %"
                                v-model="pedidoCompra.descRP"
                                :readonly="isReadOnly"
                                @update:modelValue="changeDescRP()"
                         ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" v-show="pedidoCompra.descRP == 'R'">
                        <Moeda label="Desconto em (R$)" id="desconto" v-model="pedidoCompra.desconto" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3" v-show="pedidoCompra.descRP == 'P'">
                        <Porcentagem label="Desconto em (%)" id="desconto" v-model="pedidoCompra.desconto" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <v-select
                                :items="realPorcentagem"
                                item-title="text"
                                item-value="value"
                                label="Acréscimo em R$ ou em %"
                                v-model="pedidoCompra.acreRP"
                                :readonly="isReadOnly"
                                @update:modelValue="changeAcreRP()"
                         ></v-select>
                    </v-col>
                    <v-col cols="3" v-show="pedidoCompra.acreRP == 'R'">
                        <Moeda label="Acréscimo em (R$)" id="acrescimo" v-model="pedidoCompra.acrescimo" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3" v-show="pedidoCompra.acreRP == 'P'">
                        <Porcentagem label="Acréscimo em (%)" id="acrescimo" v-model="pedidoCompra.acrescimo" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Total com Descontos e Acréscimos" id="valorTotalComDesconto" v-model="pedidoCompra.valorTotalComDesconto" readonly tabindex="-1" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <Moeda label="Entrada(R$)" id="entrada" v-model="pedidoCompra.entrada" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            label="Número de Parcelas"
                            id="numeroParcelas"
                            type="text"
                            v-model="pedidoCompra.numeroParcelas"
                            maxlength="9"
                            :readonly="isReadOnly"
                            @blur="calculaValorAPagar()"
                        />
                    </v-col>
                    <v-col cols="3" v-show="operacao == 'R' || route.name == 'PedidosCompras.Show'">
                        <Data label="Data da Entrega" id="dataEntrega" v-model="pedidoCompra.dataEntrega" :readonly="isReadOnly"/>
                    </v-col>
                </v-row>

                <v-card v-show="operacao !== 'C'">
                    <v-card-title>
                        <h2>Dados dos Cheques</h2>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="3">
                                <v-select
                                    :items="contas"
                                    item-title="apelido"
                                    item-value="idConta"
                                    label="Conta"
                                    v-model="cheque.idConta"
                                    :readonly="isReadOnlyCheque"
                                    @update:modelValue="changeConta()"
                                    no-data-text="Nenhuma conta bancária cadastrada."
                                ></v-select>
                            </v-col>

                            <v-col cols="9">
                            <v-text-field
                                label="Detalhes da Conta"
                                id="detalhesConta"
                                type="text"
                                v-model="detalhesConta"
                                readonly
                                tabIndex="-1"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3">
                                <Data label="Data de Emissão" id="data" v-model="cheque.data" :readonly="isReadOnlyCheque"/>
                            </v-col>
                            <v-col cols="3">
                                <Data label="Bom Para" id="bomPara" v-model="cheque.bomPara" :readonly="isReadOnlyCheque"/>
                            </v-col>
                            <v-col cols="3">
                                <Moeda label="Valor do Cheque" id="valor" v-model="cheque.valor" :readonly="isReadOnlyCheque"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3">
                                <v-text-field
                                    label="Nro.Cheque"
                                    id="numero"
                                    type="text"
                                    v-model="cheque.numero"
                                    maxlength="10"
                                    :readonly="isReadOnlyCheque"
                                />
                            </v-col>
                            <v-col cols="9">
                                <v-text-field
                                    label="Observações"
                                    id="observacoes"
                                    type="text"
                                    v-model="cheque.observacoes"
                                    maxlength="100"
                                    :readonly="isReadOnlyCheque"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2">
                                <v-btn v-show="!isReadOnlyCheque" color="primary" @click.prevent="incluirCheque()">Incluir Cheque</v-btn>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>


                <v-card class="mt-8" v-show="pedidoCompra.cheques && pedidoCompra.cheques.length">
                    <v-card-title>
                        <h2>Cheques</h2>
                    </v-card-title>
                    <v-card-text>
                        <v-table density="compact">
                            <thead>
                                <tr>
                                    <th class="text-center" v-show="!isReadOnly">Ações</th>
                                    <th>D.Emissão</th>    
                                    <th>Bom Para</th>
                                    <th>Apelido da Conta</th>
                                    <th>Valor</th>
                                    <th>Banco</th>
                                    <th>Agência</th>
                                    <th>Conta</th>
                                    <th>Nro.Cheque</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="o in pedidoCompra.cheques" :key="o">
                                    <td v-show="!isReadOnly">
                                        <v-tooltip text="Excluir o cheque.">
                                            <template v-slot:activator="{ props }">
                                                <v-btn
                                                    size="small"
                                                    v-bind="props"
                                                    variant="text"
                                                    icon="mdi-trash-can"
                                                    @click.prevent="excluirCheque(o)">
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                    </td>
                                    <td>{{ o.data }}</td>
                                    <td>{{ o.bomPara }}</td>
                                    <td>{{ o.apelidoConta }}</td>
                                    <td>{{ valorFormat(o.valor) }}</td>
                                    <td>{{ o.banco }}</td>
                                    <td>{{ o.agencia }}</td>
                                    <td>{{ o.conta }}</td>
                                    <td>{{ o.numero }}</td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-btn v-show="operacao !== 'C'&& pedidoCompra.cheques && pedidoCompra.cheques.length" color="primary" variant="elevated" 
                    @click.prevent="gravar()" id="btnConfirmarCheque">Confirmar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="emit('close-dialog')">
                    Fechar
                    </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>