<script setup>
import { ref, onMounted, inject, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { OrdemServicos } from "../../resources/ordemServicosResource";
import { Cliente } from "../../resources/clienteResource";
import { Vendedor } from "../../resources/vendedorResource";
import { useField, useForm } from "vee-validate";
import { string, number, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import { Relatorio } from "../../resources/relatoriosResource";
import config from "../../services/config";
import ClienteCreate from "../Clientes/ClienteCreate.vue";
import ClienteBusca from "../Clientes/ClienteBusca.vue";
import VendedorBusca from "../Vendedores/VendedorBusca.vue";
import ProdutoOrdemServicos from "./ProdutoOrdemServicos.vue";
import ServicoOrdemServicos from "./ServicoOrdemServicos.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";
import ObservacoesOrdemServicos from "./ObservacoesOrdemServicos.vue";
import ObservacoesInternasOrdemServicos from "./ObservacoesInternasOrdemServicos.vue";
import FormaPagamentoOrdemServicos from "./FormaPagamentoOrdemServicos.vue";
import { quantidadeProdutoFormat, quantidadeServicoFormat, valorFormat } from "../../services/filtros";
import AVistaOrdemServicos from "./AVistaOrdemServicos.vue";
import APrazoOrdemServicos from "./APrazoOrdemServicos.vue";
import ChequeOrdemServicos from "./ChequeOrdemServicos.vue";
import CartaoOrdemServicos from "./CartaoOrdemServicos.vue";
import { useConfiguracoesOrdensServicos } from "../../store/configuracoesOrdensServicos";
import UpdateProdutoOrdemServicos from "./UpdateProdutoOrdemServicos.vue";
import UpdateServicoOrdemServicos from "./UpdateServicoOrdemServicos.vue";
import AdiantamentoOrdemServicos from "./AdiantamentoOrdemServicos.vue";

const configuracoesOrdensServicosStore = useConfiguracoesOrdensServicos();
const isRealValidation = ref(null);
const validationCodigo = ref(null);
const isReadOnly = ref(false);
const route = useRoute();
const router = useRouter();
const dialogCliente = ref(false);
const dialogBuscaCliente = ref(false);
const dialogBuscaVendedor = ref(false);
const dialogObservacoes = ref(false);
const dialogObservacoesInternas = ref(false);
const dialogFormaPagamento = ref(false);
const dialogFinalizaAVista = ref(false);
const dialogFinalizaAPrazo = ref(false);
const dialogFinalizaCheque = ref(false);
const dialogFinalizaCartao = ref(false);
const dialogProdutoOrdemServicos = ref(false);
const dialogServicoOrdemServicos = ref(false);
const dialogUpdateProdutoOrdemServicos = ref(false);
const dialogUpdateServicoOrdemServicos = ref(false);
const dialogAdiantamento = ref(false);
const operacao = ref('F');

let showBtnGravar = ref(true);
const produtosOrdemServicos = ref([]);
const servicosOrdemServicos = ref([]);

const situacao = ref("");
const totalProdutos = ref(0);
const totalServicos = ref(0);
const total = ref(0);
const adiantamento = ref(0);
const totalAdiantamento = ref(0);

const labelCampo1 = ref(configuracoesOrdensServicosStore.labelCampo1);
const labelCampo2 = ref(configuracoesOrdensServicosStore.labelCampo2);
const labelCampo3 = ref(configuracoesOrdensServicosStore.labelCampo3);
const labelCampo4 = ref(configuracoesOrdensServicosStore.labelCampo4);
const labelCampo5 = ref(configuracoesOrdensServicosStore.labelCampo5);
const jaCarregado = ref(configuracoesOrdensServicosStore.jaCarregado);

const snackBarApp = inject("snackBarApp");

const permissoesStore = usePermissoes();

addMethod(number, "codigo_ordem_servicos_existe", function codigoOrdemServicosExiste(message) {
    return number().test(
        "codigo-ordem-servicos-existe", // Name
        message, // Msg
        function (codigo) {
            if (isRealValidation.value == null) {
                return true;
            }
            if (!isRealValidation.value) {
                return validationCodigo.value;
            }
            isRealValidation.value = false;
            validationCodigo.value = false;

            return new Promise((resolve) => {
                OrdemServicos.codigoExiste(codigo, ordemServicos.value.idOrdemServicos).then((res) => {
                    if (res.data == false) {
                        validationCodigo.value = true;
                        resolve(true);
                    }
                    resolve(false);
                });
            });
        }
    );
});


const validationSchema = object({
    ordemServicos: object({
        idOrdemServicos: undefined,
        codigo: number()
            .min(1)
            .max(999999999)
            .codigo_ordem_servicos_existe("Esse código já está sendo usado por outra ordem de serviços, tente outro código.")
            .typeError("O código deve ser um número")
            .label("O código"),
        data: string().required("A data é obrigatória").label("A data"),
        dataFinalizacao: undefined,
        idCliente: undefined,
        codigoCliente: undefined,
        nomeCliente: undefined,
        idVendedor: undefined,
        codigoVendedor: undefined,
        nomeVendedor: undefined,
        campo1: string().max(50),
        campo2: string().max(50),
        campo3: string().max(50),
        campo4: string().max(50),
        campo5: string().max(50),
        prazo: undefined,
        situacao: undefined,
        formaPagamento: undefined,

    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const ordemServicos = ref({
    idOrdemServicos: useField("ordemServicos.idOrdemServicos").value,
    codigo: useField("ordemServicos.codigo").value,
    data: useField("ordemServicos.data").value,
    dataFinalizacao: useField("ordemServicos.dataFinalizacao").value,
    idCliente: useField("ordemServicos.idCliente").value,
    codigoCliente: useField("ordemServicos.codigoCliente").value,
    nomeCliente: useField("ordemServicos.nomeCliente").value,
    idVendedor: useField("ordemServicos.idVendedor").value,
    codigoVendedor: useField("ordemServicos.codigoVendedor").value,
    nomeVendedor: useField("ordemServicos.nomeVendedor").value,
    campo1: useField("ordemServicos.campo1").value,
    campo2: useField("ordemServicos.campo2").value,
    campo3: useField("ordemServicos.campo3").value,
    campo4: useField("ordemServicos.campo4").value,
    campo5: useField("ordemServicos.campo5").value,
    prazo: useField("ordemServicos.prazo").value,
    situacao: useField("ordemServicos.situacao").value,
    formaPagamento: useField("ordemServicos.formaPagamento").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});


onMounted(() => {
    if (jaCarregado.value == false) {
        setTimeout(() => {
            labelCampo1.value = configuracoesOrdensServicosStore.labelCampo1;
            labelCampo2.value = configuracoesOrdensServicosStore.labelCampo2;
            labelCampo3.value = configuracoesOrdensServicosStore.labelCampo3;
            labelCampo4.value = configuracoesOrdensServicosStore.labelCampo4;
            labelCampo5.value = configuracoesOrdensServicosStore.labelCampo5;
        }, 1000);
    }

    cleanForm();
    isReadOnly.value = false;

    if (route.name == "OrdensServicos.Continuar" || route.name == "OrdensServicos.Show") {
        getOrdemServicos(route.params.id);
    } else {
        document.getElementById("codigo").focus();
    }

    if (route.name == "OrdensServicos.Show") {
        isReadOnly.value = true;
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name == "OrdensServicos.Create") {
        ordemServicos.value.idOrdemServicos = null;
        proximoCodigo();
    }

    ordemServicos.value.data = new Date().toLocaleDateString("pt-BR");
    ordemServicos.value.dataFinalizacao = "";
    ordemServicos.value.idCliente = null;
    ordemServicos.value.codigoCliente = "";
    ordemServicos.value.nomeCliente = "";
    ordemServicos.value.idVendedor = null;
    ordemServicos.value.codigoVendedor = "";
    ordemServicos.value.nomeVendedor = "";
    ordemServicos.value.prazo = "";
    ordemServicos.value.situacao = "A";
    ordemServicos.value.formaPagamento = "";
    ordemServicos.value.campo1 = "";
    ordemServicos.value.campo2 = "";
    ordemServicos.value.campo3 = "";
    ordemServicos.value.campo4 = "";
    ordemServicos.value.campo5 = "";
}


async function gravar(values) {
    let dados = new FormData();

    values.ordemServicos.data = Converte.datePtBrToIso(values.ordemServicos.data);

    if (values.ordemServicos.idCliente == "") {
        values.ordemServicos.idCliente = null;
    }

    let prazo = '';

    if (values.ordemServicos.prazo == '') {
        prazo = null;
    } else {
        prazo = Converte.datePtBrToIso(values.ordemServicos.prazo);
    }

    values.ordemServicos.prazo = prazo;

    dados.append("dados", JSON.stringify(values.ordemServicos));

    if (route.name == 'OrdensServicos.Create' || route.name == 'OrdensServicos.Continuar') {
        try {
            const response = await OrdemServicos.gravar(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open("A ordem de serviços não pode ser cadastrada, mensagem de erro: " + response.data.message, 6000);
                    return;
                }


                if (route.name == 'OrdensServicos.Create') {
                    let idOrdemServicos = response.data.idOrdemServicos;
                    ordemServicos.value.idOrdemServicos = idOrdemServicos;
                    snackBarApp.value.open("Nova ordem de serviços gravada com sucesso.");
                    // isReadOnlyDadosProduto.value = false;
                    router.push({ name: 'OrdensServicos.Continuar', params: { id: idOrdemServicos } });

                    setTimeout(function () {
                        //                        document.getElementById("codigoProduto").focus();
                    }, 200);

                }

                if (route.name == 'OrdensServicos.Continuar') {
                    // isReadOnlyDadosProduto.value = false;
                    snackBarApp.value.open("Ordem de Serviços gravada com sucesso.");
                }
            }
        } catch (err) {
            snackBarApp.value.open("Erro, mensagem de erro: " + err);
        }
    }
}

async function getOrdemServicos(id) {
    let dados = new FormData();

    if (route.name == "OrdensServicos.Continuar") {
        dados.append("codigoModulo", "03.03.02");
    } else if (route.name == "OrdensServicos.Show") {
        dados.append("codigoModulo", "03.03.03");
    }

    dados.append("idOrdemServicos", id);

    try {
        let response = await OrdemServicos.getById(dados)
        if (response.status == 200) {

            response.data.data.data = Converte.dateIsoToPtBr(response.data.data.data);
            if (response.data.data.prazo !== null) {
                response.data.data.prazo = Converte.dateIsoToPtBr(response.data.data.prazo);
            }
            if (response.data.data.dataFinalizacao !== null) {
                response.data.data.dataFinalizacao = Converte.dateIsoToPtBr(response.data.data.dataFinalizacao);
            }

            if (response.data.data.formaPagamento == null) {
                response.data.data.formaPagamento = "";
            }

            response.data.data.valorTotal = Converte.numberToCurrency(response.data.data.valorTotal);

            let sit = response.data.data.situacao;

            if(route.name == 'OrdensServicos.Continuar' && sit == 'F') {
                snackBarApp.value.open('Ordem de serviços finalizada não pode ser alterada, entrando na consulta dessa ordem de serviços.');
                router.push({ name: 'OrdensServicos.Show', params: { id: id } });
            }

            if (sit == "A") {
                situacao.value = "Situação da ordem de serviços: Em Aberto";

            } else if (sit == 'F') {
                if (response.data.data.formaPagamento == 'CAI') {
                    situacao.value = "Situação da ordem de serviços: Finalizada a Vista";
                }
                if (response.data.data.formaPagamento == 'PRA') {
                    situacao.value = "Situação da ordem de serviços: Finalizada a Prazo";
                }
                if (response.data.data.formaPagamento == 'CHE') {
                    situacao.value = "Situação da ordem de serviços: Finalizada com Cheque";
                }
                if (response.data.data.formaPagamento == 'CAR') {
                    situacao.value = "Situação da ordem de serviços: Finalizada com Cartão/Convênio";
                }

            } else if (sit == 'C') {
                situacao.value = "Situação da ordem de serviços: Cancelada";
            }

            if (route.name == "OrdensServicos.Continuar" && response.data.data.formaPagamento !== "") {
                isReadOnly.value = true;
                // isReadOnlyDadosProduto.value = true;
            }


            setValues({ ordemServicos: response.data.data });
            getProdutos(response.data.data.idOrdemServicos);
            getServicos(response.data.data.idOrdemServicos);
        }
    }
    catch (err) {
        console.log(err);
    }
}

async function proximoCodigo() {
    try {
        const response = await OrdemServicos.proximoCodigo();
        if (response.status == 200) {
            ordemServicos.value.codigo = response.data;
        }
    } catch (err) {
        console.log(err);
    }
}

async function getCliente() {
    ordemServicos.value.idCliente = null;
    ordemServicos.value.nomeCliente = "";

    if (ordemServicos.value.codigoCliente == null || ordemServicos.value.codigoCliente.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", ordemServicos.value.codigoCliente);
    dados.append("getInativos", false);
    dados.append("codigoModulo", "03.03");

    try {
        let response = await Cliente.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    snackBarApp.value.open("Um cliente inativo não pode gerar novas ordens de serviços.", 6000);
                    ordemServicos.value.codigoCliente = "";
                    document.getElementById("codigoCliente").focus();
                    return;
                }
                if (response.data.message == "bloqueado") {
                    snackBarApp.value.open("Um cliente bloqueado não pode gerar novas ordens de serviços.", 6000);
                    ordemServicos.value.codigoCliente = "";
                    document.getElementById("codigoCliente").focus();
                    return;
                }
                ordemServicos.value.codigoCliente = "";
                document.getElementById("codigoCliente").focus();

                snackBarApp.value.open("Cliente não encontrado, tente outro código ou faça uma busca.", 6000);
                return;
            }
            ordemServicos.value.idCliente = response.data.data.idCliente;
            ordemServicos.value.nomeCliente = response.data.data.nome;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouCliente(cliente) {
    if (cliente == null) {
        return;
    }

    ordemServicos.value.codigoCliente = cliente.codigo;
    ordemServicos.value.nomeCliente = cliente.nome;
    ordemServicos.value.idCliente = cliente.idCliente;

    getCliente();
    dialogBuscaCliente.value = false;

    setTimeout(() => {
        document.getElementById("codigoVendedor").focus();
    }, 100);
}

async function getVendedor() {
    ordemServicos.value.idVendedor = null;
    ordemServicos.value.nomeVendedor = "";

    if (ordemServicos.value.codigoVendedor == null || ordemServicos.value.codigoVendedor.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", ordemServicos.value.codigoVendedor);
    dados.append("getInativos", false);
    dados.append("codigoModulo", "03.03");

    try {
        let response = await Vendedor.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    ordemServicos.value.codigoVendedor = "";
                    document.getElementById("codigoVendedor").focus();
                    snackBarApp.value.open("Um vendedor/funcionário inativo não pode gerar novas ordens de serviços.", 6000);
                    return;
                }
                if (response.data.message == "bloqueado") {
                    ordemServicos.value.codigoVendedor = "";
                    document.getElementById("codigoVendedor").focus();
                    snackBarApp.value.open("Um vendedor/funcionário bloqueado não pode gerar novas ordens de serviços.", 6000);
                    return;
                }
                ordemServicos.value.codigoVendedor = "";
                document.getElementById("codigoVendedor").focus();
                snackBarApp.value.open("Vendedor/Funcionário não encontrado, tente outro código ou faça uma busca.", 6000);
                return;
            }
            ordemServicos.value.idVendedor = response.data.data.idVendedor;
            ordemServicos.value.nomeVendedor = response.data.data.nome;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouVendedor(vendedor) {
    if (vendedor == null) {
        return;
    }

    ordemServicos.value.codigoVendedor = vendedor.codigo;
    ordemServicos.value.nomeVendedor = vendedor.nome;
    ordemServicos.value.idVendedor = vendedor.idVendedor;

    getVendedor();
    dialogBuscaVendedor.value = false;

    setTimeout(() => {
        document.getElementById("campo1").focus();
    }, 100);
}

function cadastrouCliente(codigoCliente) {
    if (codigoCliente == null) {
        return;
    }

    ordemServicos.value.codigoCliente = codigoCliente;

    getCliente();
}

async function totais(idOrdemServicos) {
    try {
        const response = await OrdemServicos.totais(idOrdemServicos);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("Erro ao calcular os totais da ordem de serviços, mensagem: " + response.data.message, 6000);
                return;
            }

            totalProdutos.value = response.data.data.totalProdutos;
            totalServicos.value = response.data.data.totalServicos;
            total.value = response.data.data.total;
            adiantamento.value = response.data.data.adiantamento;
            totalAdiantamento.value = response.data.data.totalAdiantamento;

        }
    } catch (err) {
        console.log(err);
    }
}

function finalizouOrdemServicos() {
    dialogFormaPagamento.value = false;
    cleanForm();
    ordemServicos.value.idOrdemServicos = null;
    proximoCodigo();
    totais(null);
    produtosOrdemServicos.value = [];
    servicosOrdemServicos.value = [];
    router.push({ name: "OrdensServicos.Create" });
}

function addProduto(produtoOrdemServicos) {
    produtoOrdemServicos.valorUnitario = Converte.stringToFloat(produtoOrdemServicos.valorUnitario).toString();
    produtosOrdemServicos.value.push(produtoOrdemServicos);
    totais(route.params.id);
}

function addServico(servicoOrdemServicos) {
    servicoOrdemServicos.valorUnitario = Converte.stringToFloat(servicoOrdemServicos.valorUnitario).toString();
    servicosOrdemServicos.value.push(servicoOrdemServicos);
    totais(route.params.id);
}


async function getProdutos(idOrdemServicos) {
    try {
        const response = await OrdemServicos.listarProdutos(idOrdemServicos);
        if (response.status == 200) {
            produtosOrdemServicos.value = response.data.data;
            totais(idOrdemServicos);
        }
    } catch (err) {
        console.log(err);
    }
}

async function getServicos(idOrdemServicos) {
    try {
        const response = await OrdemServicos.listarServicos(idOrdemServicos);
        if (response.status == 200) {
            servicosOrdemServicos.value = response.data.data;
            totais(idOrdemServicos);
        }
    } catch (err) {
        console.log(err);
    }
}


async function removeProduto(idProdutoOrdemServicos) {
    try {
        const response = await OrdemServicos.removeProduto(idProdutoOrdemServicos);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O item não pode ser excluído da ordem de serviços, mensagem: " + response.data.message, 6000);
                return;
            }

            snackBarApp.value.open("Item excluído com sucesso da ordem de serviços.", 1500);
            getProdutos(route.params.id);
            totais(route.params.id);
        }
    } catch (err) {
        console.log(err);
    }
}

async function removeServico(idServicoOrdemServicos) {
    try {
        const response = await OrdemServicos.removeServico(idServicoOrdemServicos);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O item não pode ser excluído da ordem de serviços, mensagem: " + response.data.message, 6000);
                return;
            }

            snackBarApp.value.open("Item excluído com sucesso da ordem de serviços.", 1500);
            getServicos(route.params.id);
            totais(route.params.id);
        }
    } catch (err) {
        console.log(err);
    }
}


function abreInformacoesPagamento() {
    if (ordemServicos.value.formaPagamento == "CAI") {
        dialogFinalizaAVista.value = true;
    }
    if (ordemServicos.value.formaPagamento == "PRA") {
        dialogFinalizaAPrazo.value = true;
    }
    if (ordemServicos.value.formaPagamento == "CHE") {
        dialogFinalizaCheque.value = true;
    }
    if (ordemServicos.value.formaPagamento == "CAR") {
        dialogFinalizaCartao.value = true;
    }
}

function finalizarOrdemServicos() {
    operacao.value = 'F';
    dialogFormaPagamento.value = true;
}

async function imprimir() {
    let nomeRel;
    nomeRel = 'OrdemServicos';
    try {
        let response = await Relatorio.get(nomeRel, route.params.id);

        if (response.status == 200) {
            if(response.data.success == true) {
                let nomeArquivo = response.data.nomeArquivo;
                let url = config.baseUrlRel+nomeArquivo;
                window.open(url, '_blank');
                return;
            }
            snackBarApp.value.open('Erro ao imprimir a ordem de serviços, mensagem: '+response.data.message);
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao imprimir a ordem de serviços.');
        console.log(err);
    }
}

const keydownHandler = (event) => {
    if(event.key === 'F5') {
        event.preventDefault();
    }

    if(event.key === 'F7') {
        event.preventDefault();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoCliente' && document.getElementById('codigoCliente').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnBuscaCliente').click();
    }

    if (event.key === 'F7' && document.activeElement.id === 'codigoCliente' && document.getElementById('codigoCliente').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnNovoCliente').click();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoVendedor' && document.getElementById('codigoVendedor').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnBuscaVendedor').click();
    }
};

document.addEventListener('keydown', keydownHandler);

onUnmounted(() => {
    document.removeEventListener('keydown', keydownHandler);
});


</script>

<template>
    <v-card elevetion="16">
        <v-card-title>
            <h2 v-show="$route.name == 'OrdensServicos.Create'">Nova Ordem de Serviços</h2>
        </v-card-title>
        <v-card-text>
            <v-card elevation="16" class="mb-8">
                <v-card-title>
                    <h3>Dados da Ordem de Serviços</h3>
                </v-card-title>
                <form ref="formOrdemServicos" @submit.prevent="submit">
                    <v-card-text>
                        <v-row>
                            <v-col cols="4">
                                <v-text-field label="Código" id="codigo" type="text" v-model="ordemServicos.codigo"
                                    :error-messages="errors['ordemServicos.codigo']" maxlength="9" @blur="(e) => {
                                        isRealValidation = true;
                                        ordemServicos.codigo = e.target.value;
                                    }
                                        " :readonly="isReadOnly" />
                            </v-col>
                            <v-col cols="4">
                                <Data label="Data" id="data" v-model="ordemServicos.data"
                                    :error-messages="errors['ordemServicos.data']" :readonly="isReadOnly"></Data>
                            </v-col>

                            <v-col cols="4">
                                <Data label="Prazo para Entrega" id="prazo" v-model="ordemServicos.prazo"
                                    :error-messages="errors['ordemServicos.prazo']" :readonly="isReadOnly"></Data>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3">
                                <v-text-field label="Código do Cliente" id="codigoCliente" type="text"
                                    v-model="ordemServicos.codigoCliente" maxlength="10"
                                    :error-messages="errors['ordemServicos.codigoCliente']" @blur="getCliente()"
                                    :readonly="isReadOnly"
                                    v-tooltip='isReadOnly ? "Código do cliente" : "F5-Abre a busca de clientes, F7-Cadastra um novo cliente"'/>
                            </v-col>
                            <v-col>
                                <v-text-field label="Nome do Cliente" id="nomeCliente" type="text"
                                    v-model="ordemServicos.nomeCliente" maxlength="50"
                                    :error-messages="errors['ordemServicos.nomeCliente']" readonly tabindex="-1">
                                    <template v-slot:append
                                        v-if="route.name !== 'OrdensServicos.Show' && (ordemServicos.dataFinalizacao == '' || ordemServicos.dataFinalizacao == null)">
                                        <v-tooltip text="Faz a busca de um cliente por nome/apelido/cpf">
                                            <template v-slot:activator="{ props }">
                                                <v-btn v-bind="props" color="primary" @click="dialogBuscaCliente = true"
                                                    id="btnBuscaCliente">
                                                    <v-icon>mdi-magnify</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                        <v-dialog v-model="dialogBuscaCliente" transition="dialog-top-transition">
                                            <ClienteBusca @selecionouCliente="selecionouCliente"
                                                @close-dialog="dialogBuscaCliente = false"
                                                :select-inativo-bloqueado=false />
                                        </v-dialog>
                                        &nbsp;
                                        <v-tooltip text="Cadastra um novo cliente.">
                                            <template v-slot:activator="{ props }">
                                                <v-btn v-bind="props" color="primary" @click="dialogCliente = true"
                                                    id="btnNovoCliente">
                                                    <v-icon>mdi-invoice-text-plus</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                        <v-dialog v-model="dialogCliente" transition="dialog-top-transition">
                                            <ClienteCreate @cadastrouCliente="cadastrouCliente"
                                                @close-dialog="dialogCliente = false" />
                                        </v-dialog>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3">
                                <v-text-field label="Código do Vendedor" id="codigoVendedor" type="text"
                                    v-model="ordemServicos.codigoVendedor" maxlength="10"
                                    :error-messages="errors['ordemServicos.codigoVendedor']" @blur="getVendedor()"
                                    :readonly="isReadOnly"
                                    v-tooltip='isReadOnly ? "Código do vendedor/funcionário" : "F5-Abre a busca de vendedores/funcionários"'>
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Nome do Vendedor" id="nomeVendedor" type="text"
                                    v-model="ordemServicos.nomeVendedor" maxlength="50"
                                    :error-messages="errors['ordemServicos.nomeVendedor']" readonly tabindex="-1">
                                    <template v-slot:append
                                        v-if="route.name !== 'OrdensServicos.Show' && ordemServicos.dataFinalizacao == ''">
                                        <v-tooltip text="Faz a busca de um vendedor/funcionário por nome apelido/cpf">
                                            <template v-slot:activator="{ props }">
                                                <v-btn v-bind="props" color="primary"
                                                    @click="dialogBuscaVendedor = true" id="btnBuscaVendedor">
                                                    <v-icon>mdi-magnify</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                        <v-dialog v-model="dialogBuscaVendedor" transition="dialog-top-transition">
                                            <VendedorBusca @selecionouVendedor="selecionouVendedor"
                                                @close-dialog="dialogBuscaVendedor = false"
                                                :select-inativo-bloqueado=false />
                                        </v-dialog>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <v-text-field :label="labelCampo1" id="campo1" type="text"
                                    v-model="ordemServicos.campo1" maxlength="50"
                                    :error-messages="errors['ordemServicos.campo1']" :readonly="isReadOnly" />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field :label="labelCampo2" id="campo2" type="text"
                                    v-model="ordemServicos.campo2" maxlength="50"
                                    :error-messages="errors['ordemServicos.campo2']" :readonly="isReadOnly" />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field :label="labelCampo3" id="campo3" type="text"
                                    v-model="ordemServicos.campo3" maxlength="50"
                                    :error-messages="errors['ordemServicos.campo3']" :readonly="isReadOnly" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field :label="labelCampo4" id="campo4" type="text"
                                    v-model="ordemServicos.campo4" maxlength="50"
                                    :error-messages="errors['ordemServicos.campo4']" :readonly="isReadOnly" />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :label="labelCampo5" id="campo5" type="text"
                                    v-model="ordemServicos.campo5" maxlength="50"
                                    :error-messages="errors['ordemServicos.campo5']" :readonly="isReadOnly" />
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" v-show="$route.name !== 'OrdensServicos.Show' && showBtnGravar &&
                            (ordemServicos.dataFinalizacao == '' || ordemServicos.dataFinalizacao == null)"
                            variant="elevated" type="submit" id="btnOrdemServicosGravar">Gravar</v-btn>
                    </v-card-actions>
                </form>
            </v-card>

            <v-dialog v-model="dialogProdutoOrdemServicos" transition="dialog-top-transition">
                <ProdutoOrdemServicos :idOrdemServicos="ordemServicos.idOrdemServicos" @addProduto="addProduto"
                    @closeDialog="dialogProdutoOrdemServicos = false">
                </ProdutoOrdemServicos>
            </v-dialog>

            <v-card elevation="16" class="mb-8" v-show="route.name !== 'OrdensServicos.Create'">
                <v-card-title>
                    <h3>Produtos</h3>
                </v-card-title>
                <v-card-text>
                    <v-table density="compact">
                        <thead>
                            <tr>
                                <th class="text-center"
                                    v-show="route.name == 'OrdensServicos.Continuar' && ordemServicos.formaPagamento == ''">
                                    Ações
                                </th>
                                <th>Cód.Produto</th>
                                <th>Descrição do Produto</th>
                                <th>Unid.</th>
                                <th>Quantidade</th>
                                <th>Valor Unitário</th>
                                <th>Valor Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="o in produtosOrdemServicos" :key="o">
                                <td
                                    v-show="route.name == 'OrdensServicos.Continuar' && ordemServicos.formaPagamento == ''">
                                    <v-tooltip text="Altera o item.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn size="small" v-bind="props" variant="text"
                                                icon="mdi-invoice-edit-outline"
                                                @click.prevent="auxProdutoOrdemServicos = Object.assign({}, o);; dialogUpdateProdutoOrdemServicos = true;">
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                    <v-tooltip text="Exclui o item.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn size="small" v-bind="props" variant="text" icon="mdi-trash-can"
                                                @click.prevent="removeProduto(o.idProdutoOrdemServicos)">
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                </td>
                                <td>{{ o.codigoProduto }}</td>
                                <td>{{ o.descricaoProduto }}</td>
                                <td>{{ o.unidadeProduto }}</td>
                                <td>{{ quantidadeProdutoFormat(o.quantidade) }}</td>
                                <td>{{ valorFormat(o.valorUnitario) }}</td>
                                <td>{{ valorFormat(o.valorTotal) }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" variant="elevated" @click="dialogProdutoOrdemServicos = true"
                        v-show="route.name == 'OrdensServicos.Continuar' && ordemServicos.formaPagamento == ''">
                        Adicionar Produto
                    </v-btn>
                    <v-spacer></v-spacer>
                    <h2 class="text-right">Valor Total dos Produtos: {{ valorFormat(totalProdutos) }}</h2>
                </v-card-actions>
            </v-card>

            <v-card elevation="16" class="mb-8" v-show="route.name !== 'OrdensServicos.Create'">
                <v-card-title>
                    <h3>Serviços</h3>
                </v-card-title>
                <v-card-text>
                    <v-table density="compact">
                        <thead>
                            <tr>
                                <th class="text-center"
                                    v-show="route.name == 'OrdensServicos.Continuar' && ordemServicos.formaPagamento == ''">
                                    Ações
                                </th>
                                <th>Cód.Serviço</th>
                                <th>Descrição do Serviço</th>
                                <th>Unid.</th>
                                <th>Quantidade</th>
                                <th>Valor Unitário</th>
                                <th>Valor Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="o in servicosOrdemServicos" :key="o">
                                <td
                                    v-show="route.name == 'OrdensServicos.Continuar' && ordemServicos.formaPagamento == ''">
                                    <v-tooltip text="Altera o item.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn size="small" v-bind="props" variant="text"
                                                icon="mdi-invoice-edit-outline"
                                                @click.prevent="auxServicoOrdemServicos = Object.assign({}, o);; dialogUpdateServicoOrdemServicos = true;">
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                    <v-tooltip text="Exclui o item.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn size="small" v-bind="props" variant="text" icon="mdi-trash-can"
                                                @click.prevent="removeServico(o.idServicoOrdemServicos)">
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                </td>
                                <td>{{ o.codigoServico }}</td>
                                <td>{{ o.descricaoServico }}</td>
                                <td>{{ o.unidadeServico }}</td>
                                <td>{{ quantidadeServicoFormat(o.quantidade) }}</td>
                                <td>{{ valorFormat(o.valorUnitario) }}</td>
                                <td>{{ valorFormat(o.valorTotal) }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" variant="elevated" @click="dialogServicoOrdemServicos = true"
                        v-show="route.name == 'OrdensServicos.Continuar' && ordemServicos.formaPagamento == ''">
                        Adicionar Serviço
                    </v-btn>
                    <v-spacer></v-spacer>
                    <h2 class="text-right">Valor Total dos Servicos: {{ valorFormat(totalServicos) }}</h2>
                </v-card-actions>
            </v-card>

            <v-dialog v-model="dialogUpdateProdutoOrdemServicos" transition="dialog-top-transition">
                <UpdateProdutoOrdemServicos :produtoOrdemServicos=auxProdutoOrdemServicos
                    @close-dialog="dialogUpdateProdutoOrdemServicos = false"
                    @alterou-produto="getProdutos(route.params.id); dialogUpdateProdutoOrdemServicos = false" />
            </v-dialog>


            <v-dialog v-model="dialogServicoOrdemServicos" transition="dialog-top-transition">
                <ServicoOrdemServicos
                    :idOrdemServicos="ordemServicos.idOrdemServicos" @addServico="addServico"
                    @closeDialog="dialogServicoOrdemServicos = false"> </ServicoOrdemServicos>
            </v-dialog>

            <v-dialog v-model="dialogUpdateServicoOrdemServicos" transition="dialog-top-transition">
                <UpdateServicoOrdemServicos :servicoOrdemServicos=auxServicoOrdemServicos
                    @close-dialog="dialogUpdateServicoOrdemServicos = false"
                    @alterou-servico="dialogUpdateServicoOrdemServicos = false; getServicos(route.params.id)" />
            </v-dialog>

            <v-dialog v-model="dialogObservacoes" transition="dialog-top-transition">
                <ObservacoesOrdemServicos @close-dialog="dialogObservacoes = false" />
            </v-dialog>

            <v-dialog v-model="dialogObservacoesInternas" transition="dialog-top-transition">
                <ObservacoesInternasOrdemServicos @close-dialog="dialogObservacoesInternas = false" />
            </v-dialog>

            <v-dialog v-model="dialogFormaPagamento" transition="dialog-top-transition" max-width="1024">
                <FormaPagamentoOrdemServicos @close-dialog="dialogFormaPagamento = false"
                    @finalizou-ordem-servicos="finalizouOrdemServicos()" :ordemServicos="ordemServicos" />
            </v-dialog>

            <v-dialog v-model="dialogFinalizaAVista" transition="dialog-top-transition">
                <AVistaOrdemServicos :propOrdemServicos="ordemServicos" operacao = "C"
                    @close-dialog="dialogFinalizaAVista = false">
                </AVistaOrdemServicos>
            </v-dialog>

            <v-dialog v-model="dialogFinalizaAPrazo" transition="dialog-top-transition">
                <APrazoOrdemServicos :propOrdemServicos="ordemServicos" operacao = "C"
                    @close-dialog="dialogFinalizaAPrazo = false">
                </APrazoOrdemServicos>
            </v-dialog>

            <v-dialog v-model="dialogFinalizaCheque" transition="dialog-top-transition">
                <ChequeOrdemServicos :propOrdemServicos="ordemServicos" operacao = "C"
                    @close-dialog="dialogFinalizaCheque = false">
                </ChequeOrdemServicos>
            </v-dialog>

            <v-dialog v-model="dialogFinalizaCartao" transition="dialog-top-transition">
                <CartaoOrdemServicos :propOrdemServicos="ordemServicos" operacao = "C"
                    @close-dialog="dialogFinalizaCartao = false">
                </CartaoOrdemServicos>
            </v-dialog>

            <v-dialog v-model="dialogAdiantamento" transition="dialog-top-transition" width="auto">
                <AdiantamentoOrdemServicos
                    @close-dialog="dialogAdiantamento = false"
                    @alterou-adiantamento="dialogAdiantamento = false; totais(ordemServicos.idOrdemServicos)" />
            </v-dialog>


            <v-row v-show="route.name !== 'OrdensServicos.Create'">
                <v-col cols="12">
                    <h2 class="text-right">Valor Total dos Produtos + Serviços: {{ valorFormat(total) }}</h2>
                    <h2 v-show="adiantamento > 0" class="text-right">Valor Total dos Adiantamentos: {{ valorFormat(adiantamento) }}</h2>
                    <h2 v-show="adiantamento > 0" class="text-right">Valor Total dos Produtos + Serviços - Adiantamentos: {{
                        valorFormat(totalAdiantamento) }}
                    </h2>
                </v-col>
            </v-row>

            <v-row v-show="route.name !== 'OrdensServicos.Create'">
                <v-col cols="6">
                    <h2>{{ situacao }}</h2>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <div align-content="space-between">
                <v-btn class="mr-1 mb-1" size="small" color="primary" variant="elevated" @click="dialogAdiantamento = true; operacaoAdiantamento='E'" 
                    v-show="route.name == 'OrdensServicos.Continuar'">
                    Adiantamento
                </v-btn>

                <v-btn class="mr-1 mb-1" size="small" color="primary" variant="elevated" @click="dialogAdiantamento = true; operacaoAdiantamento='C'"
                    v-show="route.name == 'OrdensServicos.Show'">
                    Adiantamento
                </v-btn>

                <v-btn class="mr-1 mb-1" size="small" color="primary" variant="elevated" @click="dialogObservacoes = true"
                    v-show="route.name !== 'OrdensServicos.Create'">
                    Observações
                </v-btn>

                <v-btn class="mr-1 mb-1" size="small" color="primary" variant="elevated" @click="dialogObservacoesInternas = true"
                    v-show="route.name !== 'OrdensServicos.Create'">
                    Observações Internas
                </v-btn>

                <v-btn class="mr-1 mb-1" size="small" color="primary" variant="elevated" @click="finalizarOrdemServicos()" v-show="route.name == 'OrdensServicos.Continuar' && ordemServicos.formaPagamento == '' &&
                    (produtosOrdemServicos.length > 0 || servicosOrdemServicos.length > 0)">
                    Finalizar Ordem de Serviços
                </v-btn>

                <v-btn class="mr-1 mb-1" size="small" color="primary" variant="elevated" @click="abreInformacoesPagamento"
                    v-show="ordemServicos.formaPagamento !== '' && ordemServicos.situacao !== 'C'">
                    Forma de Pagamento
                </v-btn>

                <v-btn class="mr-1 mb-1" size="small" color="primary" variant="elevated" @click="imprimir()" v-show="route.name == 'OrdensServicos.Continuar' &&
                    ordemServicos.situacao !== 'C'">
                    Imprimir
                </v-btn>
                </div>
                <v-spacer></v-spacer>
                <v-btn class="mr-1 mb-1" color="primary" variant="elevated" :to="{ name: 'OrdensServicos.List' }">
                    Voltar
                </v-btn>
            
        </v-card-actions>
    </v-card>
</template>
