<script setup>
import { ref, onMounted, inject, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ChequeEmitido } from "../../resources/chequeEmitidoResource";
import {Fornecedor} from "../../resources/fornecedorResource";
import {Conta} from "../../resources/contaResource";
import { useField, useForm } from "vee-validate";
import { string, number, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import FornecedorCreate from "../Fornecedores/FornecedorCreate.vue";
import FornecedorBusca from "../Fornecedores/FornecedorBusca.vue";
import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";

const isRealValidation = ref(null);
const validationCodigo = ref(null);
const isReadOnly = ref(false);
const route = useRoute();
const router = useRouter();
const dialogFornecedor = ref(false);
const dialogBuscaFornecedor = ref(false);
let showBtnGravar = ref(true);
const detalhesConta = ref("");
const contas = ref([]);
const situacao = ref("");

const snackBarApp = inject("snackBarApp");

const permissoesStore = usePermissoes();

addMethod(number, "codigo_cheque_emitido_existe", function codigoChequeEmitidosExiste(message) {
    return number().test(
        "codigo-cheque-emitido-existe", // Name
        message, // Msg
        function (codigo) {
            if (isRealValidation.value == null) {
                return true;
            }
            if (!isRealValidation.value) {
                return validationCodigo.value;
            }
            isRealValidation.value = false;
            validationCodigo.value = false;

            return new Promise((resolve) => {
                ChequeEmitido.codigoExiste(codigo, chequeEmitido.value.idChequeEmitido).then((res) => {
                    if (res.data == false) {
                        validationCodigo.value = true;
                        resolve(true);
                    }
                    resolve(false);
                });
            });
        }
    );
});

addMethod(string, "valor_maior_zero", function valorMaiorZero(message) {
    return string().test(
        "valor-maior_zero", // Name
        message, // Msg
        function (valor) {
            if(Converte.stringToFloat(valor) > 0) {
                return true;
            }
            return false;
        }
    );
});


const validationSchema = object({
    chequeEmitido: object({
        idChequeEmitido: undefined,
        codigo: number()
            .min(1)
            .max(999999999)
            .codigo_cheque_emitido_existe("Esse código já está sendo usado por outro cheque emitido, tente outro código.")
            .typeError("O código deve ser um número")
            .label("O código"),
        data: string().required("A data é obrigatória").label("A data"),
        bomPara: string().required("Bom para é obrigatório").label("Bom para"),
        dataBaixa: undefined, 
        idFornecedor: undefined, 
        codigoFornecedor: undefined,
        razaoFornecedor: undefined,
        idConta: number().required("A conta é obrigatória").label("A conta"),
        valor:string().valor_maior_zero("O valor deve ser maior que zero").required("O valor é obrigatório").label("O valor"),
        documento: string().max(10).label("O documento"),
        numero: string().max(10).label("O número"),
        observacoes: string().label("As observações"),
        situacao: undefined,
        modulo: undefined,

    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const chequeEmitido = ref({
    idChequeEmitido: useField("chequeEmitido.idChequeEmitido").value,
    codigo: useField("chequeEmitido.codigo").value,
    data: useField("chequeEmitido.data").value,
    bomPara: useField("chequeEmitido.bomPara").value,
    dataBaixa: useField("chequeEmitido.dataBaixa").value,
    idFornecedor: useField("chequeEmitido.idFornecedor").value,
    codigoFornecedor: useField("chequeEmitido.codigoFornecedor").value,
    razaoFornecedor: useField("chequeEmitido.razaoFornecedor").value,
    idConta: useField("chequeEmitido.idConta").value,
    valor: useField("chequeEmitido.valor").value,
    documento: useField("chequeEmitido.documento").value,
    numero: useField("chequeEmitido.numero").value,
    observacoes: useField("chequeEmitido.observacoes").value,
    situacao: useField("chequeEmitido.situacao").value,
    modulo: useField("chequeEmitido.modulo").value,

});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    situacao.value = "";
    getContas();
    cleanForm();
    isReadOnly.value = false;

    if (route.name == "ChequesEmitidos.Update" || route.name == "ChequesEmitidos.Show" || route.name == "ChequesEmitidos.Baixa") {
        getChequeEmitido(route.params.id);
    } else {
        proximoCodigo();
        document.getElementById("codigo").focus();
        
    }

    if (route.name == "ChequesEmitidos.Show") {
        isReadOnly.value = true;
    }

    if(route.name == 'ChequesEmitidos.Update') {
        document.getElementById("dataBaixa").setAttribute("tabindex", "-1");
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name == "ChequesEmitidos.Create") {
        chequeEmitido.value.idChequeEmitido = null;
        proximoCodigo();
    }
    chequeEmitido.value.data = new Date().toLocaleDateString("pt-BR");
    chequeEmitido.value.bomPara = new Date().toLocaleDateString("pt-BR");
    chequeEmitido.value.dataBaixa = "";
    chequeEmitido.value.idFornecedor = null;
    chequeEmitido.value.codigoFornecedor = "";
    chequeEmitido.value.razaoFornecedor = "";
    chequeEmitido.value.numero = "";
    chequeEmitido.value.valor = "R$ 0,00";
    chequeEmitido.value.documento = "";
    chequeEmitido.value.observacoes = "";
    chequeEmitido.value.situacao  = 'A';
    chequeEmitido.value.modulo = 'CHEEM';

}

async function gravar(values, actions) {
    let dados = new FormData();

    values.chequeEmitido.data = Converte.datePtBrToIso(values.chequeEmitido.data); 
    values.chequeEmitido.bomPara = Converte.datePtBrToIso(values.chequeEmitido.bomPara);

    if(values.chequeEmitido.idFornecedor=="") {
        values.chequeEmitido.idFornecedor = null;
    }

    let dataBaixa = '';

    if(values.chequeEmitido.dataBaixa == '') {
        dataBaixa = null;
    } else{
        dataBaixa = Converte.datePtBrToIso(values.chequeEmitido.dataBaixa); 
    }
    values.chequeEmitido.dataBaixa = dataBaixa;

    dados.append("data", JSON.stringify(values.chequeEmitido));    

    if(route.name == 'ChequesEmitidos.Create') { 
        try {
            const response = await ChequeEmitido.save(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open("O cheque emitido não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                    return;
                }
                snackBarApp.value.open("Novo cheque emitido cadastrado com sucesso.");
                // actions.resetForm();
                cleanForm();
                document.getElementById("codigo").focus();
            }
        } catch (err) {
           snackBarApp.value.open("Erro, mensagem de erro: " + err);
        }
    }

    if(route.name == 'ChequesEmitidos.Update') {
        try {
            const response = await ChequeEmitido.save(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open("O cheque emitido não pode ser alterado, mensagem de erro: " + response.data.message, 6000);
                    return;
                }
                actions.resetForm();
                cleanForm();
                snackBarApp.value.open("Cheque emitido alterado com sucesso.");
                
                router.push({ name: "ChequesEmitidos.List" });

            }
        } catch (err) {
            snackBarApp.value.open("Erro, mensagem de erro: " + err);
        }
    }

    if(route.name == 'ChequesEmitidos.Baixa') {
        try {
            const response = await ChequeEmitido.baixar(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open("O cheque emitido não pode ser baixado, mensagem de erro: " + response.data.message, 6000);
                    return;
                }
                snackBarApp.value.open("Cheque emitido baixado com sucesso.");
                actions.resetForm();
                cleanForm();
                router.push({'name':'ChequesEmitidos.List'});
            }
        } catch (err) {
            snackBarApp.value.open("Erro, mensagem de erro: " + err);
        }
    }
}

async function getContas() {
    const response = await Conta.getContas();
    contas.value = response.data.data;

    if (contas.value.length > 0 && (chequeEmitido.value.idConta == null || chequeEmitido.value.idConta == 0)) {
        chequeEmitido.value.idConta = response.data.data[0].idConta;
    }

    changeConta();
}

async function changeConta() {
    let dados = new FormData();
    dados.append('codigoModulo', '03.10');
    dados.append('idConta', chequeEmitido.value.idConta);
    try {
        const response = await Conta.getById(dados);
        if (response.status == 200) {
            let conta = response.data.data;

            detalhesConta.value = 'Banco:'+conta.nomeBanco+' agência:'+conta.agencia+' nro.conta:'+conta.conta+' saldo atual:'+Converte.numberToCurrency(conta.saldo);

            if(conta == []) {
                detalhesConta.value = "";
            }
        }
    }
    catch (err) {
        console.log(err);
    }
}

async function getChequeEmitido(id) {
    let dados = new FormData();

    if (route.name == "ChequesEmitidos.Update") {
        dados.append("codigoModulo", "03.10.02");
    } else if (route.name == "ChequesEmitidos.Show") {
        dados.append("codigoModulo", "03.10.03");
    } else if (route.name == "ChequesEmitidos.Baixa") {
        dados.append("codigoModulo", "03.10.04");
    } 

    dados.append("idChequeEmitido", id);

    try {
        let response = await ChequeEmitido.getById(dados)
        if (response.status == 200) {

            response.data.data.bomPara = Converte.dateIsoToPtBr(response.data.data.bomPara);
            response.data.data.data = Converte.dateIsoToPtBr(response.data.data.data);
            if(response.data.data.dataBaixa !== null) {
                response.data.data.dataBaixa = Converte.dateIsoToPtBr(response.data.data.dataBaixa);
            }
            response.data.data.valor = Converte.numberToCurrency(response.data.data.valor);

            let sit = response.data.data.situacao;
            if(sit == "A") {
                situacao.value = "Situação do cheque: Em Aberto";
            } else if (sit == 'B')  {
                situacao.value = "Situação do cheque: Baixado";
            } else if (sit == 'C') {
                situacao.value = "Situação do cheque: Cancelado";
            } else if (sit == 'V') {
                situacao.value = "Situação do cheque: Voltou";
            }


            if(route.name == 'ChequesEmitidos.Baixa') {
                response.data.data.dataBaixa = new Date().toLocaleDateString("pt-BR");
            }


            setValues({ chequeEmitido: response.data.data });
        }
    }
    catch (err) {
        console.log(err);
    }
}

async function proximoCodigo() {
    try {
        const response = await ChequeEmitido.proximoCodigo();
        if (response.status == 200) {
            chequeEmitido.value.codigo = response.data;
        }
    } catch (err) {
        console.log(err);
    }
}

async function getFornecedor() {
    chequeEmitido.value.idFornecedor = null;
    chequeEmitido.value.razaoFornecedor = "";

    if (chequeEmitido.value.codigoFornecedor == null || chequeEmitido.value.codigoFornecedor.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", chequeEmitido.value.codigoFornecedor);
    dados.append("getInativos", false);
    dados.append("codigoModulo", "03.10");

    try {
        let response = await Fornecedor.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    chequeEmitido.value.codigoFornecedor = "";
                    document.getElementById("codigoFornecedor").focus();
                    snackBarApp.value.open("Um fornecedor inativo não pode gerar novos lançamentos de cheques emitidos.", 6000);                    
                    return;
                }
                if (response.data.message == "bloqueado") {
                    chequeEmitido.value.codigoFornecedor = "";
                    document.getElementById("codigoFornecedor").focus();
                    snackBarApp.value.open("Um fornecedor bloqueado não pode gerar novos lançamentos de cheques emitidos.", 6000);
                    return;
                }
                chequeEmitido.value.codigoFornecedor = "";
                document.getElementById("codigoFornecedor").focus();
                snackBarApp.value.open("Fornecedor não encontrado, tente outro código ou faça uma busca.", 6000);
                return;
            }
            chequeEmitido.value.idFornecedor = response.data.data.idFornecedor;
            chequeEmitido.value.razaoFornecedor = response.data.data.razao;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouFornecedor(fornecedor) {
    if (fornecedor == null) {
        return;
    }

    chequeEmitido.value.codigoFornecedor = fornecedor.codigo;
    chequeEmitido.value.razaoFornecedor = fornecedor.razao;
    chequeEmitido.value.idFornecedor = fornecedor.idFornecedor;

    getFornecedor();
    dialogBuscaFornecedor.value = false;

    setTimeout(() => {
        document.getElementById("conta").focus();
    }, 100);
}

function cadastrouFornecedor(codigoFornecedor) {
    if (codigoFornecedor == null) {
        return;
    }

    chequeEmitido.value.codigoFornecedor = codigoFornecedor;

    getFornecedor();
}

const keydownHandler = (event) => {
    if(event.key === 'F5') {
        event.preventDefault();
    }

    if(event.key === 'F7') {
        event.preventDefault();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoFornecedor' && document.getElementById('codigoFornecedor').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnBuscaFornecedor').click();
    }

    if (event.key === 'F7' && document.activeElement.id === 'codigoFornecedor' && document.getElementById('codigoFornecedor').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnNovoFornecedor').click();
    }
};

document.addEventListener('keydown', keydownHandler);

onUnmounted(() => {
    document.removeEventListener('keydown', keydownHandler);
});


</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="route.name == 'ChequesEmitidos.Create'">Novo Cheque Emitido</h2>
            <h2 v-show="route.name == 'ChequesEmitidos.Update'">Alteração de Cheque Emitido</h2>
            <h2 v-show="route.name == 'ChequesEmitidos.Baixa'">Baixa de Cheque Emitido</h2>
            <h2 v-show="route.name == 'ChequesEmitidos.Show'">Consulta de um Cheque Emitido</h2>
        </v-card-title>
        <form ref="formChequeEmitido" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <v-text-field
                            label="Código"
                            id="codigo"
                            type="text"
                            v-model="chequeEmitido.codigo"
                            :error-messages="errors['chequeEmitido.codigo']"
                            maxlength="9"
                            @blur="
                                (e) => {
                                    isRealValidation = true;
                                    chequeEmitido.codigo = e.target.value;
                                }
                            "
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="3">
                        <Data label="Data Emissão" id="data" v-model="chequeEmitido.data" 
                            :error-messages="errors['chequeEmitido.data']" :readonly="isReadOnly" 
                        ></Data>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Documento" id="documento" type="text" v-model="chequeEmitido.documento" maxlength="10" :error-messages="errors['chequeEmitido.documento']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor" id="valor" type="text" v-model="chequeEmitido.valor" :error-messages="errors['chequeEmitido.valor']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="3">
                        <v-text-field
                            label="Código do Fornecedor"
                            id="codigoFornecedor"
                            type="text"
                            v-model="chequeEmitido.codigoFornecedor"
                            maxlength="10"
                            :error-messages="errors['chequeEmitido.codigoFornecedor']"
                            @blur="getFornecedor()"
                            :readonly="isReadOnly"
                            v-tooltip="isReadOnly ? 'código do fornecedor' : 'F5-Abre a busca de fornecedores; F7-CAdastra um novo fornecedor.'"
                        />
                    </v-col>
                    <v-col cols="9">
                        <v-text-field
                            label="Razão Social/Nome do Fornecedor"
                            id="razaoFornecedor"
                            type="text"
                            v-model="chequeEmitido.razaoFornecedor"
                            maxlength="50"
                            :error-messages="errors['chequeEmitido.razaoFornecedor']"
                            readonly 
                            tabindex="-1">
                            <template v-slot:append v-if="route.name !=='ChequesEmitidos.Show'">
                                <v-tooltip text="Faz a busca de um fornecedor por nome fantasia/cnpj.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaFornecedor = true" id="btnBuscaFornecedor">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaFornecedor" transition="dialog-top-transition">
                                    <FornecedorBusca @selecionouFornecedor = "selecionouFornecedor" 
                                        @close-dialog="dialogBuscaFornecedor = false" :select-inativo-bloqueado=false />
                                </v-dialog>
                                &nbsp;
                                <v-tooltip text="Cadastra um novo fornecedor.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogFornecedor = true" id="btnNovoFornecedor">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogFornecedor" transition="dialog-top-transition">
                                    <FornecedorCreate @cadastrouFornecedor="cadastrouFornecedor" @close-dialog="dialogFornecedor = false" />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-select v-model="chequeEmitido.idConta" id="conta" :items="contas" 
                            item-title="apelido" 
                            item-value="idConta" 
                            @update:modelValue="changeConta()" 
                            label="Conta" 
                            :readonly="isReadOnly"
                            :error-messages="errors['chequeEmitido.idConta']"
                            no-data-text="Nenhuma conta bancária cadastrada."
                            >
                        </v-select>
                    </v-col>    
                    <v-col cols="9">
                        <v-text-field label="Detalhes da Conta" v-model="detalhesConta" readonly tabindex=-1></v-text-field>
                    </v-col>    
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <Data label="Bom Para" id="bomPara" v-model="chequeEmitido.bomPara" :error-messages="errors['chequeEmitido.bomPara']" 
                            :readonly="isReadOnly || route.name =='ChequesEmitidos.Update'"    />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field label="Número" id="numero" type="text" v-model="chequeEmitido.numero" maxlength="10" :error-messages="errors['chequeEmitido.numero']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="4">
                        <Data label="Data Baixa" id="dataBaixa" v-model="chequeEmitido.dataBaixa" :error-messages="errors['chequeEmitido.dataBaixa']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea label="Observações" id="observacoes" type="text" v-model="chequeEmitido.observacoes" :error-messages="errors['chequeEmitido.observacoes']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>

                {{ situacao }}
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" v-show="$route.name !== 'ChequesEmitidos.Show' && showBtnGravar" variant="elevated" type="submit" id="btnChequesEmitidosGravar">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="$router.go(-1)">
                    Voltar
                    </v-btn>
            </v-card-actions>
        </form>
    </v-card>
    
</template>
