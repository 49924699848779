<script setup>
import { ref, computed, onMounted, inject, onUnmounted } from "vue";
import { ReciboCliente } from "@/resources/reciboClienteResource";
import { Cliente } from "../../resources/clienteResource";
import {Relatorio} from '../../resources/relatoriosResource';
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import { useReciboCliente } from "../../store/reciboCliente";
import ClienteBusca from "../Clientes/ClienteBusca";
import AlterarCodigoReciboCliente from "./AlterarCodigoReciboCliente.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";
import { dateFormat, valorFormat} from "../../services/filtros";


let msgConfirm = ref(null);
let snackBarApp = inject('snackBarApp');

const reciboClienteStore = useReciboCliente();
let lancamento = ref({});
let lancamentos = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(reciboClienteStore.currentPage);
const dataInicial = ref(reciboClienteStore.dataInicial);
const dataFinal = ref(reciboClienteStore.dataFinal);
const idCliente = ref(reciboClienteStore.idCliente);
const codigoCliente = ref(reciboClienteStore.codigoCliente);
const nomeCliente = ref(reciboClienteStore.nomeCliente);
const dialogCliente = ref(false);
const dialogAlterarCodigoReciboCliente = ref(false);

const direction = computed(() => {
    return reciboClienteStore.sort[0].direction;
});

const orderBy = computed(() => {
    return reciboClienteStore.sort[0].property;
});

onMounted(() => {
    filtrar();
});

async function destroy() {
    let dados = new FormData();
    dados.append("data", JSON.stringify(lancamento.value));

    try {
        const response = await ReciboCliente.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Recibo de cliente excluído com sucesso.");
                filtrar();
            }
        }
    } catch (err) {
        console.log(err);
    }
}
function openModalDelete(lanc) {
    lancamento.value = lanc;
    msgConfirm.value
        .open(
            "Confirma Exclusão ?",
            "Confirma a exclusão desse recibo de cliente ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                destroy();
            }
        });
}


async function filtrar(mudouPagina = false) {
    let start = reciboClienteStore.start;
    let limit = reciboClienteStore.limit;
    let sort = JSON.stringify(reciboClienteStore.sort);

    reciboClienteStore.setDataInicial(dataInicial.value);
    reciboClienteStore.setDataFinal(dataFinal.value);
    reciboClienteStore.setIdCliente(idCliente.value);
    reciboClienteStore.setCodigoCliente(codigoCliente.value);
    reciboClienteStore.setNomeCliente(nomeCliente.value);

    let auxDataInicial = null;
    let auxDataFinal = null;

    if(dataInicial.value != '') {
        auxDataInicial = Converte.datePtBrToIso(dataInicial.value); 
    }

    if(dataFinal.value != '') {
        auxDataFinal = Converte.datePtBrToIso(dataFinal.value); 
    }

    if(mudouPagina == false) {
        handleCurrentChange(1);
        return;
    }

    try {
        const response = await ReciboCliente.get(start, limit, sort, idCliente.value, auxDataInicial, auxDataFinal)
        lancamentos.value = response.data.data;
        total.value = parseInt(response.data.total);
    }
    catch(err) {
        console.log(err);
    }
}

function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    reciboClienteStore.setCurrentPage(val);
    reciboClienteStore.setStart(start);
    filtrar(true);
}

function setSort(property) {
    let sort = reciboClienteStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [
        {
            property: property,
            direction: direction,
        },
    ];

    reciboClienteStore.setSort(aSort);
    filtrar();
}

function selecionouCliente(cliente) {
    if (cliente == null) {
        return;
    }

    codigoCliente.value = cliente.codigo;
    
    getCliente();

    dialogCliente.value = false;
}

async function getCliente() {
    idCliente.value = null;
    nomeCliente.value = "";

    if (codigoCliente.value.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", codigoCliente.value);
    dados.append("getInativosBloqueados", true);
    dados.append("codigoModulo", "03.14");
    try {
        let response = await Cliente.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                document.getElementById("codigoCliente").focus();
                snackBarApp.value.open("O cliente não foi encontrado, tente outro código ou faça uma busca.");
                codigoCliente.value = "";
                return;
            }
            idCliente.value = response.data.data.idCliente;
            codigoCliente.value = response.data.data.codigo;
            nomeCliente.value = response.data.data.nome;
        }
    } catch (err) {
        console.log("Erro ao buscar o cliente: " + err);
    }
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

async function imprimir(idReciboCliente) {
    let nomeRel = 'Recibo Cliente';

    let response = await Relatorio.get(nomeRel, idReciboCliente);
    if (response.status == 200) {
        if(response.data.success == true) {
            let nomeArquivo = response.data.nomeArquivo;
            let url = config.baseUrlRel+nomeArquivo;

            window.open(url, '_blank');
            return;

        }
        if(response.data.success == false ) {
            snackBarApp.value.open(response.data.message);
            return;
        }

        snackBarApp.value.open("Não foi possível fazer a impressão do recibo, tente novamente.");
    }
}

document.addEventListener('keydown', handleKeydown);

function handleKeydown(event) {
    if(event.key === 'F5') {
        event.preventDefault();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoCliente' ) {
        event.preventDefault();
        document.getElementById('btnBuscaCliente').click();
    }
}

onUnmounted(() => {
    document.removeEventListener('keydown', handleKeydown);
});

</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-row>
        <v-col>
            <h1>Recibos para Clientes</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.14.01')" color="primary" :to="{ name: 'RecibosClientes.Create' }">Novo Recibo</v-btn>
        </v-col>
    </v-row>
    <v-row >
        <v-col cols="12">
            <v-card elevation="16" density="compact">
                <v-card-title>Filtrar Por</v-card-title>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="2">
                                <Data label="Data Inicial" type="text" v-model="dataInicial"></Data>
                            </v-col>
                            <v-col cols="2">
                                <Data label="Data Final" type="text" v-model="dataFinal"></Data>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field id="codigoCliente" label="Cód.Cliente" type="text" 
                                    v-model="codigoCliente" @blur="getCliente()"
                                    v-tooltip="'F5-Abre a busca de clientes'"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Nome do Cliente" type="text" v-model="nomeCliente" readonly tabindex="-1">
                                    <template v-slot:append>
                                        <v-tooltip text="Faz a busca de um cliente pelo nome.">
                                            <template v-slot:activator="{ props }">
                                                <v-btn v-bind="props" color="primary" @click="dialogCliente = true" id="btnBuscaCliente">
                                                    <v-icon>mdi-magnify</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                        <v-dialog v-model="dialogCliente" transition="dialog-top-transition">
                                            <ClienteBusca 
                                                @selecionouCliente="selecionouCliente" 
                                                @close-dialog="dialogCliente = false"
                                                :select-inativo-bloqueado=true />
                                        </v-dialog>

                                        &nbsp;&nbsp;
                                        <v-btn color="primary" @click.prevent="filtrar('cliente')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    
    <v-card elevation="16" class="mt-3">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                            Código
                            <v-icon v-if="orderBy == 'codigo'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('data')">
                            Data
                            <v-icon v-if="orderBy == 'data'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Descrição</th>
                    <th class="text-right">Valor</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigoCliente')">
                            Cód.Cliente
                            <v-icon v-if="orderBy == 'codigoCliente'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('nomeCliente')">
                            Nome do Cliente
                            <v-icon v-if="orderBy == 'nomeCliente'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in lancamentos" :key="o">
                    <td :class="o.situcao == 'C' ? 'text-red': ''">
                        <v-tooltip text="Exclui o recibo.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-show="temPermissao('03.14.03')" size="small" v-bind="props" variant="text" @click.prevent="openModalDelete(o)" icon="mdi-trash-can">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Consulta o recibo.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-tab-search"
                                    v-show="temPermissao('03.14.02')"
                                    :to="{ name: 'RecibosClientes.Show', params: { id: o.idReciboCliente } }"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Imprime o recibo.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-printer-outline"
                                    v-show="temPermissao('03.14.04')"
                                    @click.prevent="imprimir(o.idReciboCliente)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Altera o código do recibo.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-pencil"
                                    v-show="temPermissao('03.14.04')"
                                    @click.prevent = "dialogAlterarCodigoReciboCliente = true; auxIdReciboCliente = o.idReciboCliente; auxCodigoAntigo = o.codigo"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>

                    </td>
                    <td>{{  o.codigo }}</td>
                    <td>{{ dateFormat(o.data) }}</td>
                    <td>{{ o.descricao }}</td>
                    <td class="text-right">{{ valorFormat(o.valor) }}</td>
                    <td> {{ o.codigoCliente }}</td>
                    <td>{{ o.nomeCliente }}</td>
                </tr>
            </tbody>
        </v-table>

        <v-dialog v-model="dialogAlterarCodigoReciboCliente" transition="dialog-top-transition" width="auto">
            <AlterarCodigoReciboCliente :idReciboCliente="auxIdReciboCliente" :codigoAntigo="auxCodigoAntigo" 
                @close-dialog="dialogAlterarCodigoReciboCliente = false" 
                @alterou-codigo="dialogAlterarCodigoReciboCliente = false;filtrar()" />
        </v-dialog> 

    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage" :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-btn v-show="temPermissao('03.14.01')" color="primary" :to="{ name: 'RecibosClientes.Create' }">Novo Recibo</v-btn>
</template>