<script setup>
    import { ref, computed, onMounted, defineEmits, defineProps } from 'vue';
    import {Fornecedor} from '../../resources/fornecedorResource';
    import config from '../../services/config';
    import { useFornecedor } from '../../store/fornecedor';
        
    const fornecedorStore = useFornecedor();
    let fornecedores = ref({});

    const props = defineProps({
        selectInativoBloqueado: {
            type: Boolean,
            default: true,
        },
    });

    const emit = defineEmits(['selecionouFornecedor', 'close-dialog']);

    const total = ref(0);
    const pageSize = ref(config.maxPerPage);
    const currentPage = ref(fornecedorStore.currentPage);
    const filtro = ref(fornecedorStore.filtro);
    const filtrarPor = ref(fornecedorStore.filtrarPor)
    const itemsFiltrarPor = ref([
        { value: 'codigo', label: 'Código' },
        { value: 'razao', label: 'Razão Social/Nome' },
        { value: 'fantasia', label: 'Nome Fantasia/Apelido' },
        { value: 'cnpj', label: 'CNPJ/CPF' },
    ]);

    const direction = computed(() => {
        return fornecedorStore.sort[0].direction;
    });

    const orderBy = computed(() => {
        return fornecedorStore.sort[0].property;
    });
    
    onMounted(() => {
        search();
    });

    async function search() {
        let start = fornecedorStore.start;
        let limit = fornecedorStore.limit;
        let sort = JSON.stringify(fornecedorStore.sort);
        
        fornecedorStore.setFiltro(filtro.value);
        fornecedorStore.setFiltrarPor(filtrarPor.value);
        try {            
            const response = await Fornecedor.get(start, limit, filtro.value, sort, filtrarPor.value);
            fornecedores.value = response.data.data;
            total.value = parseInt(response.data.total);
        } catch(err) {
            console.log(err);
        }
    }

    function handleCurrentChange(val) {
        let start = ( val - 1) * pageSize.value;
        fornecedorStore.setCurrentPage(val);
        fornecedorStore.setStart(start);
        search();
    }

    function setSort(property) {
        let sort = fornecedorStore.sort;
        let directionAtual = sort[0].direction;
        let direction = '';

        if (property !== sort[0].property) {
            direction = 'ASC';
        }
        else
        {
            if(directionAtual == 'ASC') {
                direction = 'DESC'
            }
            else
            {
                direction = 'ASC'
            }
        }

        let aSort = [{
            'property':property,
            'direction':direction
        }];
        fornecedorStore.setSort(aSort);
        search();
    }

    function selecionar(fornecedor) {
        emit('selecionouFornecedor', fornecedor);
    }

    function closeModalBuscaFornecedores() {
        emit('close-dialog');
    }


</script>

<template>
    <v-card>
        <v-card-title>
            <h3>Busca de Fornecedores</h3>
        </v-card-title>
        <v-card-text>
            <v-card  elevation="16" class="mb-5">
                <v-card-title>
                    Filtro
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols=4>
                                <v-select
                                    v-model="filtrarPor"
                                    :items="itemsFiltrarPor"
                                    item-title="label"
                                    item-value="value"
                                    label="Filtrar Por"
                                ></v-select>
                            </v-col>
                            <v-col cols=8>
                                <v-text-field label="Filtro" type="text" v-model="filtro" prepend-inner-icon="mdi-magnify">
                                    <template v-slot:append>
                                        <v-btn color="primary" @click.prevent="search()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
            
            
            <v-card  elevation="16" class="mb-5">
                <v-card-text>
                    <v-table density="compact">
                        <thead>
                        <tr>
                            <th class="text-center">Seleciona</th>

                            <th>
                                <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                                    Código
                                    <v-icon v-if="orderBy == 'codigo'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                                </a>
                            </th>
                            <th>
                                <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('razao')">
                                    Razão Social/Nome
                                    <v-icon v-if="orderBy == 'razao'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                                </a>
                            </th>
                            <th>
                                <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('fantasia')">
                                    Nome Fantasia/Apelido
                                    <v-icon v-if="orderBy == 'fantasia'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                                </a>
                            </th>
                            <th>CNPJ/CPF</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(o) in fornecedores" :key="o">
                                <td :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red text-center' : 'text-center'">
                                    <v-tooltip text="Seleciona esse fornecedor." v-if="props.selectInativoBloqueado || o.ativoInativoBloqueado == 'A'">
                                        <template v-slot:activator="{ props }">
                                            <v-btn 
                                                v-bind="props"
                                                variant="text" 
                                                icon="mdi-check-bold" 
                                                @click.prevent="selecionar(o)">
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                </td>
                                <td><a :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''"
                                        @click.prevent="(props.selectInativoBloqueado || o.ativoInativoBloqueado == 'A') ? selecionar(o) : ''">
                                        {{ o.codigo }}</a></td>
                                <td><a :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''"
                                        @click.prevent="(props.selectInativoBloqueado || o.ativoInativoBloqueado == 'A') ? selecionar(o) : ''">
                                        {{ o.razao }}</a></td>
                                <td><a :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''"
                                        @click.prevent="(props.selectInativoBloqueado || o.ativoInativoBloqueado == 'A') ? selecionar(o) : ''">
                                        {{ o.fantasia }}</a></td>
                                <td><a :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''"
                                        @click.prevent="(props.selectInativoBloqueado || o.ativoInativoBloqueado == 'A') ? selecionar(o) : ''">
                                        {{ o.cnpj }}</a></td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
            </v-card>

            <div class="block text-right">
                <v-pagination
                    @update:modelValue="handleCurrentChange"        
                    v-model="currentPage"
                    :length="Math.ceil(total / pageSize)"
                    :total-visible="7"
                    :show-first-last-page="true">
                </v-pagination>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="elevated" @click.prevent='closeModalBuscaFornecedores'>Fechar</v-btn>
        </v-card-actions>
    </v-card>
</template>


<!-- <template>
    <div class="container-fluid">
        <h2>Busca de Fornecedores</h2>

        <div class="panel panel-default">
            <div class="panel-body">
                <div class="row">
                    <form class="form-inline">
                    <div class="col-md-10">
                        <div class="form-group">
                            <label for="filtrarPor">Filtrar Por:</label>
                            <select class="form-control" id="filtrarPor" v-model="filtrarPor">
                                <option value="codigo">Código</option>
                                <option value="razao">Razão Social/Nome</option>
                                <option value="fantasia">Nome Fantasia/Apelido</option>
                                <option value="cnpj">CPF/CNPJ</option>
                            </select>
                            <div class="form-group">
                                <label for="filtro">Filtro:</label>
                                <input type="text" class="form-control" id="filtro" placeholder="Filtro" v-model="filtro">
                            </div>
                            <button type="submit" class="btn btn-primary" @click.prevent="search()"> <span class="glyphicon glyphicon-search" aria-hidden="true"/></button>
                        </div>
                    </div>

                    </form>

                </div>

                <p></p>

                <table class="table table-hover table-striped table-bordered">
                    <thead>
                    <tr>
                        <th>
                            <a href="#" @click.prevent="setSort('codigo')">
                                Código
                                <span v-if="orderBy == 'codigo'" :class="[direction == 'ASC' ?
                                    'glyphicon glyphicon-sort-by-attributes' : 'glyphicon glyphicon-sort-by-attributes-alt']"
                                      aria-hidden="true">
                                </span>
                            </a>
                        </th>
                        <th>
                            <a href="#" @click.prevent="setSort('razao')">
                                Razão Social/Nome
                                <span v-if="orderBy == 'razao'" :class="[direction == 'ASC' ?
                                    'glyphicon glyphicon-sort-by-attributes' : 'glyphicon glyphicon-sort-by-attributes-alt']"
                                     aria-hidden="true">
                                </span>
                            </a>
                        </th>
                        <th>
                            <a href="#" @click.prevent="setSort('fantasia')">
                                Nome Fantasia/Apelido
                                <span v-if="orderBy == 'fantasia'" :class="[direction == 'ASC' ?
                                    'glyphicon glyphicon-sort-by-attributes' : 'glyphicon glyphicon-sort-by-attributes-alt']"
                                      aria-hidden="true">
                                </span>
                            </a>
                        </th>
                        <th>
                            <a href="#" @click.prevent="setSort('cnpj')">
                                CPF/CNPJ
                                <span  v-if="orderBy == 'cnpj'" :class="[direction == 'ASC' ?
                                    'glyphicon glyphicon-sort-by-attributes' : 'glyphicon glyphicon-sort-by-attributes-alt']"
                                      aria-hidden="true">
                                </span>
                            </a>
                        </th>
                        <th>Seleciona</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(o) in fornecedores" :key="o">
                        <td><a @click.prevent="selecionar(o)">{{ o.codigo }}</a></td>
                        <td><a @click.prevent="selecionar(o)">{{ o.razao }}</a></td>
                        <td><a @click.prevent="selecionar(o)">{{ o.fantasia }}</a></td>
                        <td><a @click.prevent="selecionar(o)">{{ o.cnpj }}</a></td>
                        <td><a @click.prevent="selecionar(o)"
                               data-toggle="tooltip"
                               name="selecionaFornecedor"
                               id="selecionaFornecedor"
                               data-placement="bottom"
                               title="Seleciona o Fornecedor"
                        ><span class="glyphicon glyphicon-ok" aria-hidden="true"></span></a>
                        </td>
                    </tr>

                    </tbody>
                </table>

                <div class="block text-right">
                    <el-pagination
                            @current-change="handleCurrentChange"
                            :v-model:current-page="currentPage"
                            :page-size="pageSize"
                            layout="prev, pager, next, jumper"
                            :v-model:total="total">
                    </el-pagination>
                </div>

                <p></p>
                <a class="btn btn-primary" href="#" @click.prevent='closeModalBuscaFornecedores'>Fechar</a>

            </div>
        </div>

    </div>
</template>

<script>
    import {Fornecedor} from '../../resources/fornecedorResource';
    import store from '../../store/store';
    import {Pagination} from 'element-plus';
    import config from '../../services/config';

    export default {
        components: {
            'el-pagination': Pagination
        },
        data() {
            return {
                fornecedores:{},
                filtro: store.state.fornecedor.filtro,
                filtrarPor: store.state.fornecedor.filtrarPor,
                total:0,
                pageSize: config.maxPerPage,
                currentPage:store.state.fornecedor.currentPage,
            }
        },
        mounted() {
            this.search();
        },
        computed:{
            direction() {
                return store.state.fornecedor.sort[0].direction;
            },
            orderBy() {
                return store.state.fornecedor.sort[0].property;
            }
        },
        methods:{
            selecionar(fornecedor) {
                this.$emit('onSelecionouFornecedor', fornecedor);
                this.closeModalBuscaFornecedores();
            },
            closeModalBuscaFornecedores() {
               this.$('#modalBuscaFornecedores').modal('hide');
            },

            search() {
                let self = this;
                let start = store.state.fornecedor.start;
                let limit = store.state.fornecedor.limit;
                let sort = JSON.stringify(store.state.fornecedor.sort);
                store.commit('fornecedor/setFiltro', this.filtro);
                store.commit('fornecedor/setFiltrarPor', this.filtrarPor);

                Fornecedor.get(start, limit, this.filtro, sort, this.filtrarPor)
                    .then(function (response) {
                        self.fornecedores = response.data.data;
                        self.total = parseInt(response.data.total);
                    })
                    .catch(function () {

                    });
            },
            handleCurrentChange(val) {
                let start = ( val - 1) * this.pageSize;
                store.commit('fornecedor/setCurrentPage', val);
                store.commit('fornecedor/setStart', start);
                this.search();
            },
            setSort(property) {
                let sort = store.state.fornecedor.sort;
                let directionAtual = sort[0].direction;
                let direction = '';

                if (property !== sort[0].property) {

                    direction = 'ASC';
                }
                else
                {
                    if(directionAtual == 'ASC') {
                        direction = 'DESC'
                    }
                    else
                    {
                        direction = 'ASC'
                    }
                }

                let aSort = [{
                    'property':property,
                    'direction':direction
                }];
                store.commit('fornecedor/setSort', aSort);
                this.search();
            }

        },
    }

</script>
 -->
