<script setup>
import { ref, computed, onMounted, inject, onUnmounted } from "vue";
import { Vendedor } from "@/resources/vendedorResource";
import { AgendamentoVisita } from "@/resources/agendamentoVisitaResource";
import { Cliente } from "@/resources/clienteResource";
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import { useAgendamentoVisita } from "../../store/agendamentoVisita";
import { dateFormat } from "../../services/filtros";
import VendedorBusca from "../Vendedores/VendedorBusca";
import ClienteBusca from "../Clientes/ClienteBusca.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";

let msgConfirm = ref(null);
let snackBarApp = inject("snackBarApp");

let dialogVendedor = ref(false);
let dialogCliente = ref(false);

const agendamentoVisitaStore = useAgendamentoVisita();
let agendamentoVisita = ref({});
let agendamentosVisitas = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(agendamentoVisitaStore.currentPage);
const pendentes = ref(agendamentoVisitaStore.pendentes);
const cancelados = ref(agendamentoVisitaStore.cancelados);
const visitados = ref(agendamentoVisitaStore.visitados);
const codigo = ref(agendamentoVisitaStore.codigo);
const idCliente = ref(agendamentoVisitaStore.idCliente);
const idVendedor = ref(agendamentoVisitaStore.idVendedor);
const dataInicialCliente = ref(agendamentoVisitaStore.dataInicialCliente);
const dataFinalCliente = ref(agendamentoVisitaStore.dataFinalCliente);
const dataInicialVendedor = ref(agendamentoVisitaStore.dataInicialVendedor);
const dataFinalVendedor = ref(agendamentoVisitaStore.dataFinalVendedor);
const codigoCliente = ref(agendamentoVisitaStore.codigoCliente);
const nomeCliente = ref(agendamentoVisitaStore.nomeCliente);
const codigoVendedor = ref(agendamentoVisitaStore.codigoVendedor);
const nomeVendedor = ref(agendamentoVisitaStore.nomeVendedor);

const direction = computed(() => {
    return agendamentoVisitaStore.sort[0].direction;
});

const orderBy = computed(() => {
    return agendamentoVisitaStore.sort[0].property;
});

onMounted(() => {
    filtrar(agendamentoVisitaStore.filtrarPor);
});

async function destroy() {
    let dados = new FormData();
    dados.append("idAgendamentoVisita", JSON.stringify(agendamentoVisita.value.idAgendamentoVisita));

    try {
        const response = await AgendamentoVisita.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Agendamento excluído com sucesso.");
                filtrar();
            }
        }
    } catch (err) {
        console.log(err);
    }
}
function openModalDelete(agendamento) {
    agendamentoVisita.value = agendamento;
    msgConfirm.value
        .open(
            "Confirma Exclusão ?",
            "Confirma a exclusão do agendamento para o cliente: " +
                agendamentoVisita.value.codigoCliente +
                "/" +
                agendamentoVisita.value.nomeCliente +
                ", data:" +
                dateFormat(agendamentoVisita.value.data) +
                ", hora:" +
                agendamentoVisita.value.hora +
                " ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                destroy();
            }
        });
}
async function filtrar(filtrarPor, mudouPagina = false) {
    let start = agendamentoVisitaStore.start;
    let limit = agendamentoVisitaStore.limit;
    let sort = JSON.stringify(agendamentoVisitaStore.sort);

    if (filtrarPor == undefined || filtrarPor == "" || filtrarPor == null) {
        filtrarPor = agendamentoVisitaStore.filtrarPor;
    }

    agendamentoVisitaStore.setIdCliente(idCliente.value);
    agendamentoVisitaStore.setIdVendedor(idVendedor.value);
    agendamentoVisitaStore.setCodigo(codigo.value);
    agendamentoVisitaStore.setCodigoCliente(codigoCliente.value);
    agendamentoVisitaStore.setNomeCliente(nomeCliente.value);
    agendamentoVisitaStore.setCodigoVendedor(codigoVendedor.value);
    agendamentoVisitaStore.setNomeVendedor(nomeVendedor.value);
    agendamentoVisitaStore.setDataInicialCliente(dataInicialCliente.value);
    agendamentoVisitaStore.setDataFinalCliente(dataFinalCliente.value);
    agendamentoVisitaStore.setDataInicialVendedor(dataInicialVendedor.value);
    agendamentoVisitaStore.setDataFinalVendedor(dataFinalVendedor.value);
    agendamentoVisitaStore.setPendentes(pendentes.value);
    agendamentoVisitaStore.setCancelados(cancelados.value);
    agendamentoVisitaStore.setVisitados(visitados.value);
    agendamentoVisitaStore.setFiltrarPor(filtrarPor);

    let dataInicialCli = null;
    let dataFinalCli = null;
    let dataInicialVend = null;
    let dataFinalVend = null;

    if (dataInicialCliente.value != "") {
        dataInicialCli = Converte.datePtBrToIso(dataInicialCliente.value); ///format(parse(dataInicialCliente.value, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
    }

    if (dataFinalCliente.value != "") {
        dataFinalCli = Converte.datePtBrToIso(dataFinalCliente.value); /// format(parse(dataFinalCliente.value, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
    }

    if (dataInicialVendedor.value != "") {
        dataInicialVend = Converte.datePtBrToIso(dataInicialVendedor.value); // format(parse(dataInicialVendedor.value, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
    }

    if (dataFinalVendedor.value != "") {
        dataFinalVend = Converte.datePtBrToIso(dataFinalVendedor.value); //format(parse(dataFinalVendedor.value, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
    }

    let dataInicial = dataInicialCli;
    let dataFinal = dataFinalCli;

    if (filtrarPor == "cliente") {
        dataInicial = dataInicialCli;
        dataFinal = dataFinalCli;
    }
    if (filtrarPor == "vendedor") {
        dataInicial = dataInicialVend;
        dataFinal = dataFinalVend;
    }

    if (mudouPagina == false) {
        handleCurrentChange(1);
        return;
    }

    try {
        const response = await AgendamentoVisita.listar(
            start,
            limit,
            sort,
            filtrarPor,
            dataInicial,
            dataFinal,
            pendentes.value,
            cancelados.value,
            visitados.value,
            idCliente.value,
            idVendedor.value,
            codigo.value
        );
        agendamentosVisitas.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}
function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    agendamentoVisitaStore.setCurrentPage(val);
    agendamentoVisitaStore.setStart(start);
    currentPage.value = val;
    filtrar(null, true);
}
function setSort(property) {
    let sort = agendamentoVisitaStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [
        {
            property: property,
            direction: direction,
        },
    ];

    agendamentoVisitaStore.setSort(aSort);
    filtrar();
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

function selecionouVendedor(vendedor) {
    if (vendedor == null) {
        return;
    }

    codigoVendedor.value = vendedor.codigo;
    document.getElementById("codigoVendedor").focus();
    document.getElementById("codigoVendedor").blur();
    dialogVendedor.value = false;
}

function selecionouCliente(cliente) {
    if (cliente == null) {
        return;
    }

    codigoCliente.value = cliente.codigo;
    document.getElementById("codigoCliente").focus();
    document.getElementById("codigoCliente").blur();
    dialogCliente.value = false;
}

async function getVendedor() {
    idVendedor.value = null;
    nomeVendedor.value = "";

    if (codigoVendedor.value.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", codigoVendedor.value);
    dados.append("getInativosBloqueados", true);
    dados.append("codigoModulo", "01.19");
    try {
        let response = await Vendedor.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                document.getElementById("codigoVendedor").focus();
                snackBarApp.value.open("O vendedor/funcionário não foi encontrado, tente outro código ou faça uma busca.");
                codigoVendedor.value = "";
                return;
            }
            idVendedor.value = response.data.data.idVendedor;
            codigoVendedor.value = response.data.data.codigo;
            nomeVendedor.value = response.data.data.nome;
        }
    } catch (err) {
        console.log("Erro ao buscar vendedor/funcionário: " + err);
    }
}

async function getCliente() {
    idCliente.value = null;
    nomeCliente.value = "";

    if (codigoCliente.value.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", codigoCliente.value);
    dados.append("getInativos", true);
    dados.append("codigoModulo", "01.19");

    try {
        let response = await Cliente.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                document.getElementById("codigoCliente").focus();
                snackBarApp.value.open("O cliente não foi encontrado, tente outro código ou faça uma busca.");
                codigoCliente.value = "";
                return;
            }
            idCliente.value = response.data.data.idCliente;
            codigoCliente.value = response.data.data.codigo;
            nomeCliente.value = response.data.data.nome;
        }
    } catch (err) {
        console.log("Erro ao buscar cliente: " + err);
    }
}

function openModalCancelar(agendamento) {
    agendamentoVisita.value = agendamento;
    msgConfirm.value
        .open(
            "Confirma Cancelamento ?",
            "Confirma o cancelamento do agendamento para o cliente: " +
                agendamentoVisita.value.codigoCliente +
                "/" +
                agendamentoVisita.value.nomeCliente +
                ", data:" +
                dateFormat(agendamentoVisita.value.data) +
                ", hora:" +
                agendamentoVisita.value.hora +
                " ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                cancelar(agendamentoVisita.value.idAgendamentoVisita);
            }
        });
}

async function cancelar(idAgendamentoVisita) {
    let dados = new FormData();
    dados.append("idAgendamentoVisita", idAgendamentoVisita);

    try {
        const response = await AgendamentoVisita.cancelar(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Agendamento de visita cancelado.");
                filtrar();
            } else {
                snackBarApp.value.open("Não foi possível cancelar o agendamento de visita.");
            }
        }
    } catch (err) {
        console.log(err);
    }
}

function openModalVisitado(agendamento) {
    agendamentoVisita.value = agendamento;
    msgConfirm.value
        .open(
            "Confirma a Visitação ?",
            "Confirma a alteração da situação do agendamento para o cliente: " +
                agendamentoVisita.value.codigoCliente +
                "/" +
                agendamentoVisita.value.nomeCliente +
                ", data:" +
                dateFormat(agendamentoVisita.value.data) +
                ", hora:" +
                agendamentoVisita.value.hora +
                " para visitado ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                visitado(agendamentoVisita.value.idAgendamentoVisita);
            }
        });
}

async function visitado(idAgendamentoVisita) {
    let dados = new FormData();
    dados.append("idAgendamentoVisita", idAgendamentoVisita);

    try {
        const response = await AgendamentoVisita.visitado(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Agendamento de visita alterado para visitado com sucesso.");
                filtrar();
            } else {
                snackBarApp.value.open("Não foi possível alterar o agendamento para visitado.");
            }
        }
    } catch (err) {
        console.log(err);
    }
}

document.addEventListener('keydown', handleKeydown);

function handleKeydown(event) {
    if(event.key === 'F5') {
        event.preventDefault();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoCliente' ) {
        event.preventDefault();
        document.getElementById('btnBuscaCliente').click();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoVendedor' ) {
        event.preventDefault();
        document.getElementById('btnBuscaVendedor').click();
    }
}

onUnmounted(() => {
    document.removeEventListener('keydown', handleKeydown);
});

</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-row>
        <v-col>
            <h1>Agendamentos de Visitas</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('01.19.01')" color="primary" :to="{ name: 'AgendamentoVisita.Create' }">Novo Agendamento de Visita</v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-form>
                <v-row fill-height>
                    <v-col cols="12">
                        <v-card elevation="16">
                            <v-card-title color="primary">Mostrar</v-card-title>
                            <v-card-text density="compact">
                                <v-row>
                                    <v-col cols="4">
                                        <v-checkbox v-model="pendentes" label="Pendentes" @change="filtrar(agendamentoVisitaStore.filtrarPor)"></v-checkbox>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-checkbox v-model="cancelados" label="Cancelados" @change="filtrar(agendamentoVisitaStore.filtrarPor)"></v-checkbox>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-checkbox v-model="visitados" label="Visitados" @change="filtrar(agendamentoVisitaStore.filtrarPor)"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="12">
                        <v-card elevation="16">
                            <v-card-title>Filtro por Cliente</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="2">
                                        <Data compact label="Data Inicial" type="text" v-model="dataInicialCliente"></Data>
                                    </v-col>
                                    <v-col cols="2">
                                        <Data label="Data Final" type="text" v-model="dataFinalCliente"></Data>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field id="codigoCliente" label="Cód.Cliente" 
                                            type="text" v-model="codigoCliente" @blur="getCliente()"
                                            v-tooltip="'F5-Abre busca de clientes'"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field label="Nome do Cliente" type="text" v-model="nomeCliente" readonly tabindex="-1">
                                            <template v-slot:append>
                                                <v-tooltip text="Faz a busca de um cliente por nome/cpf.">
                                                    <template v-slot:activator="{ props }">
                                                        <v-btn v-bind="props" color="primary" @click="dialogCliente = true" id="btnBuscaCliente">
                                                            <v-icon>mdi-magnify</v-icon>
                                                        </v-btn>
                                                    </template>
                                                </v-tooltip>
                                                <v-dialog v-model="dialogCliente" transition="dialog-top-transition">
                                                    <ClienteBusca 
                                                        @selecionouCliente="selecionouCliente" 
                                                        @close-dialog="dialogCliente = false"
                                                        :select-inativo-bloqueado=true />
                                                </v-dialog>

                                                &nbsp;&nbsp;
                                                <v-btn color="primary" @click.prevent="filtrar('cliente')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card elevation="16" class="mt-3">
                            <v-card-title>Filtro por Vendedor/Funcionário</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="2">
                                        <Data label="Data Inicial" type="text" v-model="dataInicialVendedor"></Data>
                                    </v-col>
                                    <v-col cols="2">
                                        <Data label="Data Final" type="text" v-model="dataFinalVendedor"></Data>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field id="codigoVendedor" label="Cód.Vendedor" 
                                            type="text" v-model="codigoVendedor" @blur="getVendedor()"
                                            v-tooltip="'F5-Abre busca de vendedores/funcionários'"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field label="Nome do Vendedor" type="text" v-model="nomeVendedor" readonly tabindex="-1">
                                            <template v-slot:append>
                                                <v-tooltip text="Faz a busca de um vendedor por nome.">
                                                    <template v-slot:activator="{ props }">
                                                        <v-btn v-bind="props" color="primary" @click="dialogVendedor = true" id="btnBuscaVendedor">
                                                            <v-icon>mdi-magnify</v-icon>
                                                        </v-btn>
                                                    </template>
                                                </v-tooltip>
                                                <v-dialog v-model="dialogVendedor" transition="dialog-top-transition">
                                                    <VendedorBusca 
                                                        @selecionouVendedor="selecionouVendedor" 
                                                        @close-dialog="dialogVendedor = false"
                                                        :select-inativo-bloqueado=true />
                                                </v-dialog>

                                                &nbsp;&nbsp;
                                                <v-btn color="primary" @click.prevent="filtrar('vendedor')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card elevation="16" class="mb-5">
                            <v-card-title>Busca Rápida</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="5">
                                        <v-text-field label="Código do Agendamento" type="text" v-model="codigo">
                                            <template v-slot:append>
                                                <v-btn color="primary" @click.prevent="filtrar('codigo')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>

    <p></p>
    <v-card elevation="16">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                            Código
                            <v-icon v-if="orderBy == 'codigo'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Situação</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('data')">
                            Data
                            <v-icon v-if="orderBy == 'data'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Hora</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigoCliente')">
                            Cód.Cliente
                            <v-icon
                                v-if="orderBy == 'codigoCliente'"
                                :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"
                            ></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('nomeCliente')">
                            Nome/Razão Social do Cliente
                            <v-icon v-if="orderBy == 'nomeCliente'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigoVendedor')">
                            Cód.Vend./Func.
                            <v-icon
                                v-if="orderBy == 'codigoVendedor'"
                                :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"
                            ></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('nomeVendedor')">
                            Nome do Vendedor/Funcionário
                            <v-icon v-if="orderBy == 'nomeVendedor'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in agendamentosVisitas" :key="o">
                    <td :class="o.situacao == 'C' ? 'text-red' : ''">
                        <v-tooltip text="Altera o agendamento.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-pencil"
                                    v-show="temPermissao('01.19.02') && o.situacao == 'P'"
                                    :to="{ name: 'AgendamentoVisita.Update', params: { id: o.idAgendamentoVisita } }"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Cancela o agendamento.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-cancel"
                                    v-show="temPermissao('01.19.03') && (o.situacao == 'P' || o.situacao == 'A')"
                                    @click.prevent="openModalCancelar(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Altera o agendamento para visitado.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-checkbox-marked-outline"
                                    v-show="temPermissao('01.19.05') && o.situacao == 'P'"
                                    @click.prevent="openModalVisitado(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Consulta o agendamento.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-tab-search"
                                    v-show="temPermissao('01.19.06')"
                                    :to="{ name: 'AgendamentoVisita.Show', params: { id: o.idAgendamentoVisita } }"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui o agendamento.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-show="temPermissao('01.19.04')" size="small" v-bind="props" variant="text" @click.prevent="openModalDelete(o)" icon="mdi-trash-can">
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>

                    <td :class="o.situacao == 'C' ? 'text-red' : ''">{{ o.codigo }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : ''">{{ o.situacaoExtenso }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : ''">{{ dateFormat(o.data) }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : ''">{{ o.hora }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : ''">{{ o.codigoCliente }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : ''">{{ o.nomeCliente }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : ''">{{ o.codigoVendedor }}</td>
                    <td :class="o.situacao == 'C' ? 'text-red' : ''">{{ o.nomeVendedor }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage" :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-btn Primary v-show="temPermissao('01.19.01')" color="primary" :to="{ name: 'AgendamentoVisita.Create' }">Novo Agendamento Visita</v-btn>
</template>
