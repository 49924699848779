<script setup>
import { ref, computed, onMounted, inject, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { ContaReceber } from "@/resources/contaReceberResource";
import { ReciboCliente } from "@/resources/reciboClienteResource";
import { Cliente } from "@/resources/clienteResource";
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import { useContasReceber } from "../../store/contasReceber";
import { valorFormat } from "../../services/filtros";
import ClienteBusca from "../Clientes/ClienteBusca.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";
import ContasReceberRecebimentos from "./ContasReceberRecebimentos.vue";
import { Relatorio } from '../../resources/relatoriosResource';

const router = useRouter();

let msgConfirm = ref(null);
let snackBarApp = inject("snackBarApp");

let dialogCliente = ref(false);
let dialogUltimosRecebimentos = ref(false);

const contasReceberStore = useContasReceber();
let lancamentosContasReceber = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(contasReceberStore.currentPage);
const abertos = ref(contasReceberStore.abertos);
const cancelados = ref(contasReceberStore.cancelados);
const quitados = ref(contasReceberStore.quitados);
const dataInicial = ref(contasReceberStore.dataInicial);
const dataFinal = ref(contasReceberStore.dataFinal);
const idCliente = ref(contasReceberStore.idCliente);
const codigoCliente = ref(contasReceberStore.codigoCliente);
const nomeCliente = ref(contasReceberStore.nomeCliente);
const filtrarPor = ref(contasReceberStore.filtrarPor);
const lancamento = ref(contasReceberStore.lancamento);
const documento = ref(contasReceberStore.documento);
const auxIdLancamento = ref(null);

const itemsFiltrarPor = ref([
    { value: 'dataVencimento', label: 'Data de Vencimento' },
    { value: 'dataEmissao', label: 'Data de Emissão' },
    { value: 'dataUltimoRecebimento', label: 'Data Último Recebimento' }
]);

const direction = computed(() => {
    return contasReceberStore.sort[0].direction;
});

const orderBy = computed(() => {
    return contasReceberStore.sort[0].property;
});

onMounted(() => {
    filtrar('geral');
});

async function destroy(idContaReceber) {
    let dados = new FormData();
    dados.append("idContaReceber", JSON.stringify(idContaReceber));

    try {
        const response = await ContaReceber.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Lançamento de contas a receber excluído com sucesso.");
                filtrar();
            }
        }
    } catch (err) {
        console.log(err);
    }
}

function openModalDelete(lancamento) {
    
    msgConfirm.value
        .open(
            "Confirma Exclusão ?",
            "Confirma a exclusão desse lançamento de contas a receber ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                destroy(lancamento.idContaReceber);
            }
        });
}


async function filtrar(tipoFiltro, mudouPagina = false) {
    let start = contasReceberStore.start;
    let limit = contasReceberStore.limit;
    let filtro = '';
    let sort = JSON.stringify(contasReceberStore.sort);

    if(tipoFiltro == null || tipoFiltro == "" || tipoFiltro == undefined) {
        tipoFiltro = contasReceberStore.tipoFiltro;
    }
    contasReceberStore.setIdCliente(idCliente.value);
    contasReceberStore.setLancamento(lancamento.value);
    contasReceberStore.setDocumento(documento.value);
    contasReceberStore.setCodigoCliente(codigoCliente.value);
    contasReceberStore.setNomeCliente(nomeCliente.value);
    contasReceberStore.setDataInicial(dataInicial.value);
    contasReceberStore.setDataFinal(dataFinal.value);
    contasReceberStore.setAbertos(abertos.value);
    contasReceberStore.setCancelados(cancelados.value);
    contasReceberStore.setQuitados(quitados.value);
    contasReceberStore.setTipoFiltro(tipoFiltro);
    contasReceberStore.setFiltrarPor(filtrarPor.value);
                
    let auxDataInicial = null;
    let auxDataFinal = null;
    if (dataInicial.value != "") {
        auxDataInicial = Converte.datePtBrToIso(dataInicial.value); 
    }

    if (dataFinal.value != "") {
        auxDataFinal = Converte.datePtBrToIso(dataFinal.value); 
    }
    if(tipoFiltro == 'lancamento') {
        filtro = lancamento.value;
    }
    if(tipoFiltro == 'documento') {
        filtro = documento.value;
    }

    if(mudouPagina == false) {
        handleCurrentChange(1);
        return;
    }

    try {
        const response = await ContaReceber.listar(start, limit, sort, filtrarPor.value, auxDataInicial, auxDataFinal, abertos.value, 
        cancelados.value, quitados.value, idCliente.value, filtro, tipoFiltro)
        lancamentosContasReceber.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}
function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    contasReceberStore.setCurrentPage(val);
    contasReceberStore.setStart(start);
    currentPage.value = val;
    filtrar(null, true);
}
function setSort(property) {
    let sort = contasReceberStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [
        {
            property: property,
            direction: direction,
        },
    ];

    contasReceberStore.setSort(aSort);
    filtrar();
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

function openModalExcluirRecebimento(idLancamento, valorPago) {
    if(valorPago == null || valorPago == 0) {
        snackBarApp.value.open("Nenhum recebimento nesse lançamento.");
        return;
    }
    auxIdLancamento.value = idLancamento;
    dialogUltimosRecebimentos.value = true;

}

function selecionouCliente(cliente) {
    if (cliente == null) {
        return;
    }

    codigoCliente.value = cliente.codigo;
    getCliente();
    dialogCliente.value = false;
}


async function getCliente() {
    idCliente.value = null;
    nomeCliente.value = "";

    if (codigoCliente.value.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", codigoCliente.value);
    dados.append("getInativos", true);
    dados.append("codigoModulo", "03.02");

    try {
        let response = await Cliente.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                document.getElementById("codigoCliente").focus();
                snackBarApp.value.open("O cliente não foi encontrado, tente outro código ou faça uma busca.");
                codigoCliente.value = "";
                return;
            }
            idCliente.value = response.data.data.idCliente;
            codigoCliente.value = response.data.data.codigo;
            nomeCliente.value = response.data.data.nome;
        }
    } catch (err) {
        console.log("Erro ao buscar cliente: " + err);
    }
}

function openModalCancelar(lancamento) {
    msgConfirm.value
        .open(
            "Confirma Cancelamento ?",
                "Confirma o cancelamento desse lançamento ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                cancelar(lancamento.idContaReceber);
            }
        });
}

async function cancelar(idContaReceber) {
    let dados = new FormData();
    dados.append("idContaReceber", idContaReceber);

    try {
        const response = await ContaReceber.cancelar(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Lançamento de contas a receber cancelado.");
                filtrar();
            } else {
                snackBarApp.value.open("Não foi possível cancelar o lançamento de contas a receber.");
            }
        }
    } catch (err) {
        console.log(err);
    }
}

function estiloLancamento(situacao, dataVencimento) {
    let estilo = "";
    if (situacao == "C") {
        estilo = "text-red";
    }
    if (situacao == "A") {
        // Tem que fazer essa gambiarra para não deixar o fuso de -3 horas atrapalhar a comparação
        if (new Date(Converte.datePtBrToIso(dataVencimento)) < new Date(Converte.datePtBrToIso(new Date().toLocaleDateString("pt-BR")))) {
            estilo = "text-purple";
        }
        else {
            estilo = "text-green";
        }
    }
    return estilo;
}

document.addEventListener('keydown', handleKeydown);

function handleKeydown(event) {
    if(event.key === 'F5') {
        event.preventDefault();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoCliente' ) {
        event.preventDefault();
        document.getElementById('btnBuscaCliente').click();
    }
}

onUnmounted(() => {
    document.removeEventListener('keydown', handleKeydown);
});

function openModalGerarRecibo(idContaReceber, data, valor, idCliente, descricao) {
    msgConfirm.value
        .open(
            "Gerar Recibo ?",
            "Deseja gerar um recibo para o cliente ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                gerarRecibo(idContaReceber, data, valor, idCliente, descricao);
            }
            else {
                router.push({'name':'ContasReceber.List'});
            }
            
        });
}

async function gerarRecibo(idContaReceber, data, valor, idCliente, descricao) {
    let obj =  {};
    obj.codigo = null;
    obj.idModulo = idContaReceber;
    obj.modulo = 'CRECE';
    obj.data =  Converte.datePtBrToIso(data);
    obj.valor = Converte.numberToCurrency(valor);
    obj.idCliente = idCliente;
    obj.descricao = descricao;
    
    let dados = new FormData();
    dados.append('data', JSON.stringify(obj));

    try {
        const response = await ReciboCliente.save(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O recibo não pode ser gerado, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            const idReciboCliente = response.data.data.idReciboCliente;
            let nomeRel = 'Recibo Cliente';

            let responseRel = await Relatorio.get(nomeRel, idReciboCliente);
            if (responseRel.status == 200) {
                if (responseRel.data.success == true) {
                    let nomeArquivo = responseRel.data.nomeArquivo;
                    let url = config.baseUrlRel + nomeArquivo;

                    window.open(url, '_blank');
                    return;

                }
                if (response.data.success == false) {
                    snackBarApp.value.open(responseRel.data.message);
                    return;
                }

                snackBarApp.value.open("Não foi possível fazer a impressão do recibo, tente novamente.");
            }

        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}


</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>

    <v-row>
        <v-col>
            <h1>Contas a Receber</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.02.01')" color="primary" :to="{ name: 'ContasReceber.Create' }">Novo Lançamento(Uma Parcela)</v-btn>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <v-btn v-show="temPermissao('03.02.02')" color="primary" :to="{ name: 'ContasReceber.CreateVarios' }">Novo Lançamento(Várias Parcelas)</v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="4">
            <v-card elevation="16" density="compact">
                <v-card-title>Filtrar Por</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select density="compact" label="Filtrar Por" v-model="filtrarPor" :items="itemsFiltrarPor"
                                item-title="label" item-value="value"></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="8">
            <v-card elevation="16" density="compact">
                <v-card-title color="primary">Mostrar</v-card-title>
                <v-card-text density="compact">
                    <v-row>
                        <v-col cols="4">
                            <v-checkbox density="compact" v-model="abertos" label="Em Aberto" @change="filtrar()"></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox density="compact" v-model="cancelados" label="Cancelados" @change="filtrar()"></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox density="compact" v-model="quitados" label="Quitados" @change="filtrar()"></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card elevation="16">
                <v-card-title>Filtro</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="2">
                            <Data compact label="Data Inicial" type="text" v-model="dataInicial"></Data>
                        </v-col>
                        <v-col cols="2">
                            <Data label="Data Final" type="text" v-model="dataFinal"></Data>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field id="codigoCliente" label="Cód.Cliente" type="text" 
                                v-model="codigoCliente" @blur="getCliente()"
                                v-tooltip="'F5-Abre a busca de clientes.'"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Nome do Cliente" type="text" v-model="nomeCliente" readonly tabindex="-1">
                                <template v-slot:append>
                                    <v-tooltip text="Faz a busca de um cliente por nome/cpf.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" color="primary" @click="dialogCliente = true" id="btnBuscaCliente">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                    <v-dialog v-model="dialogCliente" transition="dialog-top-transition">
                                        <ClienteBusca 
                                            @selecionouCliente="selecionouCliente" 
                                            @close-dialog="dialogCliente = false" 
                                            :select-inativo-bloqueado=true />
                                    </v-dialog>

                                    &nbsp;&nbsp;
                                    <v-btn color="primary" @click.prevent="filtrar('geral')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-card elevation="16">
                <v-card-title>Busca Rápida</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="5">
                            <v-text-field label="Lançamento" type="text" v-model="lancamento">
                                <template v-slot:append>
                                    <v-btn color="primary" @click.prevent="filtrar('lancamento')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field label="Documento" type="text" v-model="documento">
                                <template v-slot:append>
                                    <v-btn color="primary" @click.prevent="filtrar('documento')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <p></p>
    <v-card elevation="16" class="mt-4">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('lancamento')">
                            Lançamento
                            <v-icon v-if="orderBy == 'lancamento'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Situação</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('dataVencimento')">
                            D.Vencimento
                            <v-icon v-if="orderBy == 'dataVencimento'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('dataEmissao')">
                            D.Emissao
                            <v-icon v-if="orderBy == 'dataEmissao'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('dataUltimoRecebimento')">
                            D.Último Recebimento
                            <v-icon v-if="orderBy == 'dataUltimoRecebimento'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>V.Parcela</th>
                    <th>V.Recebido</th>
                    <th>V.A Receber</th>
                    <th>Documento</th>
                    <th>Cód.Cliente</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('nomeCliente')">
                            Nome do Cliente
                            <v-icon v-if="orderBy == 'nomeCliente'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Histórico</th>  
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in lancamentosContasReceber" :key="o">
                    <td :class="estiloLancamento(o.situacao, o.dataVencimento)">
                        <v-tooltip text="Altera o lançamento de contas a receber.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-pencil"
                                    v-show="temPermissao('03.02.03') && o.situacao !== 'Q' && o.situacao !== 'C'"
                                    :to="{ name: 'ContasReceber.Update', params: { id: o.idContaReceber } }"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Quita o lançamento de contas a receber.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-cash-check"
                                    v-show="temPermissao('03.02.04') && o.situacao == 'A'"
                                    :to="{ name: 'ContasReceber.Quita', params: { id: o.idContaReceber } }"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Cancela o lançamento.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-cancel"
                                    v-show="temPermissao('03.02.06') && (o.situacao == 'Q' || o.situacao == 'A')"
                                    @click.prevent="openModalCancelar(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui o lançamento.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-trash-can"
                                    v-show="temPermissao('03.02.07')"
                                    @click.prevent="openModalDelete(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Consulta o lançamento.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-tab-search"
                                    v-show="temPermissao('03.02.09')"
                                    :to="{ name: 'ContasReceber.Show', params: { id: o.idContaReceber } }"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Faz a exclusão de um recebimento de um lançamento de contas a receber.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-cash-remove"
                                    v-show="temPermissao('03.02.08') && o.valorRecebido > 0"
                                    @click.prevent="openModalExcluirRecebimento(o.idContaReceber, o.valorRecebido)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Gera um recibo para o cliente.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-note-text"
                                    v-show="temPermissao('03.14.05') && o.valorRecebido > 0"
                                    @click.prevent="openModalGerarRecibo(o.idContaReceber, o.dataUltimoRecebimento, o.valorRecebido, o.idCliente, o.historico)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>

                    </td>
                    <td :class="estiloLancamento(o.situacao, o.dataVencimento)">{{ o.lancamento }}</td>
                    <td :class="estiloLancamento(o.situacao, o.dataVencimento)">{{ o.situacaoExtenso }}</td>
                    <td :class="estiloLancamento(o.situacao, o.dataVencimento)">{{ o.dataVencimento }}</td>
                    <td :class="estiloLancamento(o.situacao, o.dataVencimento)">{{ o.dataEmissao }}</td>
                    <td :class="estiloLancamento(o.situacao, o.dataVencimento)">{{ o.dataUltimoRecebimento }}</td>
                    <td :class="estiloLancamento(o.situacao, o.dataVencimento)">{{ valorFormat(o.valor) }}</td>
                    <td :class="estiloLancamento(o.situacao, o.dataVencimento)">{{ valorFormat(o.valorRecebido) }}</td>
                    <td :class="estiloLancamento(o.situacao, o.dataVencimento)">{{ valorFormat(o.valorAReceber)}}</td>
                    <td :class="estiloLancamento(o.situacao, o.dataVencimento)">{{ o.documento }}</td>
                    <td :class="estiloLancamento(o.situacao, o.dataVencimento)">{{ o.codigoCliente }}</td>
                    <td :class="estiloLancamento(o.situacao, o.dataVencimento)">{{ o.nomeCliente }}</td>
                    <td :class="estiloLancamento(o.situacao, o.dataVencimento)">{{ o.historico }}</td>
                </tr>
            </tbody>
        </v-table>
        <v-dialog v-model="dialogUltimosRecebimentos" transition="dialog-top-transition">
            <ContasReceberRecebimentos :idLancamento=auxIdLancamento @close-dialog="dialogUltimosRecebimentos = false" @excluiu-recebimento="filtrar()" />
        </v-dialog>

    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage" :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.02.01')" color="primary" :to="{ name: 'ContasReceber.Create' }">Novo Lançamento(Uma Parcela)</v-btn>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <v-btn v-show="temPermissao('03.02.02')" color="primary" :to="{ name: 'ContasReceber.CreateVarios' }">Novo Lançamento(Várias Parcelas)</v-btn>
        </v-col>
    </v-row>
  </template>