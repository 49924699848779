<script setup>
import { ref, computed, onMounted, defineEmits, defineProps } from 'vue';
import { Servico } from '../../resources/servicoResource';
import config from '../../services/config';
import { useServico } from '../../store/servico';
import { valorFormat } from "../../services/filtros";

const servicoStore = useServico();
let servicos = ref({});

const props = defineProps({
    selectInativoBloqueado: {
        type: Boolean,
        default: true,
    },
});

const emit = defineEmits(['selecionouServico', 'close-dialog']);

const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(servicoStore.currentPage);
const filtro = ref(servicoStore.filtro);
const filtrarPor = ref(servicoStore.filtrarPor)
const itemsFiltrarPor = ref([
    { value: 'codigo', label: 'Código' },
    { value: 'descricao', label: 'Descrição' },
]);

const direction = computed(() => {
    return servicoStore.sort[0].direction;
});

const orderBy = computed(() => {
    return servicoStore.sort[0].property;
});

onMounted(() => {
    search();
});

async function search() {
    let start = servicoStore.start;
    let limit = servicoStore.limit;
    let sort = JSON.stringify(servicoStore.sort);

    servicoStore.setFiltro(filtro.value);
    servicoStore.setFiltrarPor(filtrarPor.value);
    try {
        const response = await Servico.get(start, limit, filtro.value, sort, filtrarPor.value);
        servicos.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}

function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    servicoStore.setCurrentPage(val);
    servicoStore.setStart(start);
    search();
}

function setSort(property) {
    let sort = servicoStore.sort;
    let directionAtual = sort[0].direction;
    let direction = '';

    if (property !== sort[0].property) {
        direction = 'ASC';
    }
    else {
        if (directionAtual == 'ASC') {
            direction = 'DESC'
        }
        else {
            direction = 'ASC'
        }
    }

    let aSort = [{
        'property': property,
        'direction': direction
    }];
    servicoStore.setSort(aSort);
    search();
}

function selecionar(servico) {
    emit('selecionouServico', servico);
}

function closeModalBuscaServicos() {
    emit('close-dialog');
}


</script>

<template>
    <v-card>
        <v-card-title>
            <h3>Busca de Serviços</h3>
        </v-card-title>
        <v-card-text>
            <v-card elevation="16" class="mb-5">
                <v-card-title>
                    Filtro
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols=4>
                                <v-select v-model="filtrarPor" :items="itemsFiltrarPor" item-title="label"
                                    item-value="value" label="Filtrar Por"></v-select>
                            </v-col>
                            <v-col cols=8>
                                <v-text-field label="Filtro" type="text" v-model="filtro"
                                    prepend-inner-icon="mdi-magnify">
                                    <template v-slot:append>
                                        <v-btn color="primary" @click.prevent="search()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                    </template> 
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>


            <v-card elevation="16" class="mb-5">
                <v-card-text>
                    <v-table density="compact">
                        <thead>
                            <tr>
                                <th class="text-center">Seleciona</th>

                                <th>
                                    <a href="#" class="text-decoration-none text-grey-darken-3"
                                        @click.prevent="setSort('codigo')">
                                        Código
                                        <v-icon v-if="orderBy == 'codigo'"
                                            :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                                    </a>
                                </th>
                                <th>
                                    <a href="#" class="text-decoration-none text-grey-darken-3"
                                        @click.prevent="setSort('descricao')">
                                        Descrição do Serviço
                                        <v-icon v-if="orderBy == 'descricao'"
                                            :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                                    </a>
                                </th>
                                <th>Unidade</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(o) in servicos" :key="o">
                                <td
                                    :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red text-center' : 'text-center'">
                                    <v-tooltip v-if="props.selectInativoBloqueado || o.ativoInativoBloqueado == 'A'"
                                        text="Seleciona esse serviço.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" variant="text" icon="mdi-check-bold"
                                                @click.prevent="selecionar(o)">
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                </td>
                                <td><a :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''"
                                        @click.prevent="(props.selectInativoBloqueado || o.ativoInativoBloqueado == 'A') ? selecionar(o) : ''">
                                        {{ o.codigo }}</a></td>
                                <td><a :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''"
                                        @click.prevent="(props.selectInativoBloqueado || o.ativoInativoBloqueado == 'A') ? selecionar(o) : ''">
                                        {{ o.descricao }}</a></td>
                                <td><a :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''"
                                        @click.prevent="(props.selectInativoBloqueado || o.ativoInativoBloqueado == 'A') ? selecionar(o) : ''">
                                        {{ o.unidade }}</a></td>
                                <td><a :class="o.ativoInativoBloqueado == 'I' || o.ativoInativoBloqueado == 'B' ? 'text-red' : ''"
                                        @click.prevent="(props.selectInativoBloqueado || o.ativoInativoBloqueado == 'A') ? selecionar(o) : ''">
                                        {{ valorFormat(o.valor) }}</a></td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
            </v-card>

            <div class="block text-right">
                <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage"
                    :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
                </v-pagination>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="elevated" @click.prevent='closeModalBuscaServicos'>Fechar</v-btn>
        </v-card-actions>
    </v-card>
</template>

<!-- <template>
    <div class="container-fluid">
        <h2>Busca de Serviços</h2>

        <div class="panel panel-default">
            <div class="panel-body">
                <div class="row">
                    <form class="form-inline">
                    <div class="col-md-10">
                        <div class="form-group">
                            <label for="filtrarPor">Filtrar Por:</label>
                            <select class="form-control" id="filtrarPor" v-model="filtrarPor">
                                <option value="codigo">Código</option>
                                <option value="descricao">Descrição</option>
                            </select>
                            <div class="form-group">
                                <label for="filtro">Filtro:</label>
                                <input type="text" class="form-control" id="filtro" placeholder="Filtro" v-model="filtro">
                            </div>
                            <button type="submit" class="btn btn-primary" @click.prevent="search()"> <span class="glyphicon glyphicon-search" aria-hidden="true"/></button>
                        </div>
                    </div>

                    </form>

                </div>

                <p></p>

                <table class="table table-hover table-striped table-bordered">
                    <thead>
                    <tr>
                        <th>
                            <a href="#" @click.prevent="setSort('codigo')">
                                Código
                                <span v-if="orderBy == 'codigo'" :class="[direction == 'ASC' ?
                                    'glyphicon glyphicon-sort-by-attributes' : 'glyphicon glyphicon-sort-by-attributes-alt']"
                                      aria-hidden="true">
                                </span>
                            </a>
                        </th>
                        <th>
                            <a href="#" @click.prevent="setSort('descricao')">
                                Descrição
                                <span v-if="orderBy == 'descricao'" :class="[direction == 'ASC' ?
                                    'glyphicon glyphicon-sort-by-attributes' : 'glyphicon glyphicon-sort-by-attributes-alt']"
                                     aria-hidden="true">
                                </span>
                            </a>
                        </th>
                        <th>Unidade</th>
                        <th>Valor</th>
                        <th>Seleciona</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(o) in servicos" :key="o">
                        <td><a @click.prevent="selecionar(o)">{{ o.codigo }}</a></td>
                        <td><a @click.prevent="selecionar(o)">{{ o.descricao }}</a></td>
                        <td><a @click.prevent="selecionar(o)">{{ o.unidade }}</a></td>
                        <td><a @click.prevent="selecionar(o)">{{ valorFormat(o.valor) }}</a></td>
                        <td><a @click.prevent="selecionar(o)"
                               data-toggle="tooltip"
                               name="selecionaServico"
                               id="selecionaServico"
                               data-placement="bottom"
                               title="Seleciona o Serviço"
                        ><span class="glyphicon glyphicon-ok" aria-hidden="true"></span></a>
                        </td>
                    </tr>

                    </tbody>
                </table>

                <div class="block text-right">
                    <el-pagination
                            @current-change="handleCurrentChange"
                            :v-model:current-page="currentPage"
                            :page-size="pageSize"
                            layout="prev, pager, next, jumper"
                            :v-model:total="total">
                    </el-pagination>
                </div>

                <p></p>
                <a class="btn btn-primary" href="#" @click.prevent='selecionar(null)'>Fechar</a>

            </div>
        </div>

    </div>
</template>

<script>
    import {Servico} from '../../resources/servicoResource';
    import store from '../../store/store';
    import {Pagination} from 'element-plus';
    import config from '../../services/config';

    export default {
        components: {
            'el-pagination': Pagination
        },
        data() {
            return {
                servicos:{},
                filtro: store.state.servico.filtro,
                filtrarPor: store.state.servico.filtrarPor,
                total:0,
                pageSize: config.maxPerPage,
                currentPage:store.state.servico.currentPage,
            }
        },
        mounted() {
            this.search();
        },
        computed:{
            direction() {
                return store.state.servico.sort[0].direction;
            },
            orderBy() {
                return store.state.servico.sort[0].property;
            }
        },
        methods:{
            selecionar(servico) {
                this.$emit('onSelecionouServico', servico);
                this.closeModalBuscaServicos();
            },
            closeModalBuscaServicos() {
               this.$('#modalBuscaServicos').modal('hide');
            },

            search() {
                let self = this;
                let start = store.state.servico.start;
                let limit = store.state.servico.limit;
                let sort = JSON.stringify(store.state.servico.sort);
                store.commit('servico/setFiltro', this.filtro);
                store.commit('servico/setFiltrarPor', this.filtrarPor);

                Servico.get(start, limit, this.filtro, sort, this.filtrarPor)
                    .then(function (response) {
                        self.servicos = response.data.data;
                        self.total = parseInt(response.data.total);
                    })
                    .catch(function () {

                    });
            },
            handleCurrentChange(val) {
                let start = ( val - 1) * this.pageSize;
                store.commit('servico/setCurrentPage', val);
                store.commit('servico/setStart', start);
                this.search();
            },
            setSort(property) {
                let sort = store.state.servico.sort;
                let directionAtual = sort[0].direction;
                let direction = '';

                if (property !== sort[0].property) {

                    direction = 'ASC';
                }
                else
                {
                    if(directionAtual == 'ASC') {
                        direction = 'DESC'
                    }
                    else
                    {
                        direction = 'ASC'
                    }
                }

                let aSort = [{
                    'property':property,
                    'direction':direction
                }];
                store.commit('servico/setSort', aSort);
                this.search();
            }

        },
    }

</script>
 -->
