<script setup>
import { ref, onMounted, inject, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { ContaReceber } from "../../resources/contaReceberResource";
import {Cliente} from "../../resources/clienteResource";
import { GrupoContasReceber } from "../../resources/grupoContasReceberResource";
import { SubgrupoContasReceber } from "../../resources/subgrupoContasReceberResource";
import { TipoDocumento} from "../../resources/tipoDocumentoResource";
import { PermissaoService } from "../../services/permissaoService";
import { useField, useForm } from "vee-validate";
import { string, number, object, addMethod } from "yup";
import { usePermissoes } from "../../store/permissoes";
import { useConfiguracoesContasReceber } from "../../store/configuracoesContasReceber";
import GrupoContasReceberCreate from "../GruposContasReceber/GrupoContasReceberCreate.vue";
import SubgrupoContasReceberCreate from "../SubgruposContasReceber/SubgrupoContasReceberCreate.vue";
import ClienteCreate from "../Clientes/ClienteCreate.vue";
import ClienteBusca from "../Clientes/ClienteBusca.vue";
import TipoDocumentoCreate from "../TiposDocumentos/TipoDocumentoCreate.vue";
import Moeda from "../Utils/moeda.vue";
import Porcentagem from "../Utils/porcentagem.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";
import { valorFormat } from "../../services/filtros";
import AlterarParcela from "./ContasReceberUpdateParcela.vue"

const route = useRoute();
let dialogGrupoContasReceber = ref(false);
let dialogSubgrupoContasReceber = ref(false);
let dialogAlterarParcela = ref(false);
const dialogCliente = ref(false);
const dialogBuscaCliente = ref(false);
const dialogTiposDocumentos = ref(false);
let showBtnGravar = ref(true);
let showBtnGerarParcelas = ref(true);
let primeiraCarga = true;
let labelNroDiasTodoDia = ref('Número de Dias');
let lancamentoAlterar = ref(null);

const snackBarApp = inject("snackBarApp");

const itemsND = ref([
    { text: "Número de Dias", value: "N" },
    { text: "Todo Dia", value: "D" },
]);

addMethod(string, "valor_maior_zero", function valorMaiorZero(message) {
    return string().test(
        "valor-maior-zero", // Name
        message, // Msg
        function (valor) {
            if(Converte.stringToFloat(valor) <= 0) {
                return false;
            }
            return true;
        }
    );
});

addMethod(number, "numero_dias", function numero_dias(message) {
    return number().test(
        "numero_dias", // Name
        message, // Msg
        function (dias) {
            if(lancamento.value.nroDiasTodoDia == "D" && (dias < 1 || dias > 31)) {
                return false;
            }
            return true;
        }
    );
});


const gruposContasReceber = ref([]);
const subgruposContasReceber = ref([]);
const tiposDocumentos = ref([]);
const lancamentos = ref([]);

const permissoesStore = usePermissoes();
const configuracoesContasReceberStore = useConfiguracoesContasReceber();

const validationSchema = object({
    lancamento: object({
        dataEmissao: string().required("A data de emissão é obrigatória").label("A data de emissão"),
        idTipoDocumento: undefined,
        codigoCliente: number().required("O código do cliente é obrigatório").typeError("O código do cliente deve ser um número").label("O código do cliente"),
        nomeCliente: undefined,
        idCliente: undefined,
        historico: string().max(80).label("O histórico"),
        valor: string().valor_maior_zero("O valor total deve ser maior que zero").label("O valor total"),
        multa:undefined,
        juros: undefined,
        documento: string().max(10).label("O documento"),
        nroDiasTodoDia: undefined,
        dias:number().numero_dias("O dia deve ser entre 1 e 31").required("O número de dias/todo dia é obrigatório").typeError("O número de dias/todo dia é obrigatório").label("O número de dias/todo dia"),
        carencia:number().max(99999).typeError("O carência deve ser um número inteiro.").label("A carência"),
        parcelas: number().max(999).min(1).typeError("O número de parcelas deve ser maior ou igual a 1.").required("O número de parcelas é obrigatório").label("O número de parcelas"),
        idVendedor: undefined,
        comissao: undefined,
        idGrupoContasReceber: undefined,
        idSubgrupoContasReceber: undefined,
    }).required(),
});
const { handleSubmit, errors } = useForm({ validationSchema });

const lancamento = ref({
    dataEmissao: useField("lancamento.dataEmissao").value,
    idTipoDocumento: useField("lancamento.idTipoDocumento").value,
    codigoCliente: useField("lancamento.codigoCliente").value,
    nomeCliente: useField("lancamento.nomeCliente").value,
    idCliente: useField("lancamento.idCliente").value,
    historico: useField("lancamento.historico").value,
    valor: useField("lancamento.valor").value,
    multa: useField("lancamento.multa").value,
    juros: useField("lancamento.juros").value,
    documento: useField("lancamento.documento").value,
    nroDiasTodoDia: useField("lancamento.nroDiasTodoDia").value,
    dias: useField("lancamento.dias").value,
    carencia: useField("lancamento.carencia").value,
    parcelas: useField("lancamento.parcelas").value,
    idVendedor: useField("lancamento.idVendedor").value,
    comissao: useField("lancamento.comissao").value,
    idGrupoContasReceber: useField("lancamento.idGrupoContasReceber").value,
    idSubgrupoContasReceber: useField("lancamento.idSubgrupoContasReceber").value,
});

const submit = handleSubmit((values, actions) => {
    gerarParcelas(values, actions);
});

onMounted(() => {

    cleanForm();

    getGruposContasReceber();
    getTiposDocumentos();

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGerarParcelas.value = false;
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    lancamento.value.dataEmissao = new Date().toLocaleDateString("pt-BR");
    lancamento.value.idTipoDocumento = null;
    lancamento.value.codigoCliente = "";
    lancamento.value.nomeCliente = "";
    lancamento.value.idCliente = null;
    lancamento.value.historico = "";
    lancamento.value.valor = "R$ 0,00";
    lancamento.value.documento = "";
    lancamento.value.nroDiasTodoDia = 'N';
    lancamento.value.dias = 30;
    lancamento.value.carencia = 0;
    lancamento.value.parcelas = 1;
    lancamento.value.idVendedor = null;
    lancamento.value.comissao = 0;
    lancamento.value.idGrupoContasReceber = null;
    lancamento.value.idSubgrupoContasReceber = null;
    lancamento.value.lancamentos = null;
    lancamentos.value = null;

    if(configuracoesContasReceberStore.jaCarregado) {
        lancamento.value.multa = Converte.numberToPorcentagem(configuracoesContasReceberStore.multa, 4);
        lancamento.value.juros = Converte.numberToPorcentagem(configuracoesContasReceberStore.jurosDiario, 4);
    }
    else {
        setTimeout(() => {
            lancamento.value.multa = Converte.numberToPorcentagem(configuracoesContasReceberStore.multa, 4);
            lancamento.value.juros = Converte.numberToPorcentagem(configuracoesContasReceberStore.jurosDiario, 4);
        }, 1000); 
    }
}

async function gerarParcelas(values) {
    let dados = new FormData();

    values.lancamento.dataEmissao = Converte.datePtBrToIso(values.lancamento.dataEmissao); 

    dados.append("dados", JSON.stringify(values.lancamento));

    let response = await ContaReceber.gerarParcelas(dados);
    try {
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("Não foi possível gerar as parcelas, mensagem de erro: " + response.data.message, 6000);
                return;
            }

            lancamentos.value = response.data.lancamentos;
        }
    }
    catch (err) {
        console.log(err);
    }
}

async function getGruposContasReceber() {
    let start = 0;
    let limit = 999999;
    let sort = null;
    let primeiroRegistro = { idGrupoContasReceber: null, descricao: "" };

    const response = await GrupoContasReceber.get(start, limit, "", sort);
    if (response.status == 200) {
        gruposContasReceber.value = response.data.data;
        gruposContasReceber.value.unshift(primeiroRegistro);

        if (lancamento.value.idGrupoContasReceber == null) {
            lancamento.value.idGrupoContasReceber = response.data.data[0].idGrupoContasReceber;
            return;
        }
        getSubgruposContasReceber();
    }
}

async function getSubgruposContasReceber() {
    let dados = new FormData();
    dados.append("idGrupoContasReceber", lancamento.value.idGrupoContasReceber);
    subgruposContasReceber.value = [];
    if (primeiraCarga == false) {
        lancamento.value.idSubgrupoContasReceber = null;
    }

    const response = await SubgrupoContasReceber.getSubgrupos(dados);
    if (response.status == 200) {
        let primeiroRegistro = {
            idSubgrupoContasReceber: null,
            descricao: "",
            idGrupoContasReceber: null,
        };
        subgruposContasReceber.value = response.data.data;
        subgruposContasReceber.value.unshift(primeiroRegistro);
        if ((route.name !== "ContasReceber.Update" && route.name !== "ContasReceber.Show") || primeiraCarga == false) {
            lancamento.value.idSubgrupoContasReceber = response.data.data[0].idSubgrupoContasReceber;
        }

        primeiraCarga = false;
    }
}

async function getTiposDocumentos() {
    let start = 0;
    let limit = 999999;
    let sort = null;
    let primeiroRegistro = { idTipoDocumento: null, descricao: "" };

    const response = await TipoDocumento.get(start, limit, "", sort);
    if (response.status == 200) {
        tiposDocumentos.value = response.data.data;
        tiposDocumentos.value.unshift(primeiroRegistro);

        if (lancamento.value.idTipoDocumento == null) {
            lancamento.value.idTipoDocumento = response.data.data[0].idTipoDocumento;
            return;
        }
    }
}


function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}


async function getCliente() {
    
    lancamento.value.idCliente = "";
    lancamento.value.nomeCliente = "";

    if (lancamento.value.codigoCliente.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", lancamento.value.codigoCliente);
    dados.append("getInativos", false);
    dados.append("codigoModulo", "03.02");

    try {
        let response = await Cliente.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    lancamento.value.codigoCliente = "";
                    document.getElementById("codigoCliente").focus();
                    snackBarApp.value.open("Um cliente inativo não pode gerar lançamentos de contas a receber.", 6000);
                    return;
                }
                if (response.data.message == "bloqueado") {
                    lancamento.value.codigoCliente = "";
                    document.getElementById("codigoCliente").focus();
                    snackBarApp.value.open("Um cliente bloqueado não pode gerar lançamentos de contas a receber.", 6000);
                    return;
                }

                lancamento.value.codigoCliente = "";
                document.getElementById("codigoCliente").focus();
                snackBarApp.value.open("Cliente não encontrado, tente outro código ou faça uma busca.", 6000);
                return;
            }
            lancamento.value.idCliente = response.data.data.idCliente;
            lancamento.value.nomeCliente = response.data.data.nome;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouCliente(cliente) {
    if (cliente == null) {
        return;
    }

    lancamento.value.codigoCliente = cliente.codigo;
    lancamento.value.nomeCliente = cliente.nome;
    lancamento.value.idCliente = cliente.idCliente;

    getCliente();
    dialogBuscaCliente.value = false;
}


function cadastrouCliente(codigoCliente) {
    if (codigoCliente == null) {
        return;
    }

    lancamento.value.codigoCliente = codigoCliente;

    getCliente();
}

function changeNroDiasTodoDia() {
    if (lancamento.value.nroDiasTodoDia == "N") {
        labelNroDiasTodoDia.value = "Número de Dias";
    } else {
        labelNroDiasTodoDia.value = "Todo Dia";
    }
}

async function gravar() {
    if(lancamentos.value == null) {
        snackBarApp.value.open("As parcelas não foram geradas, antes de salvar gere as parcelas.", 6000);
        return;
    }
    
    let total = 0;
    lancamentos.value.forEach((item) => {
        
        total = total + parseFloat(item.valorParcela);
    });

    let valor = Converte.stringToFloat(lancamento.value.valor);

    if(total.toFixed(2) !== valor.toFixed(2)) {
        snackBarApp.value.open("A soma total das parcelas é diferente do valor total, corrija o valor das parcelas.", 6000);
        return;
    }

    if(Object.values(errors).length === 0) {
        return;
    }

    let dataEmissao = Converte.datePtBrToIso(lancamento.value.dataEmissao); 
    lancamento.value.lancamentos = lancamentos.value;

    try {

        let dados = new FormData();
        let auxDados = lancamento.value;
        auxDados.dataEmissao = dataEmissao;
        dados.append('dados', JSON.stringify(auxDados));
        

        let response = await ContaReceber.novosLancamentos(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O lançamento de contas a receber não pode ser gravado, mensagem: " + response.data.message, 6000);
                return;
            }

            snackBarApp.value.open("Lançamento de contas a receber gravado com sucesso.", 6000);
            cleanForm();
            document.getElementById('dataEmissao').focus();

            
            if(tiposDocumentos.value !== null) {
                lancamento.value.idTipoDocumento = tiposDocumentos.value[0].idTipoDocumento;
            }
        }
    } catch (err) {
        console.log(err);
    }
}
 
function  openModalAlterarParcela(lanc) {
    lancamentoAlterar.value = Object.assign({}, lanc);
    dialogAlterarParcela.value = true;
}


function alterouLancamento(lanc) {
    if(lanc == null) {
        return;
    }

    lancamentos.value.forEach((item) => {
        if(item.parcela == lanc.parcela) {
            item.documento = lanc.documento;
            item.valorAReceber = lanc.valorAReceber;
            item.desconto = Converte.stringToFloat(lanc.desconto).toString();
            item.acrescimo = Converte.stringToFloat(lanc.acrescimo).toString();
            item.valorParcela = Converte.stringToFloat(lanc.valorParcela).toString();
            item.valorAReceber = Converte.stringToFloat(lanc.valorAReceber).toString();
            item.dataVencimento = lanc.dataVencimento;
        }
    });


    dialogAlterarParcela.value = false;
}


const keydownHandler = (event) => {
    if(event.key === 'F5') {
        event.preventDefault();
    }

    if(event.key === 'F7') {
        event.preventDefault();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoCliente' && document.getElementById('codigoCliente').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnBuscaCliente').click();
    }

    if (event.key === 'F7' && document.activeElement.id === 'codigoCliente' && document.getElementById('codigoCliente').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnNovoCliente').click();
    }

    if (event.key === 'F7' && document.activeElement.id === 'grupoContasReceber' && document.getElementById('grupoContasReceber').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnNovoGrupoContasReceber').click();
    }

    if (event.key === 'F7' && document.activeElement.id === 'subgrupoContasReceber' && document.getElementById('subgrupoContasReceber').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnNovoSubgrupoContasReceber').click();
    }

    if (event.key === 'F7' && document.activeElement.id === 'tipoDocumento' && document.getElementById('tipoDocumento').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnNovoTipoDocumento').click();
    }

};

document.addEventListener('keydown', keydownHandler);

onUnmounted(() => {
    document.removeEventListener('keydown', keydownHandler);
});


</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2>Novos Lançamentos de Contas a Receber</h2>
        </v-card-title>
        <form ref="formContaReceberVarios" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <Data label="Data de Emissão" id="dataEmissao" v-model="lancamento.dataEmissao" 
                            :error-messages="errors['lancamento.dataEmissao']" />
                    </v-col>
                    <v-col cols="9">
                        <v-select
                            :items="tiposDocumentos"
                            item-title="descricao"
                            item-value="idTipoDocumento"
                            label="Tipo de Documento"
                            v-model="lancamento.idTipoDocumento"
                            id="tipoDocumento"
                            v-tooltip="'F7-Cadastra um novo tipo de documento.'"
                        >
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo tipo de documento.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogTiposDocumentos = true"
                                            id="btnNovoTipoDocumento"
                                            v-show="temPermissao('01.04.01') && route.name !== 'ContasReceber.Show'"
                                        >
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogTiposDocumentos" transition="dialog-top-transition">
                                    <TipoDocumentoCreate @cadastrouTipoDocumento="getTiposDocumentos()" @close-dialog="dialogTiposDocumentos = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field
                            label="Código do Cliente"
                            id="codigoCliente"
                            type="text"
                            v-model="lancamento.codigoCliente"
                            maxlength="10"
                            :error-messages="errors['lancamento.codigoCliente']"
                            @blur="getCliente()"
                            v-tooltip="'F5-Abre a busca de clientes; F7-Cadastra um novo cliente.'"
                        />
                    </v-col>
                    <v-col cols="9">
                        <v-text-field
                            label="Nome do Cliente"
                            id="nomeCliente"
                            type="text"
                            v-model="lancamento.nomeCliente"
                            maxlength="50"
                            :error-messages="errors['lancamento.nomeCliente']"
                            readonly 
                            tabindex="-1">
                            <template v-slot:append>
                                <v-tooltip text="Faz a busca de um cliente por nome/cpf.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaCliente = true" id="btnBuscaCliente">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaCliente" transition="dialog-top-transition">
                                    <ClienteBusca 
                                        @selecionouCliente="selecionouCliente" 
                                        @close-dialog="dialogBuscaCliente = false"
                                        :select-inativo-bloqueado=false />
                                </v-dialog>
                                &nbsp;
                                <v-tooltip text="Cadastra um novo cliente.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogCliente = true" id="btnNovoCliente">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogCliente" transition="dialog-top-transition">
                                    <ClienteCreate @cadastrouCliente="cadastrouCliente" @close-dialog="dialogCliente = false" />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="9">
                        <v-text-field
                            label="Histórico"
                            id="historico"
                            type="text"
                            v-model="lancamento.historico"
                            maxlength="80"
                            :error-messages="errors['lancamento.historico']" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda
                            label="Valor Total"
                            id="valor"
                            type="text"
                            v-model="lancamento.valor"
                            :error-messages="errors['lancamento.valor']"/>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4">
                        <Porcentagem
                            label="Multa(%)"
                            id="multa"
                            type="text"
                            tipo="multa"
                            v-model="lancamento.multa"
                            :error-messages="errors['lancamento.multa']"
                        />
                    </v-col>
                    <v-col cols="4">
                        <Porcentagem
                            label="Juros(%)"
                            id="juros"
                            type="text"
                            tipo="juros"
                            v-model="lancamento.juros"
                            :error-messages="errors['lancamentoContaReceber.juros']"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="Documento"
                            id="documento"
                            type="text"
                            v-model="lancamento.documento"
                            maxlength="10"
                            :error-messages="errors['lancamento.documento']"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-select
                            :items="itemsND"
                            item-title="text"
                            item-value="value"
                            label="Nro.Dias/Todo Dia"
                            v-model="lancamento.nroDiasTodoDia"
                        ></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            :label= labelNroDiasTodoDia
                            id="dias"
                            type="text"
                            v-model="lancamento.dias"
                            maxlength="9"
                            :error-messages="errors['lancamento.dias']"
                            :change = "changeNroDiasTodoDia()"
                        />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            label="Carência de Dias 1ª Parcela"
                            id="carencia"
                            type="text"
                            v-model="lancamento.carencia"
                            maxlength="9"
                            :error-messages="errors['lancamento.carencia']"
                        />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            label="Número de Parcelas"
                            id="parcelas"
                            type="text"
                            v-model="lancamento.parcelas"
                            maxlength="9"
                            :error-messages="errors['lancamento.parcelas']"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-select
                            :items="gruposContasReceber"
                            item-title="descricao"
                            item-value="idGrupoContasReceber"
                            label="Grupo de Contas a Receber"
                            id="grupoContasReceber"
                            v-model="lancamento.idGrupoContasReceber"
                            v-tooltip="'F7-Cadastra um novo grupo de contas a receber.'"
                            @update:modelValue="getSubgruposContasReceber()">
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo grupo de contas a receber.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogGrupoContasReceber = true"
                                            id="btnNovoGrupoContasReceber"
                                            v-show="temPermissao('01.17.01')"
                                        >
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogGrupoContasReceber" transition="dialog-top-transition">
                                    <GrupoContasReceberCreate @cadastrouGrupoContasReceber="getGruposContasReceber()" @close-dialog="dialogGrupoContasReceber = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col col="6">
                        <v-select
                            :items="subgruposContasReceber"
                            item-title="descricao"
                            item-value="idSubgrupoContasReceber"
                            label="Subgrupo de Contas a Receber"
                            v-model="lancamento.idSubgrupoContasReceber"
                            v-tooltip="'F7-Cadastra um novo subgrupo de contas a receber.'"
                            id="subgrupoContasReceber"
                            no-data-text="Nenhum subgrupo cadastrado para esse grupo.">
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo subgrupo de contas a receber.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogSubgrupoContasReceber = true"
                                            id="btnNovoSubgrupoContasReceber"
                                            v-show="temPermissao('01.18.01')">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogSubgrupoContasReceber" transition="dialog-top-transition">
                                    <SubgrupoContasReceberCreate @cadastrouSubgrupoContasReceber="getGruposContasReceber();" @close-dialog="dialogSubgrupoContasReceber = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="showBtnGerarParcelas" color="primary" variant="elevated" type="submit" id="btnContasReceberGerarParcelas">Gerar Parcelas</v-btn>
            </v-card-actions>
        </form>

        <v-dialog v-model="dialogAlterarParcela" transition="dialog-top-transition">
            <AlterarParcela :propLancamento="lancamentoAlterar" @close-dialog="dialogAlterarParcela = false"  @alterou-lancamento="alterouLancamento"/>
        </v-dialog>
    </v-card>

    <v-card elevation="16" class="mt-4">
        <v-card-title>
            <h2>Parcelas</h2>
        </v-card-title>
        <v-card-text>
            <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>Data Vencimento</th>
                    <th>Documento</th>
                    <th>Parcela</th>
                    <th>Valor da Parcela</th>
                    <th>Descontos</th>
                    <th>Acréscimos</th>
                    <th>Valor a Receber</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in lancamentos" :key="o">
                    <td>
                        <v-tooltip text="Altera o lançamento de contas a receber.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-pencil"
                                    @click.prevent="openModalAlterarParcela(o)">
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>
                    <td>{{ o.dataVencimento }}</td>
                    <td>{{ o.documento }}</td>
                    <td>{{ o.parcela }}</td>
                    <td>{{ valorFormat(o.valorParcela) }}</td>
                    <td>{{ valorFormat(o.desconto) }}</td>
                    <td>{{ valorFormat(o.acrescimo) }}</td>
                    <td>{{ valorFormat(o.valorAReceber) }}</td>
                </tr>
            </tbody>
        </v-table>
        </v-card-text>
        <v-card-actions>
            <v-btn v-show="showBtnGravar" color="primary" variant="elevated" @click.prevent="gravar()">Salvar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                variant="elevated"
                @click.prevent="$router.go(-1)">
                Voltar
                </v-btn>
        </v-card-actions>
    </v-card>
</template>
