<script setup>
import { ref, inject, defineEmits, onUnmounted } from 'vue';
import { useRelatorio } from "../../store/relatorio";
import { Relatorio } from "../../resources/relatoriosResource";
import { Fornecedor } from "../../resources/fornecedorResource";
import { Converte } from "../../utils/converte";
import config from "../../services/config";
import Data from "../Utils/data.vue";
import FornecedorBusca from "../Fornecedores/FornecedorBusca.vue";

const emit = defineEmits(['close-dialog']);
const snackBarApp = inject('snackBarApp');

const dataInicial = ref(new Date().toLocaleDateString("pt-BR"));
const dataFinal = ref(new Date().toLocaleDateString("pt-BR"));
const idFornecedor = ref(null);
const codigoFornecedor = ref('');
const razaoFornecedor = ref('');

const relatorioStore = useRelatorio();

const dialogFornecedor = ref(false);

async function submit() {
    let nomeRel = relatorioStore.relatorio;

    if (dataInicial.value == '' || dataFinal.value == '') {
        snackBarApp.value.open('Informe as datas inicial e final para a impressão do relatório.');
        return;
    }

    if (idFornecedor.value == null) {
        snackBarApp.value.open('O fornecedor precisa ser escolhido, preencha o campo do código do fornecedor e tente novamente.');
        return;
    }

    let auxDataInicial = Converte.datePtBrToIso(dataInicial.value);
    let auxDataFinal = Converte.datePtBrToIso(dataFinal.value);

    try {
        let response = await Relatorio.get(nomeRel, auxDataInicial, auxDataFinal, idFornecedor.value);
        if (response.status == 200) {
            if (response.data.success == true) {
                let nomeArquivo = response.data.nomeArquivo;
                let url = config.baseUrlRel + nomeArquivo;

                window.open(url, '_blank');
                return;

            }

            if (response.data.success == false) {
                snackBarApp.value.open(response.data.message);
                return;
            }

            snackBarApp.value.open('Não foi possível fazer a impressão do relatório, tente novamente.');
        }
    }
    catch (error) {
        snackBarApp.value.open('Erro ao tentar imprimir o relatório.');
    }
}

function selecionouFornecedor(fornecedor) {
    if (fornecedor == null) {
        return;
    }

    codigoFornecedor.value = fornecedor.codigo;
    getFornecedor();
    dialogFornecedor.value = false;
}

async function getFornecedor() {
    idFornecedor.value = null;
    razaoFornecedor.value = "";
    if (codigoFornecedor.value.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", codigoFornecedor.value);
    dados.append("getInativos", true);
    dados.append("codigoModulo", "04.02");

    try {
        let response = await Fornecedor.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                document.getElementById("codigoFornecedor").focus();
                snackBarApp.value.open("O fornecedor não foi encontrado, tente outro código ou faça uma busca.");
                codigoFornecedor.value = "";
                return;
            }
            idFornecedor.value = response.data.data.idFornecedor;
            codigoFornecedor.value = response.data.data.codigo;
            razaoFornecedor.value = response.data.data.razao;
        }
    } catch (err) {
        console.log("Erro ao buscar o fornecedor: " + err);
    }
}

document.addEventListener('keydown', handleKeydown);

function handleKeydown(event) {
    if(event.key === 'F5') {
        event.preventDefault();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoFornecedor' ) {
        event.preventDefault();
        document.getElementById('btnBuscaFornecedor').click();
    }
}

onUnmounted(() => {
    document.removeEventListener('keydown', handleKeydown);
});



</script>


<template>
    <v-container>
        <v-card elevation="16">
            <v-card-title>
                <h4>Digite as Datas Inicial, Final, Fornecedor e clique em Confirmar</h4>
            </v-card-title>

            <form ref="formTelaDatasFornecedor" @submit.prevent="submit">
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <Data v-model="dataInicial" label="Data Inicial" id="dataInicial" />
                        </v-col>
                        <v-col cols="6">
                            <Data v-model="dataFinal" label="Data Final" id="dataFinal" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-text-field id="codigoFornecedor" label="Cód.Fornecedor" type="text"
                                v-tooltip="'F5-Abre a busca de fornecedores'"
                                v-model="codigoFornecedor" @blur="getFornecedor()"></v-text-field>
                        </v-col>
                        <v-col cols="9">
                            <v-text-field label="Razão Social/Nome do Fornecedor" type="text" v-model="razaoFornecedor"
                                readonly tabindex="-1">
                                <template v-slot:append>
                                    <v-tooltip text="Faz a busca de um fornecedor por nome fantasia/CNPJ.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" color="primary" @click="dialogFornecedor = true" id="btnBuscaFornecedor">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                    <v-dialog v-model="dialogFornecedor" transition="dialog-top-transition">
                                        <FornecedorBusca @selecionouFornecedor="selecionouFornecedor"
                                            @close-dialog="dialogFornecedor = false" :select-inativo-bloqueado=true />
                                    </v-dialog>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" variant="elevated" type="submit"
                        id="btnTelaDatasFornecedorConfirmar">Confirmar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" variant="elevated" @click="emit('close-dialog')">Fechar</v-btn>
                </v-card-actions>
            </form>
        </v-card>
    </v-container>
</template>