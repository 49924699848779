import config from '../services/config';
import { defineStore } from 'pinia';

export const useReciboCliente = defineStore('reciboCliente', {
    state: () => {
        return {
            start:0,
            limit: config.maxPerPage,
            currentPage: 1,
            idCliente: null,
            codigoCliente: '',
            nomeCliente: '',
            dataInicial: new Date().toLocaleDateString("pt-BR"), 
            dataFinal: new Date().toLocaleDateString("pt-BR"),   
            sort: [{
                'property':'data',
                'direction':'ASC'
            }],
        }
    },
    actions : {
        setStart(start) {
            this.start = start;
        },
        setDataInicial(dataInicial) {
            this.dataInicial = dataInicial;
        },
        setDataFinal(dataFinal) {
            this.dataFinal = dataFinal;
        },
        setCurrentPage(currentPage) {
            this.currentPage = currentPage;
        },
        setSort(sort) {
            this.sort = sort;
        },        
        setIdCliente(idCliente) {
            this.idCliente = idCliente;
        },
        setCodigoCliente(codigoCliente) {
            this.codigoCliente = codigoCliente;
        },
        setNomeCliente(nomeCliente) {
            this.nomeCliente = nomeCliente;
        },
    }
});