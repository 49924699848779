import Http from './http';

export class ReciboCliente {

    static get = function(start, limit, sort, idCliente, dataInicial, dataFinal) {
        return Http.get('/Serv/RecibosClientes/RecibosClientes.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'sort':sort,
                'idCliente': idCliente,
                'dataInicial':dataInicial,
                'dataFinal':dataFinal,
            }
        });
    };

    static save = function(dados) {
        return Http.post('/Serv/RecibosClientes/RecibosClientes.php?operacao=gravar', dados);
    };

    static excluir = function(dados) {
        return Http.post('/Serv/RecibosClientes/RecibosClientes.php?operacao=excluir', dados);
    };

    static getById = function(dados) {
        return Http.post('/Serv/RecibosClientes/RecibosClientes.php?operacao=getbyid', dados);
    };

    static proximoCodigo = function() {
        return Http.get('/Serv/RecibosClientes/RecibosClientes.php?operacao=proximocodigo');
    };

    static codigoExiste = function(codigo, idReciboCliente) {
        let dados = new FormData();
        dados.append('codigo', codigo);
        dados.append('idReciboCliente', idReciboCliente);
        return Http.post('/Serv/RecibosClientes/RecibosClientes.php?operacao=codigoexiste', dados);
    };

    static alterarCodigo = function(dados) {
        return Http.post('/Serv/RecibosClientes/RecibosClientes.php?operacao=alterarcodigo', dados);
    };

}

