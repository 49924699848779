<script setup>
    import {Login} from '../resources/loginResource';
    import {ValidadeServico} from '../resources/validadeServicoResource';
    import {PermissaoService} from '../services/permissaoService';
    import TelaDemo from '../components/Telas/TelaDemo.vue'
    import TelaPaga from '../components/Telas/TelaPaga.vue'
    import { useLogado} from "../store/logado";
    import MsgConfirm from "./Utils/MsgConfirm";
    import {useRouter} from 'vue-router';
    import {computed, onMounted, inject, ref} from 'vue';

    const snackBarApp = inject('snackBarApp');
    let msgConfirm = ref(null);
    const logadoStore = useLogado();
    const router = useRouter();
    const dialogTelaDemo = ref(false);
    const dialogTelaPaga = ref(false);

    const logado = computed(() => {
        return logadoStore.logado;
    });

    // function temPermissao(codigoModulo) {
    //     return PermissaoService.temPermissao(codigoModulo);
    // }

    onMounted(() => {
        PermissaoService.setaPermissoes();
        
    });

    function logout() {
        Login.efetuarLogout();
        router.go({'name': 'login'});
    }

   
    function  adquirirRenovar() {
        window.open('https://www.osgold.com.br/planos.php', '_blank');
        return;
    }
     
    
    function historicoAtualizacoes() {
        window.open('https://www.osgold.com.br/historico.php', '_blank');
        return;
    }

    async function validadeServico() {
        try {
            let response = await ValidadeServico.get();
            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open('Não foi possível consultar a data da validade do serviço, tente novamente.');
                    return;
                }
                if (response.data.success == true) {
                    snackBarApp.value.open('O seu período de acesso vence/venceu em: ' +
                        response.data.data.validade + '. O número de acesso(s) simultâneo(s) ' +
                        'contratado é de: ' + response.data.data.numeroUsuarios, 10000);

                    return;
                }
            }
        }
        catch(err) {
            snackBarApp.value.open('Não foi possível consultar a data da validade do serviço, tente novamente.');
            console.log(err);
        }
    }
        
</script>



<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>

    <div v-show="logado">

    <el-menu
        class="el-menu-demo"
        mode="horizontal"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
        @select="handleSelect"
        :router=true
    >
    <el-sub-menu id="mCadastros" index="1">
      <template #title>Cadastros</template>
      <el-menu-item id = "mGruposClientes" index="1-1" route="/GrupoClientes">Grupos de Clientes</el-menu-item>
      <el-menu-item id = "mGruposServicos" index="1-2" route="/GrupoServicos">Grupos de Serviços</el-menu-item>    
      <el-menu-item id = "mSubgruposServicos" index="1-3" route="/SubgrupoServicos">Subgrupos de Serviços</el-menu-item>    
      <el-menu-item id="mServicos" index="1-4" route="/Servico">Serviços</el-menu-item>    
      <el-menu-item id = "mGruposProdutos" index="1-5" route="/GrupoProdutos">Grupos de Produtos</el-menu-item>    
      <el-menu-item id = "mSubgruposProdutos" index="1-6" route="/SubgrupoProdutos">Subgrupos de Produtos</el-menu-item>    
      <el-menu-item id = "mProdutos" index="1-7" route="/Produto">Produtos</el-menu-item>    
      <el-menu-item id="mClientes" index="1-8" route="/Cliente">Clientes</el-menu-item> 
      <el-menu-item id="mTiposDocumentos" index="1-9" route="/TipoDocumento">Tipos de Documentos</el-menu-item> 
      <el-menu-item id = "mGruposFornecedores" index="1-10" route="/GrupoFornecedores">Grupos de Fornecedores</el-menu-item> 
      <el-menu-item id = "mFornecedores" index="1-11" route="/Fornecedor">Fornecedores</el-menu-item> 
      <el-menu-item id = "mVendedores" index="1-12" route="/Vendedor">Vendedores/Funcionários</el-menu-item> 
      <el-menu-item id = "mBancos" index="1-13" route="/Banco">Bancos</el-menu-item> 
      <el-menu-item id = "mContas" index="1-14" route="/Conta">Contas Bancárias</el-menu-item> 
      <el-menu-item id = "mCartoes" index="1-15" route="/Cartao">Cartões/Convênios</el-menu-item> 
      <el-menu-item id = "mGruposContasPagar" index="1-16" route="/GrupoContasPagar">Grupos de Conta a Pagar</el-menu-item>    
      <el-menu-item id = "mSubgruposContasPagar" index="1-17" route="/SubgrupoContasPagar">Subgrupos de Conta a Pagar</el-menu-item>    
      <el-menu-item id = "mGruposContasReceber" index="1-18" route="/GrupoContasReceber">Grupos de Conta a Receber</el-menu-item> 
      <el-menu-item id = "mSubgruposContasReceber" index="1-19" route="/SubgrupoContasReceber">Subgrupos de Conta a Receber</el-menu-item> 
      <el-menu-item id = "mAgendamentosVisitas" index="1-20" route="/AgendamentoVisita">Agendamento de Visitas</el-menu-item> 
    </el-sub-menu>
    <el-sub-menu id="mMovimentacaoEstoque" index="2">
      <template #title>Movimentação de Estoque</template>
        <el-menu-item id = "mEntradaEstoque" index="2-1" route="/EntradaEstoque">Entrada no Estoque</el-menu-item> 
        <el-menu-item id = "mSaidaEstoque" index="2-2" route="/SaidaEstoque">Saída do Estoque</el-menu-item> 
        <el-menu-item id = "mExcluirReverter" index="2-3" route="/ExcluirReverter">Excluir/Reverter uma Movimentação de Estoque</el-menu-item> 
    </el-sub-menu>
    <el-sub-menu id="mFaturamento" index="3">
        <template #title>Faturamento</template>
            <el-menu-item id = "mOrdensServicos" index="3-1" route="/OrdensServicos">Ordens de Serviços</el-menu-item>
            <el-menu-item id = "mOrcamentosOrdensServicos" index="3-2" route="/OrcamentosOrdensServicos">Orçamentos de Ordens de Serviços</el-menu-item>
            <el-menu-item id = "mCaixa" index="3-3" route="/Caixa">Caixa</el-menu-item>    
            <el-menu-item id = "mContasReceber" index="3-4" route="/ContasReceber">Contas a Receber</el-menu-item> 
            <el-menu-item id = "mContasPagar" index="3-5" route="/ContasPagar">Contas a Pagar</el-menu-item> 
            <el-menu-item id = "mComissoes" index="3-6" route="/Comissoes">Comissões de Vendedores/Funcionários</el-menu-item> 
            <el-menu-item id = "mPagamentosVendedores" index="3-7" route="/PagamentosVendedores">Pagamentos a Vendedores/Funcionários</el-menu-item>             
            <el-menu-item id = "mLancamentosContas" index="3-8" route="/LancamentosContas">Lançamentos de Contas Bancárias</el-menu-item>             
            <el-menu-item id = "mChequesEmitidos" index="3-9" route="/ChequesEmitidos">Cheques Emitidos</el-menu-item>             
            <el-menu-item id = "mChequesRecebidos" index="3-10" route="/ChequesRecebidos">Cheques Recebidos</el-menu-item>             
            <el-menu-item id = "mPedidosCompras" index="3-11" route="/PedidosCompras">Pedidos de Compras de Fornecedores</el-menu-item>             
            <el-menu-item id = "mLancamentosCartoes" index="3-12" route="/LancamentosCartoes">Lançamentos de Cartões/Convênios</el-menu-item>             
            <el-menu-item id = "mRecibosClientes" index="3-13" route="/RecibosClientes">Recibos para Clientes</el-menu-item>             

    </el-sub-menu>
    <el-sub-menu id="mRelatorios" index="4">
        <template #title>Relatórios</template>
        <el-menu-item index="4-0" route="/Relatorios">Relatórios</el-menu-item>
    </el-sub-menu>

    <el-sub-menu index="5" id="mUtilitarios">
      <template #title>Utilitários</template>
        <el-menu-item id = "mUsuarios" index="5-1" route="/Usuario">Cadastro de Usuários</el-menu-item>  
        <el-menu-item id = "mCabecalhoRelatorios" index="5-2" route="/ConfigCabecalho">Configurações dos Cabeçalhos dos Relatórios</el-menu-item>  
        <el-menu-item id = "mConfiguracoesCasasDecimais" index="5-2" route="/CasasDecimais">Configurações de Casas Decimais</el-menu-item>
        <el-menu-item id = "mConfiguracoesContasReceber" index="5-3" route="/ConfiguracoesContasReceber">Configurações de Contas a Receber</el-menu-item>
        <el-menu-item id = "mConfiguracoesOrdensServicos" index="5-4" route="/ConfiguracoesOrdensServicos">Configurações de Ordens de Serviços</el-menu-item>
        <el-menu-item id = "mConfiguracoesProdutos" index="5-9" route="/ConfiguracoesProdutos">Configurações de Produtos</el-menu-item>
        <el-menu-item id = "mConsultaLogs" index="5-5" route="/ConsultaLogs">Consulta de Log de Usuários</el-menu-item>
        <el-menu-item id = "mPermissoes" index="5-6" route="/Permissoes">Define as Permissões dos Usuários</el-menu-item>
        <el-menu-item index="5-7" @click="adquirirRenovar()">Adquirir/Renovar Serviço</el-menu-item>
        <el-menu-item index="5-8" @click="historicoAtualizacoes()">Histórico das Atualizações do Sistema</el-menu-item>
        <el-menu-item index="5-8" @click="validadeServico()">Validade do Serviço</el-menu-item>
        <el-menu-item id = "mDadosEmpresa" index="5-9" route="/DadosEmpresa">Dados da Empresa</el-menu-item>
    </el-sub-menu>
    <el-menu-item index="9" @click="logout()" >Logout</el-menu-item>
  </el-menu>

        <!-- <div v-if="false" class="modal fade bs-example-modal-xl" id="modalTelaFree" tabindex="-1" role="dialog"
             aria-labelledby="OS Gold Free">
            <div class="modal-dialog modal-xl " role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <tela-free>
                        </tela-free>
                    </div>
                </div>
            </div>
        </div> -->

        <v-dialog v-model="dialogTelaDemo" transition="dialog-top-transition" max-width="800">
            <TelaDemo @close-dialog="dialogTelaDemo = false" />
        </v-dialog>

        <v-dialog v-model="dialogTelaPaga" transition="dialog-top-transition" max-width="800">
            <TelaPaga @close-dialog="dialogTelaPaga = false" />
        </v-dialog> 





</div>

</template>


