<script setup>
import { ref, onMounted, inject, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { ContaPagar } from "../../resources/contaPagarResource";
import { Fornecedor } from "../../resources/fornecedorResource";
import { GrupoContasPagar } from "../../resources/grupoContasPagarResource";
import { SubgrupoContasPagar } from "../../resources/subgrupoContasPagarResource";
import { TipoDocumento } from "../../resources/tipoDocumentoResource";
import { PermissaoService } from "../../services/permissaoService";
import { useField, useForm } from "vee-validate";
import { string, number, object, addMethod } from "yup";
import { usePermissoes } from "../../store/permissoes";
import GrupoContasPagarCreate from "../GruposContasPagar/GrupoContasPagarCreate.vue";
import SubgrupoContasPagarCreate from "../SubgruposContasPagar/SubgrupoContasPagarCreate.vue";
import FornecedorCreate from "../Fornecedores/FornecedorCreate.vue";
import FornecedorBusca from "../Fornecedores/FornecedorBusca.vue";
import TipoDocumentoCreate from "../TiposDocumentos/TipoDocumentoCreate.vue";
import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";
import { valorFormat } from "../../services/filtros";
import AlterarParcela from "./ContasPagarUpdateParcela.vue"

const route = useRoute();
let dialogGrupoContasPagar = ref(false);
let dialogSubgrupoContasPagar = ref(false);
let dialogAlterarParcela = ref(false);
const dialogFornecedor = ref(false);
const dialogBuscaFornecedor = ref(false);
const dialogTiposDocumentos = ref(false);
let showBtnGravar = ref(true);
let showBtnGerarParcelas = ref(true);
let primeiraCarga = true;
let labelNroDiasTodoDia = ref('Número de Dias');
let lancamentoAlterar = ref(null);

const snackBarApp = inject("snackBarApp");

const itemsND = ref([
    { text: "Número de Dias", value: "N" },
    { text: "Todo Dia", value: "D" },
]);

addMethod(string, "valor_maior_zero", function valorMaiorZero(message) {
    return string().test(
        "valor-maior-zero", // Name
        message, // Msg
        function (valor) {
            if (Converte.stringToFloat(valor) <= 0) {
                return false;
            }
            return true;
        }
    );
});

addMethod(number, "numero_dias", function numero_dias(message) {
    return number().test(
        "numero_dias", // Name
        message, // Msg
        function (dias) {
            if (lancamento.value.nroDiasTodoDia == "D" && (dias < 1 || dias > 31)) {
                return false;
            }
            return true;
        }
    );
});


const gruposContasPagar = ref([]);
const subgruposContasPagar = ref([]);
const tiposDocumentos = ref([]);
const lancamentos = ref([]);

const permissoesStore = usePermissoes();

const validationSchema = object({
    lancamento: object({
        dataEmissao: string().required("A data de emissão é obrigatória").label("A data de emissão"),
        idTipoDocumento: undefined,
        codigoFornecedor: number().required("O código do fornecedor é obrigatório").typeError("O código do fornecedor deve ser um número").label("O código do fornecedor"),
        razaoFornecedor: undefined,
        idFornecedor: undefined,
        historico: string().max(80).label("O histórico"),
        valor: string().valor_maior_zero("O valor total deve ser maior que zero").label("O valor total"),
        documento: string().max(10).label("O documento"),
        nroDiasTodoDia: undefined,
        dias: number().numero_dias("O dia deve ser entre 1 e 31").required("O número de dias/todo dia é obrigatório").typeError("O número de dias/todo dia é obrigatório").label("O número de dias/todo dia"),
        carencia: number().max(99999).typeError("O carência deve ser um número inteiro.").label("A carência"),
        parcelas: number().max(999).min(1).typeError("O número de parcelas deve ser maior ou igual a 1.").required("O número de parcelas é obrigatório").label("O número de parcelas"),
        idGrupoContasPagar: undefined,
        idSubgrupoContasPagar: undefined,
    }).required(),
});
const { handleSubmit, errors } = useForm({ validationSchema });

const lancamento = ref({
    dataEmissao: useField("lancamento.dataEmissao").value,
    idTipoDocumento: useField("lancamento.idTipoDocumento").value,
    codigoFornecedor: useField("lancamento.codigoFornecedor").value,
    razaoFornecedor: useField("lancamento.razaoFornecedor").value,
    idFornecedor: useField("lancamento.idFornecedor").value,
    historico: useField("lancamento.historico").value,
    valor: useField("lancamento.valor").value,
    documento: useField("lancamento.documento").value,
    nroDiasTodoDia: useField("lancamento.nroDiasTodoDia").value,
    dias: useField("lancamento.dias").value,
    carencia: useField("lancamento.carencia").value,
    parcelas: useField("lancamento.parcelas").value,
    idGrupoContasPagar: useField("lancamento.idGrupoContasPagar").value,
    idSubgrupoContasPagar: useField("lancamento.idSubgrupoContasPagar").value,
});

const submit = handleSubmit((values, actions) => {
    gerarParcelas(values, actions);
});

onMounted(() => {

    cleanForm();

    getGruposContasPagar();
    getTiposDocumentos();

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGerarParcelas.value = false;
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    lancamento.value.dataEmissao = new Date().toLocaleDateString("pt-BR");
    lancamento.value.idTipoDocumento = null;
    lancamento.value.codigoFornecedor = "";
    lancamento.value.razaoFornecedor = "";
    lancamento.value.idFornecedor = null;
    lancamento.value.historico = "";
    lancamento.value.valor = "R$ 0,00";
    lancamento.value.documento = "";
    lancamento.value.nroDiasTodoDia = 'N';
    lancamento.value.dias = 30;
    lancamento.value.carencia = 0;
    lancamento.value.parcelas = 1;
    lancamento.value.idGrupoContasPagar = null;
    lancamento.value.idSubgrupoContasPagar = null;
    lancamento.value.lancamentos = null;
    lancamentos.value = null;
}

async function gerarParcelas(values) {
    let dados = new FormData();

    values.lancamento.dataEmissao = Converte.datePtBrToIso(values.lancamento.dataEmissao);

    dados.append("dados", JSON.stringify(values.lancamento));

    let response = await ContaPagar.gerarParcelas(dados);
    try {
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("Não foi possível gerar as parcelas, mensagem de erro: " + response.data.message, 6000);
                return;
            }

            lancamentos.value = response.data.lancamentos;
        }
    }
    catch (err) {
        console.log(err);
    }
}

async function getGruposContasPagar() {
    let start = 0;
    let limit = 999999;
    let sort = null;
    let primeiroRegistro = { idGrupoContasPagar: null, descricao: "" };

    const response = await GrupoContasPagar.get(start, limit, "", sort);
    if (response.status == 200) {
        gruposContasPagar.value = response.data.data;
        gruposContasPagar.value.unshift(primeiroRegistro);

        if (lancamento.value.idGrupoContasPagar == null) {
            lancamento.value.idGrupoContasPagar = response.data.data[0].idGrupoContasPagar;
            return;
        }
        getSubgruposContasPagar();
    }
}

async function getSubgruposContasPagar() {
    let dados = new FormData();
    dados.append("idGrupoContasPagar", lancamento.value.idGrupoContasPagar);
    subgruposContasPagar.value = [];
    if (primeiraCarga == false) {
        lancamento.value.idSubgrupoContasPagar = null;
    }

    const response = await SubgrupoContasPagar.getSubgrupos(dados);
    if (response.status == 200) {
        let primeiroRegistro = {
            idSubgrupoContasPagar: null,
            descricao: "",
            idGrupoContasPagar: null,
        };
        subgruposContasPagar.value = response.data.data;
        subgruposContasPagar.value.unshift(primeiroRegistro);
        if ((route.name !== "ContasPagar.Update" && route.name !== "ContasPagar.Show") || primeiraCarga == false) {
            lancamento.value.idSubgrupoContasPagar = response.data.data[0].idSubgrupoContasPagar;
        }

        primeiraCarga = false;
    }
}

async function getTiposDocumentos() {
    let start = 0;
    let limit = 999999;
    let sort = null;
    let primeiroRegistro = { idTipoDocumento: null, descricao: "" };

    const response = await TipoDocumento.get(start, limit, "", sort);
    if (response.status == 200) {
        tiposDocumentos.value = response.data.data;
        tiposDocumentos.value.unshift(primeiroRegistro);

        if (lancamento.value.idTipoDocumento == null) {
            lancamento.value.idTipoDocumento = response.data.data[0].idTipoDocumento;
            return;
        }
    }
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

async function getFornecedor() {
    lancamento.value.idFornecedor = "";
    lancamento.value.razaoFornecedor = "";

    if (lancamento.value.codigoFornecedor.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", lancamento.value.codigoFornecedor);
    dados.append("getInativos", false);
    dados.append("codigoModulo", "03.04");

    try {
        let response = await Fornecedor.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    lancamento.value.codigoFornecedor = "";
                    document.getElementById("codigoFornecedor").focus();
                    snackBarApp.value.open("Um fornecedor inativo não pode gerar lançamentos de contas a pagar.", 6000);
                    return;
                }
                if (response.data.message == "bloqueado") {
                    lancamento.value.codigoFornecedor = "";
                    document.getElementById("codigoFornecedor").focus();
                    snackBarApp.value.open("Um fornecedor bloqueado não pode gerar lançamentos de contas a pagar.", 6000);
                    return;
                }

                lancamento.value.codigoFornecedor = "";
                document.getElementById("codigoFornecedor").focus();
                snackBarApp.value.open("Fornecedor não encontrado, tente outro código ou faça uma busca.", 6000);
                return;
            }
            lancamento.value.idFornecedor = response.data.data.idFornecedor;
            lancamento.value.razaoFornecedor = response.data.data.razao;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouFornecedor(fornecedor) {
    if (fornecedor == null) {
        return;
    }

    lancamento.value.codigoFornecedor = fornecedor.codigo;
    lancamento.value.razaoFornecedor = fornecedor.razao;
    lancamento.value.idFornecedor = fornecedor.idFornecedor;

    getFornecedor();
    dialogBuscaFornecedor.value = false;
}


function cadastrouFornecedor(codigoFornecedor) {
    if (codigoFornecedor == null) {
        return;
    }

    lancamento.value.codigoFornecedor = codigoFornecedor;

    getFornecedor();
}

function changeNroDiasTodoDia() {
    if (lancamento.value.nroDiasTodoDia == "N") {
        labelNroDiasTodoDia.value = "Número de Dias";
    } else {
        labelNroDiasTodoDia.value = "Todo Dia";
    }
}

async function gravar() {
    if (lancamentos.value == null) {
        snackBarApp.value.open("As parcelas não foram geradas, antes de salvar gere as parcelas.", 6000);
        return;
    }

    let total = 0;
    lancamentos.value.forEach((item) => {

        total = total + parseFloat(item.valorParcela);
    });

    let valor = Converte.stringToFloat(lancamento.value.valor);

    if (total.toFixed(2) !== valor.toFixed(2)) {
        snackBarApp.value.open("A soma total das parcelas é diferente do valor total, corrija o valor das parcelas.", 6000);
        return;
    }

    if (Object.values(errors).length === 0) {
        return;
    }

    let dataEmissao = Converte.datePtBrToIso(lancamento.value.dataEmissao);
    lancamento.value.lancamentos = lancamentos.value;

    try {

        let dados = new FormData();
        let auxDados = lancamento.value;
        auxDados.dataEmissao = dataEmissao;
        dados.append('dados', JSON.stringify(auxDados));


        let response = await ContaPagar.novosLancamentos(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O lançamento de contas a pagar não pode ser gravado, mensagem: " + response.data.message, 6000);
                return;
            }

            snackBarApp.value.open("Lançamento de contas a pagar gravado com sucesso.", 6000);
            cleanForm();
            document.getElementById('dataEmissao').focus();


            if (tiposDocumentos.value !== null) {
                lancamento.value.idTipoDocumento = tiposDocumentos.value[0].idTipoDocumento;
            }
        }
    } catch (err) {
        console.log(err);
    }
}

function openModalAlterarParcela(lanc) {
    lancamentoAlterar.value = Object.assign({}, lanc);
    dialogAlterarParcela.value = true;
}


function alterouLancamento(lanc) {
    if (lanc == null) {
        return;
    }

    lancamentos.value.forEach((item) => {
        if (item.parcela == lanc.parcela) {
            item.documento = lanc.documento;
            item.valorAPagar = lanc.valorAPagar;
            item.desconto = Converte.stringToFloat(lanc.desconto).toString();
            item.acrescimo = Converte.stringToFloat(lanc.acrescimo).toString();
            item.valorParcela = Converte.stringToFloat(lanc.valorParcela).toString();
            item.valorAPagar = Converte.stringToFloat(lanc.valorAPagar).toString();
            item.dataVencimento = lanc.dataVencimento;
        }
    });

    dialogAlterarParcela.value = false;
}

const keydownHandler = (event) => {
    if(event.key === 'F5') {
        event.preventDefault();
    }

    if(event.key === 'F7') {
        event.preventDefault();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoFornecedor' && document.getElementById('codigoFornecedor').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnBuscaFornecedor').click();
    }

    if (event.key === 'F7' && document.activeElement.id === 'codigoFornecedor' && document.getElementById('codigoFornecedor').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnNovoFornecedor').click();
    }

    if (event.key === 'F7' && document.activeElement.id === 'tipoDocumento' && document.getElementById('tipoDocumento').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnNovoTipoDocumento').click();
    }

    if (event.key === 'F7' && document.activeElement.id === 'grupoContasPagar' && document.getElementById('grupoContasPagar').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnNovoGrupoContasPagar').click();
    }

    if (event.key === 'F7' && document.activeElement.id === 'subgrupoContasPagar' && document.getElementById('subgrupoContasPagar').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnNovoSubgrupoContasPagar').click();
    }

};

document.addEventListener('keydown', keydownHandler);

onUnmounted(() => {
    document.removeEventListener('keydown', keydownHandler);
});


</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2>Novos Lançamentos de Contas a Pagar</h2>
        </v-card-title>
        <form ref="formContaPagarVarios" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <Data label="Data de Emissão" id="dataEmissao" v-model="lancamento.dataEmissao"
                            :error-messages="errors['lancamento.dataEmissao']" />
                    </v-col>
                    <v-col cols="9">
                        <v-select :items="tiposDocumentos" item-title="descricao" item-value="idTipoDocumento"
                            label="Tipo de Documento" v-model="lancamento.idTipoDocumento"
                            id="tipoDocumento"
                            v-tooltip="'F7-Cadastra um novo tipo de documento'">
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo tipo de documento.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogTiposDocumentos = true"
                                            id="btnNovoTipoDocumento"
                                            v-show="temPermissao('01.04.01') && route.name !== 'ContasReceber.Show'">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogTiposDocumentos" transition="dialog-top-transition">
                                    <TipoDocumentoCreate @cadastrouTipoDocumento="getTiposDocumentos()"
                                        @close-dialog="dialogTiposDocumentos = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Código do Fornecedor" id="codigoFornecedor" type="text"
                            v-model="lancamento.codigoFornecedor" maxlength="10"
                            v-tooltip="'F5-Abre a busca de fornecedores; F7-Cadastra um novo fornecedor'"
                            :error-messages="errors['lancamento.codigoFornecedor']" @blur="getFornecedor()" />
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="Razão Social/Nome do Fornecedor" id="razaoFornecedor" type="text"
                            v-model="lancamento.razaoFornecedor" maxlength="50"
                            :error-messages="errors['lancamento.razaoFornecedor']" readonly tabindex="-1">
                            <template v-slot:append>
                                <v-tooltip text="Faz a busca de um fornecedor por razão/cnpj.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaFornecedor = true"
                                            id="btnBuscaFornecedor">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaFornecedor" transition="dialog-top-transition">
                                    <FornecedorBusca @selecionouFornecedor="selecionouFornecedor"
                                        @close-dialog="dialogBuscaFornecedor = false" :select-inativo-bloqueado=false />
                                </v-dialog>
                                &nbsp;
                                <v-tooltip text="Cadastra um novo fornecedor.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogFornecedor = true"
                                            id="btnNovoFornecedor">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogFornecedor" transition="dialog-top-transition">
                                    <FornecedorCreate @cadastrouFornecedor="cadastrouFornecedor"
                                        @close-dialog="dialogFornecedor = false" />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="9">
                        <v-text-field label="Histórico" id="historico" type="text" v-model="lancamento.historico"
                            maxlength="80" :error-messages="errors['lancamento.historico']" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Total" id="valor" type="text" v-model="lancamento.valor"
                            :error-messages="errors['lancamento.valor']" />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Documento" id="documento" type="text" v-model="lancamento.documento"
                            maxlength="10" :error-messages="errors['lancamento.documento']" />
                    </v-col>
                    <v-col cols="3">
                        <v-select :items="itemsND" item-title="text" item-value="value" label="Nro.Dias/Todo Dia"
                            v-model="lancamento.nroDiasTodoDia"></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field :label=labelNroDiasTodoDia id="dias" type="text" v-model="lancamento.dias"
                            maxlength="9" :error-messages="errors['lancamento.dias']"
                            :change="changeNroDiasTodoDia()" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Carência de Dias 1ª Parcela" id="carencia" type="text"
                            v-model="lancamento.carencia" maxlength="9"
                            :error-messages="errors['lancamento.carencia']" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Número de Parcelas" id="parcelas" type="text" v-model="lancamento.parcelas"
                            maxlength="9" :error-messages="errors['lancamento.parcelas']" />
                    </v-col>
                    <v-col cols="9">
                        <v-select :items="gruposContasPagar" item-title="descricao" item-value="idGrupoContasPagar"
                            label="Grupo de Contas a Pagar" v-model="lancamento.idGrupoContasPagar"
                            id="grupoContasPagar" v-tooltip="'F7-Cadastra um novo grupo de contas a pagar'"
                            @update:modelValue="getSubgruposContasPagar()">
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo grupo de contas a pagar.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogGrupoContasPagar = true"
                                            id="btnNovoGrupoContasPagar" v-show="temPermissao('01.15.01')">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogGrupoContasPagar" transition="dialog-top-transition">
                                    <GrupoContasPagarCreate @cadastrouGrupoContasPagar="getGruposContasPagar()"
                                        @close-dialog="dialogGrupoContasPagar = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col col="12">
                        <v-select :items="subgruposContasPagar" item-title="descricao"
                            item-value="idSubgrupoContasPagar" label="Subgrupo de Contas a Pagar"
                            v-model="lancamento.idSubgrupoContasPagar"
                            id="subgrupoContasPagar" 
                            v-tooltip="'F7-Cadastra um novo subgrupo de contas a pagar'"
                            no-data-text="Nenhum subgrupo cadastrado para esse grupo.">
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo subgrupo de contas a pagar.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogSubgrupoContasPagar = true"
                                            id="btnNovoSubgrupoContasPagar" v-show="temPermissao('01.16.01')">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogSubgrupoContasPagar" transition="dialog-top-transition">
                                    <SubgrupoContasPagarCreate @cadastrouSubgrupoContasPagar="getGruposContasPagar();"
                                        @close-dialog="dialogSubgrupoContasPagar = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="showBtnGerarParcelas" color="primary" variant="elevated" type="submit"
                    id="btnContasPagarGerarParcelas">Gerar Parcelas</v-btn>
            </v-card-actions>
        </form>

        <v-dialog v-model="dialogAlterarParcela" transition="dialog-top-transition">
            <AlterarParcela :propLancamento="lancamentoAlterar" @close-dialog="dialogAlterarParcela = false"
                @alterou-lancamento="alterouLancamento" />
        </v-dialog>
    </v-card>

    <v-card elevation="16" class="mt-4">
        <v-card-title>
            <h2>Parcelas</h2>
        </v-card-title>
        <v-card-text>
            <v-table density="compact">
                <thead>
                    <tr>
                        <th class="text-center">Ações</th>
                        <th>Data Vencimento</th>
                        <th>Documento</th>
                        <th>Parcela</th>
                        <th>Valor da Parcela</th>
                        <th>Descontos</th>
                        <th>Acréscimos</th>
                        <th>Valor a Pagar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="o in lancamentos" :key="o">
                        <td>
                            <v-tooltip text="Altera o lançamento de contas a pagar.">
                                <template v-slot:activator="{ props }">
                                    <v-btn size="small" v-bind="props" variant="text" icon="mdi-pencil"
                                        @click.prevent="openModalAlterarParcela(o)">
                                    </v-btn>
                                </template>
                            </v-tooltip>
                        </td>
                        <td>{{ o.dataVencimento }}</td>
                        <td>{{ o.documento }}</td>
                        <td>{{ o.parcela }}</td>
                        <td>{{ valorFormat(o.valorParcela) }}</td>
                        <td>{{ valorFormat(o.desconto) }}</td>
                        <td>{{ valorFormat(o.acrescimo) }}</td>
                        <td>{{ valorFormat(o.valorAPagar) }}</td>
                    </tr>
                </tbody>
            </v-table>
        </v-card-text>
        <v-card-actions>
            <v-btn v-show="showBtnGravar" color="primary" variant="elevated" @click.prevent="gravar()">Salvar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="elevated" @click.prevent="$router.go(-1)">
                Voltar
            </v-btn>
        </v-card-actions>
    </v-card>
</template>