<script setup>
import { ref, onMounted, inject, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { ReciboCliente } from "../../resources/reciboClienteResource";
import { Cliente } from "../../resources/clienteResource";
import { useField, useForm } from "vee-validate";
import { string, number, object, addMethod } from "yup";
import { usePermissoes } from "../../store/permissoes";
import ClienteBusca from "../Clientes/ClienteBusca.vue";
import ClienteCreate from "../Clientes/ClienteCreate.vue";
import { Converte } from "../../utils/converte";
import Data from "../Utils/data.vue";
import Moeda from "../Utils/moeda.vue";
import MsgConfirm from "../Utils/MsgConfirm";
import { Relatorio } from '../../resources/relatoriosResource';
import config from "../../services/config";

let msgConfirm = ref(null);
const snackBarApp = inject("snackBarApp");

const isRealValidation = ref(null);
const validationCodigo = ref(null);

const route = useRoute();
const isReadOnly = ref(false);
let dialogBuscaCliente = ref(false);
let dialogCliente = ref(false);
let showBtnGravar = ref(true);

const permissoesStore = usePermissoes();

addMethod(number, "codigo_recibo_cliente_existe", function codigoReciboClienteExiste(message) {
    return number().test(
        "codigo-recibo-cliente-existe", // Name
        message, // Msg
        function (codigo_recibo_cliente) {
            if (isRealValidation.value == null) {
                return true;
            }
            if (!isRealValidation.value) {
                return validationCodigo.value;
            }
            isRealValidation.value = false;
            validationCodigo.value = false;

            return new Promise((resolve) => {
                ReciboCliente.codigoExiste(codigo_recibo_cliente, reciboCliente.value.idReciboCliente).then((res) => {
                    if (res.data == false) {
                        validationCodigo.value = true;
                        resolve(true);
                    }
                    resolve(false);
                });
            });
        }
    );
});


const validationSchema = object({
    reciboCliente: object({
        idReciboCliente: undefined,
        codigo: number()
            .codigo_recibo_cliente_existe("Esse código já está sendo usado por outro recibo, tente outro código.")
            .min(1)
            .max(999999999)
            .typeError("O código deve ser um número")
            .label("O código"),
        data: string().required("A data é obrigatória").label("Data"),
        idCliente: undefined,
        codigoCliente: number().typeError("O código do cliente deve ser um número").label("O código do cliente"),
        nomeCliente: undefined,
        descricao: string().label("A descrição"),
        valor: undefined,
    }).required(),
});

const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const reciboCliente = ref({
    idReciboCliente: useField("reciboCliente.idReciboCliente").value,
    codigo: useField("reciboCliente.codigo").value,
    data: useField("reciboCliente.data").value,
    idCliente: useField("reciboCliente.idCliente").value,
    codigoCliente: useField("reciboCliente.codigoCliente").value,
    nomeCliente: useField("reciboCliente.nomeCliente").value,
    descricao: useField("reciboCliente.descricao").value,
    valor: useField("reciboCliente.valor").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();
    if (route.name == "RecibosClientes.Show") {
        getReciboCliente(route.params.id);
        isReadOnly.value = true;

        document.getElementById("btnBuscaCliente").style.display = "none";
        document.getElementById("btnNovoCliente").style.display = "none";

        showBtnGravar.value = false;
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name !== "RecibosClientes.Show") {
        reciboCliente.value.idReciboCliente = null;
        proximoCodigo();
    }

    reciboCliente.value.data = new Date().toLocaleDateString("pt-BR"),
    reciboCliente.value.idCliente = null;
    reciboCliente.value.codigoCliente = "";
    reciboCliente.value.nomeCliente = "";
    reciboCliente.value.descricao = "";
    reciboCliente.value.valor = "R$ 0,00";
}

async function proximoCodigo() {
    try {
        const response = await ReciboCliente.proximoCodigo();
        if (response.status == 200) {
            reciboCliente.value.codigo = response.data;
        }
    } catch (err) {
        console.log(err);
    }
}

async function gravar(values, actions) {
    let dados = new FormData();

    if (values.reciboCliente.data == "") {
        values.reciboCliente.data = null;
    } else {
        values.reciboCliente.data = Converte.datePtBrToIso(values.reciboCliente.data);
    }

    dados.append("data", JSON.stringify(values.reciboCliente));

    try {
        const response = await ReciboCliente.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O recibo não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                return;
            }

            openModalImprimir(response.data.data.idReciboCliente);
            snackBarApp.value.open("Novo recibo cadastrado com sucesso.");
            actions.resetForm();
            cleanForm();
            document.getElementById("codigo").focus();
        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}

async function getReciboCliente(id) {
    let dados = new FormData();
    dados.append("codigoModulo", "03.14.02");
    dados.append("idReciboCliente", id);

    try {
        const response = await ReciboCliente.getById(dados);
        if (response.status == 200) {
            response.data.data.data = Converte.dateIsoToPtBr(response.data.data.data);
            setValues({ reciboCliente: response.data.data });
            reciboCliente.value.valor = Converte.numberToCurrency(response.data.data.valor);

            document.getElementById("codigo").focus();
        }
    } catch (err) {
        console.log(err);
    }
}

async function getCliente() {
    reciboCliente.value.idCliente = null;
    reciboCliente.value.nomeCliente = "";

    if (reciboCliente.value.codigoCliente.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", reciboCliente.value.codigoCliente);
    dados.append("getInativosBloqueados", false);
    dados.append("codigoModulo", "03.14");

    try {
        let response = await Cliente.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    snackBarApp.value.open("Um cliente inativo não pode ter novos recibos.", 6000);
                    document.getElementById("codigoCliente").focus();
                    reciboCliente.value.codigoCliente = "";
                    return;
                }
                if (response.data.message == "bloqueado") {
                    snackBarApp.value.open("Um cliente bloqueado não pode ter novos recibos.", 6000);
                    document.getElementById("codigoCliente").focus();
                    reciboCliente.value.codigoCliente = "";
                    return;
                }

                document.getElementById("codigoCliente").focus();

                snackBarApp.value.open("Cliente não encontrado, tente outro código ou faça uma busca.", 6000);

                reciboCliente.value.codigoCliente = "";
                return;
            }
            reciboCliente.value.idCliente = response.data.data.idCliente;
            reciboCliente.value.nomeCliente = response.data.data.nome;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouCliente(cliente) {
    if (cliente == null) {
        return;
    }

    reciboCliente.value.codigoCliente = cliente.codigo;
    reciboCliente.value.nomeCliente = cliente.nome;
    reciboCliente.value.idCliente = cliente.idCliente;

    getCliente();
    dialogBuscaCliente.value = false;
}

function cadastrouCliente(codigoCliente) {
    if (codigoCliente == null) {
        return;
    }

    reciboCliente.value.codigoCliente = codigoCliente;

    getCliente();
}


document.addEventListener('keydown', handleKeydown);

function handleKeydown(event) {
    if (event.key === 'F5') {
        event.preventDefault();
    }

    if (event.key === 'F7') {
        event.preventDefault();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoCliente' && document.getElementById('codigoCliente').readOnly == false) {
        event.preventDefault();
        document.getElementById('btnBuscaCliente').click();
    }

    if (event.key === 'F7' && document.activeElement.id === 'codigoCliente' && document.getElementById('codigoCliente').readOnly==false ) {
        event.preventDefault();
        document.getElementById('btnNovoCliente').click();
    }
}

onUnmounted(() => {
    document.removeEventListener('keydown', handleKeydown);
});

async function imprimir(idReciboCliente) {
    let nomeRel = 'Recibo Cliente';

    let response = await Relatorio.get(nomeRel, idReciboCliente);
    if (response.status == 200) {
        if (response.data.success == true) {
            let nomeArquivo = response.data.nomeArquivo;
            let url = config.baseUrlRel + nomeArquivo;

            window.open(url, '_blank');
            return;

        }
        if (response.data.success == false) {
            snackBarApp.value.open(response.data.message);
            return;
        }

        snackBarApp.value.open("Não foi possível fazer a impressão do recibo, tente novamente.");
    }
}


function openModalImprimir(idReciboCliente) {
    msgConfirm.value
        .open(
            "Imprimir Recibo ?",
            "Deseja imprimir um recibo para o cliente ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                imprimir(idReciboCliente);
            }
        });
}


</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-card elevation="16">
        <form ref="formReciboCliente" @submit.prevent="submit">
            <v-card-title>
                <h2 v-show="$route.name == 'RecibosClientes.Create'">Novo Recibo para o Cliente</h2>
                <h2 v-show="$route.name == 'RecibosClientes.Show'">Consulta de Recibo</h2>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <v-text-field
                            label="Código"
                            id="codigo"
                            type="text"
                            v-model="reciboCliente.codigo"
                            :error-messages="errors['reciboCliente.codigo']"
                            maxlength="9"
                            @blur="
                                (e) => {
                                    isRealValidation = true;
                                    reciboCliente.codigo = e.target.value;
                                }
                            "
                            :readonly="isReadOnly"
                        />
                    </v-col>

                    <v-col cols="4">
                        <Data label="Data" id="data" type="text" v-model="reciboCliente.data"
                            :error-messages="errors['reciboCliente.data']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="4">
                        <Moeda label="Valor" id="valor" type="text" v-model="reciboCliente.valor"
                            :error-messages="errors['reciboCliente.valor']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Cód.Cliente" id="codigoCliente" type="text"
                            v-model="reciboCliente.codigoCliente"
                            :error-messages="errors['reciboCliente.codigoCliente']" maxlength="9"
                            @blur="getCliente()" :readonly="isReadOnly"
                            v-tooltip="isReadOnly ? 'código do cliente' : 'F5-Abre a busca de cliente; F7-Cadastra um novo cliente'" />
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="Nome do Cliente" id="nomeCliente" type="text"
                            v-model="reciboCliente.nomeCliente" readonly tabindex="-1">
                            <template v-slot:append>
                                <v-tooltip text="Faz a busca de um cliente por nome/apelido/nome fantasia/cpf.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaCliente = true" id="btnBuscaCliente">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaCliente" transition="dialog-top-transition">
                                    <ClienteBusca 
                                        @selecionouCliente = "selecionouCliente" 
                                        @close-dialog="dialogBuscaCliente = false" 
                                        :select-inativo-bloqueado=false />
                                </v-dialog>
                                &nbsp;
                                <v-tooltip text="Cadastra um novo cliente.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogCliente = true" id="btnNovoCliente">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogCliente" transition="dialog-top-transition">
                                    <ClienteCreate @cadastrouCliente="cadastrouCliente" @close-dialog="dialogCliente = false" />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea label="Descrição" id="descricao" type="text" 
                            v-model="reciboCliente.descricao"
                            :error-messages="errors['reciboCliente.descricao']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn v-show="showBtnGravar && $route.name !== 'RecibosClientes.Show'" color="primary"
                    variant="elevated" type="submit" id="btnRecibosClientesGravar">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click.prevent="$router.go(-1)"
                    v-show="$route.name == 'RecibosClientes.Create' || $route.name == 'RecibosClientes.Show'">Voltar</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
