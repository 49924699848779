import Http from './http';

export class OrcamentoOrdemServicos {
    static listar = function(start, limit, sort, filtrarPor, dataInicial, dataFinal, abertos, cancelados, transformados, idCliente, filtro, tipoFiltro, nomeCampo) {
        return Http.get('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'sort':sort,
                'filtrarPor': filtrarPor,
                'dataInicial': dataInicial,
                'dataFinal': dataFinal,
                'abertos': abertos,
                'cancelados': cancelados,
                'transformados': transformados,
                'idCliente': idCliente,
                'filtro': filtro,
                'tipoFiltro': tipoFiltro,
                'nomeCampo': nomeCampo,
            }
        });
    };

    static gravar = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=gravar', dados);
    };

    static proximoCodigo = function() {
        return Http.get('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=proximocodigo');
    };

    static codigoExiste = function(codigo, idOrcamentoOrdemServicos) {
        let dados = new FormData();
        dados.append('codigo', codigo);
        dados.append('idOrcamentoOrdemServicos', idOrcamentoOrdemServicos);
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=codigoexiste', dados);
    };

    static getById = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=getbyid', dados);
    };

    static excluir = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=excluir', dados);
    };

    static cancelar = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=cancelar', dados);
    };

    static addProduto = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=addproduto', dados);
    };

    static listarProdutos = function(idOrcamentoOrdemServicos) {
        return Http.get('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=listarprodutos', {
            params: {
                'idOrcamentoOrdemServicos':idOrcamentoOrdemServicos,
            }
        });
    };

    static addServico = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=addservico', dados);
    };

    static listarServicos = function(idOrcamentoOrdemServicos) {
        return Http.get('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=listarservicos', {
            params: {
                'idOrcamentoOrdemServicos':idOrcamentoOrdemServicos,
            }
        });
    };


    static totais = function(idOrcamentoOrdemServicos) {
        return Http.get('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=totais', {
            params: {
                'idOrcamentoOrdemServicos':idOrcamentoOrdemServicos,
            }
        });
    };

    static removeProduto = function(idProdutoOrcamentoOS) {
        let dados = new FormData();
        dados.append('idProdutoOrcamentoOS', idProdutoOrcamentoOS);

        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=removeproduto', dados);
    };

    static removeServico = function(idServicoOrcamentoOS) {
        let dados = new FormData();
        dados.append('idServicoOrcamentoOS', idServicoOrcamentoOS);

        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=removeservico', dados);
    };
    static updateProduto = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=updateproduto', dados);
    };
    static updateServico = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=updateservico', dados);
    };
    static getObservacoes = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=getobservacoes', dados);
    };
    static gravarObservacoes = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=gravarobservacoes', dados);
    };
    static transformar = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=transformar', dados);
    };
    static getCondicoesPagamento = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=getcondicoespagamento', dados);
    };
    static gravarCondicoesPagamento = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=gravarcondicoespagamento', dados);
    };
    static descontos = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=descontos', dados);
    };
    static getObservacoesInternas = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=getobservacoesinternas', dados);
    };
    static gravarObservacoesInternas = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=gravarobservacoesinternas', dados);
    };
    static duplica = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=duplica', dados);
    };
    static alterarCodigo = function(dados) {
        return Http.post('/Serv/OrcamentoOrdemServicos/OrcamentoOrdemServicos.php?operacao=alterarcodigo', dados);
    };
}
