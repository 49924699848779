<script setup>
import { onMounted, inject, defineProps, defineEmits, ref } from "vue";

import { number, object, addMethod } from "yup";
import { OrdemServicos } from "../../resources/ordemServicosResource";
import { useField, useForm } from "vee-validate";

const snackBarApp = inject("snackBarApp");

const props = defineProps({
    idOrdemServicos: {
        type: Number,
        required: true,
    },
    codigoAntigo: {
        type: Number,
        required: true,
    }
});

addMethod(number, "codigo_ordem_servicos_existe", function codigoOrdemServicosExiste(message) {
    return number().test(
        "codigo-ordem-servicos-existe", // Name
        message, // Msg
        function (codigo) {
            if (isRealValidation.value == null) {
                return true;
            }
            if (!isRealValidation.value) {
                return validationCodigo.value;
            }
            isRealValidation.value = false;
            validationCodigo.value = false;

            return new Promise((resolve) => {
                OrdemServicos.codigoExiste(codigo, ordemServicos.value.idOrdemServicos).then((res) => {
                    if (res.data == false) {
                        validationCodigo.value = true;
                        resolve(true);
                    }
                    resolve(false);
                });
            });
        }
    );
});

const isRealValidation = ref(null);
const validationCodigo = ref(null);
const emit = defineEmits(['close-dialog', 'alterou-codigo']);

const validationSchema = object({
    ordemServicos: object({
        idOrdemServicos: undefined,
        codigoAntigo: undefined,
        codigoNovo: number()
            .min(1)
            .max(999999999)
            .codigo_ordem_servicos_existe("Esse código já está sendo usado por outra ordem de serviços, tente outro código.")
            .typeError("O código deve ser um número")
            .label("O código"),
    }).required(),
});

const { handleSubmit, errors } = useForm({ validationSchema });

const ordemServicos = ref({
    idOrdemServicos: useField("ordemServicos.idOrdemServicos").value,
    codigoAntigo: useField("ordemServicos.codigoAntigo").value,
    codigoNovo: useField("ordemServicos.codigoNovo").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    ordemServicos.value.idOrdemServicos = props.idOrdemServicos;
    ordemServicos.value.codigoAntigo = props.codigoAntigo;
    proximoCodigo();
    document.getElementById("codigoNovo").focus();
});


async function gravar() {
    let obj =  {};
    obj.idOrdemServicos = ordemServicos.value.idOrdemServicos;
    obj.codigo = ordemServicos.value.codigoNovo;
    
    let dados = new FormData();
    dados.append('dados', JSON.stringify(obj));

    try {
       let response = await OrdemServicos.alterarCodigo(dados);
       
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open('Não foi possível alterar o código da ordem de serviços, '+ response.data.message);
                return;
            }

            snackBarApp.value.open('Código da ordem de serviços foi alterado com sucesso.');
            emit('alterou-codigo');
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao alterar o código da ordem de serviços.');
        console.log(err);
    }
}

async function proximoCodigo() {
    try {
        const response = await OrdemServicos.proximoCodigo();
        if (response.status == 200) {
            ordemServicos.value.codigoNovo = response.data;
        }
    } catch (err) {
        snackBarApp.value.open('Erro ao buscar o próximo código da ordem de serviços.');
        console.log(err);
    }
}

</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2>Alteração do Código da Ordem de Serviços</h2>
        </v-card-title>
        <form ref="formAlteraCodigoOrdemServicos" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                            <v-text-field
                                label="Código Antigo"
                                id="codigoAntigo"
                                type="text"
                                v-model="ordemServicos.codigoAntigo"
                                maxlength="9"
                                readonly
                                tabindex="-1"
                            />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            label="Código Novo"
                            id="codigoNovo"
                            type="text"
                            v-model="ordemServicos.codigoNovo"
                            :error-messages="errors['ordemServicos.codigoNovo']"
                            maxlength="9"
                            @blur="
                                (e) => {
                                    isRealValidation = true;
                                    ordemServicos.codigoNovo = e.target.value;
                                }
                            "
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated"  type="submit" id="btnAlterarCodigo">Altera Código</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="emit('close-dialog')">
                    Fechar
                    </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>