<script setup>
import { ref, inject, defineEmits, onUnmounted } from 'vue';
import { useRelatorio } from "../../store/relatorio";
import { Relatorio } from "../../resources/relatoriosResource";
import { Cliente } from "../../resources/clienteResource";
import { Converte } from "../../utils/converte";
import config from "../../services/config";
import Data from "../Utils/data.vue";
import ClienteBusca from "../Clientes/ClienteBusca.vue";

const emit = defineEmits(['close-dialog']);
const snackBarApp = inject('snackBarApp');

const dataInicial = ref(new Date().toLocaleDateString("pt-BR"));
const dataFinal = ref(new Date().toLocaleDateString("pt-BR"));
const idCliente = ref(null);
const codigoCliente = ref('');
const nomeCliente = ref('');

const relatorioStore = useRelatorio();

const dialogCliente = ref(false);

async function submit() {
    let nomeRel = relatorioStore.relatorio;

    if (dataInicial.value == '' || dataFinal.value == '') {
        snackBarApp.value.open('Informe as datas inicial e final para a impressão do relatório.');
        return;
    }

    if (idCliente.value == null) {
        snackBarApp.value.open('O cliente precisa ser escolhido, preencha o campo do código do cliente e tente novamente.');
        return;
    }

    let auxDataInicial = Converte.datePtBrToIso(dataInicial.value);
    let auxDataFinal = Converte.datePtBrToIso(dataFinal.value);

    try {
        let response = await Relatorio.get(nomeRel, auxDataInicial, auxDataFinal, idCliente.value);
        if (response.status == 200) {
            if (response.data.success == true) {
                let nomeArquivo = response.data.nomeArquivo;
                let url = config.baseUrlRel + nomeArquivo;

                window.open(url, '_blank');
                return;

            }

            if (response.data.success == false) {
                snackBarApp.value.open(response.data.message);
                return;
            }

            snackBarApp.value.open('Não foi possível fazer a impressão do relatório, tente novamente.');
        }
    }
    catch (error) {
        snackBarApp.value.open('Erro ao tentar imprimir o relatório.');
    }
}

function selecionouCliente(cliente) {
    if (cliente == null) {
        return;
    }

    codigoCliente.value = cliente.codigo;
    getCliente();
    dialogCliente.value = false;
}

async function getCliente() {
    idCliente.value = null;
    nomeCliente.value = "";
    if (codigoCliente.value.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", codigoCliente.value);
    dados.append("getInativos", true);
    dados.append("codigoModulo", "04.04");

    try {
        let response = await Cliente.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                document.getElementById("codigoCliente").focus();
                snackBarApp.value.open("O cliente não foi encontrado, tente outro código ou faça uma busca.");
                codigoCliente.value = "";
                return;
            }
            idCliente.value = response.data.data.idCliente;
            codigoCliente.value = response.data.data.codigo;
            nomeCliente.value = response.data.data.nome;
        }
    } catch (err) {
        console.log("Erro ao buscar o cliente: " + err);
    }
}

document.addEventListener('keydown', handleKeydown);

function handleKeydown(event) {
    if (event.key === 'F5') {
        event.preventDefault();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoCliente') {
        event.preventDefault();
        document.getElementById('btnBuscaCliente').click();
    }
}

onUnmounted(() => {
    document.removeEventListener('keydown', handleKeydown);
});

</script>


<template>
    <v-container>
        <v-card elevation="16">
            <v-card-title>
                <h4>Digite as Datas Inicial, Final, Cliente e clique em Confirmar</h4>
            </v-card-title>

            <form ref="formTelaDatasCliente" @submit.prevent="submit">
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <Data v-model="dataInicial" label="Data Inicial" id="dataInicial" />
                        </v-col>
                        <v-col cols="6">
                            <Data v-model="dataFinal" label="Data Final" id="dataFinal" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-text-field id="codigoCliente" label="Cód.Cliente" type="text" v-model="codigoCliente"
                                v-tooltip="'F5-Abre a busca de clientes'"
                                @blur="getCliente()"></v-text-field>
                        </v-col>
                        <v-col cols="9">
                            <v-text-field label="Nome do Cliente" type="text" v-model="nomeCliente" readonly
                                tabindex="-1">
                                <template v-slot:append>
                                    <v-tooltip text="Faz a busca de um cliente por nome apelido/CPF.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" color="primary" @click="dialogCliente = true" id="btnBuscaCliente">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                    <v-dialog v-model="dialogCliente" transition="dialog-top-transition">
                                        <ClienteBusca @selecionouCliente="selecionouCliente"
                                            @close-dialog="dialogCliente = false" :select-inativo-bloqueado=true />
                                    </v-dialog>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" variant="elevated" type="submit"
                        id="btnTelaDatasClienteConfirmar">Confirmar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" variant="elevated" @click="emit('close-dialog')">Fechar</v-btn>
                </v-card-actions>
            </form>
        </v-card>
    </v-container>
</template>