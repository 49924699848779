<script setup>
import { ref, computed, onMounted, inject } from "vue";
import { Caixa } from "@/resources/caixaResource";
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import { useCaixa } from "../../store/caixa";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";
import { dateFormat, valorFormat, debitoCreditoFormat} from "../../services/filtros";

let msgConfirm = ref(null);
let snackBarApp = inject("snackBarApp");

const caixaStore = useCaixa();
let lancamentoCaixa = ref({});
let lancamentosCaixa = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(caixaStore.currentPage);
const credito = ref(caixaStore.credito);
const debito = ref(caixaStore.debito);
const dataInicial = ref(caixaStore.dataInicial);
const dataFinal = ref(caixaStore.dataFinal);
const comSaldoAnterior = ref(caixaStore.comSaldoAnterior);

const totalCreditos = ref('');
const totalDebitos = ref('');
const saldo = ref('');


const direction = computed(() => {
    return caixaStore.sort[0].direction;
});

const orderBy = computed(() => {
    return caixaStore.sort[0].property;
});

onMounted(() => {
    filtrar(caixaStore.filtrarPor);
});

async function destroy() {
    let dados = new FormData();
    dados.append("data", JSON.stringify(lancamentoCaixa.value));

    try {
        const response = await Caixa.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Lançamento de caixa excluído com sucesso.");
                filtrar();
            }
        }
    } catch (err) {
        console.log(err);
    }
}
function openModalDelete(lancamento) {
    lancamentoCaixa.value = lancamento;
    msgConfirm.value
        .open(
            "Confirma Exclusão ?",
            "Confirma a exclusão do lançamento de caixa ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                destroy();
            }
        });
}


async function filtrar(mudouPagina = false) {
    let start = caixaStore.start;
    let limit = caixaStore.limit;
    let sort = JSON.stringify(caixaStore.sort);

    let dc = 'Z';
    if(credito.value == true && debito.value == true) {
        dc = '2'
    }
    if(credito.value == true && debito.value == false) {
        dc = 'C'
    }
    if(credito.value == false && debito.value == true) {
        dc = 'D'
    }
    caixaStore.setDataInicial(dataInicial.value);
    caixaStore.setDataFinal(dataFinal.value);
    caixaStore.setDebito(debito.value);
    caixaStore.setCredito(credito.value);
    caixaStore.setComSaldoAnterior(comSaldoAnterior.value);

    let auxDataInicial = null;
    let auxDataFinal = null;

    if(dataInicial.value != '') {
        auxDataInicial = Converte.datePtBrToIso(dataInicial.value); 
    }

    if(dataFinal.value != '') {
        auxDataFinal = Converte.datePtBrToIso(dataFinal.value); 
    }

    if(mudouPagina == false) {
        handleCurrentChange(1);
        return;
    }


    try {
        const response = await Caixa.get(start, limit, sort, dc, auxDataInicial, auxDataFinal, comSaldoAnterior.value)
        lancamentosCaixa.value = response.data.data;
        total.value = parseInt(response.data.total);

        totalCreditos.value = 'Total de Créditos:'+Converte.numberToCurrency(response.data.totalCreditos);
        totalDebitos.value = 'Total de Débitos:'+Converte.numberToCurrency(response.data.totalDebitos);
        saldo.value = 'Saldo:'+Converte.numberToCurrency(response.data.saldo);
    } catch (err) {
        console.log(err);
    }
}

function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    caixaStore.setCurrentPage(val);
    caixaStore.setStart(start);

    filtrar(true);
}
function setSort(property) {
    let sort = caixaStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [
        {
            property: property,
            direction: direction,
        },
    ];

    caixaStore.setSort(aSort);
    filtrar();
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-row>
        <v-col>
            <h1>Caixa</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.01.01')" color="primary" :to="{ name: 'Caixa.Create' }">Novo Lançamento de Caixa</v-btn>&nbsp;&nbsp;
            <v-btn v-show="temPermissao('03.01.03')" color="primary" :to="{ name: 'Caixa.TransferenciaConta' }">Transferência para Conta Bancária</v-btn>
        </v-col>
    </v-row>
    <v-row >
        <v-col cols="12">
            <v-form>
                <v-row>
                    <v-col cols="3">
                        <v-card elevation="16"  density="compact">
                            <v-card-title color="primary">Mostrar</v-card-title>
                            <v-card-text  density="compact">
                                <v-row class="ml-1">
                                    <v-checkbox density="compact" v-model="credito" label="Créditos" @change="filtrar()"></v-checkbox>
                                </v-row>
                                <v-row class="ml-1">
                                    <v-checkbox density="compact" v-model="debito" label="Débitos" @change="filtrar()"></v-checkbox>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="9">
                        <v-card elevation="16" class="fill-height">
                            <v-card-title>Filtro</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="5">
                                        <Data label="Data Inicial" type="text" v-model="dataInicial"></Data>
                                    </v-col>
                                    <v-col cols="5">
                                        <Data label="Data Final" type="text" v-model="dataFinal"></Data>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-btn color="primary" @click.prevent="filtrar()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>
    
    <v-card elevation="16" class="mt-3">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('data')">
                            Data
                            <v-icon v-if="orderBy == 'data'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('descricao')">
                            Descrição
                            <v-icon v-if="orderBy == 'descricao'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>

                    <th>Déb./Créd.</th>
                    <th>Valor</th>
                    <th>Documento</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in lancamentosCaixa" :key="o">
                    <td :class="o.dc == 'D' ? 'text-red' : ''">
                        <v-tooltip text="Exclui o lançamento de caixa.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-show="temPermissao('03.01.02')" size="small" v-bind="props" variant="text" @click.prevent="openModalDelete(o)" icon="mdi-trash-can">
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>
                    <td :class="o.dc == 'D' ? 'text-red' : ''">{{ dateFormat(o.data) }}</td>
                    <td :class="o.dc == 'D' ? 'text-red' : ''">{{ o.descricao }}</td>
                    <td :class="o.dc == 'D' ? 'text-red' : ''">{{ debitoCreditoFormat(o.dc) }}</td>
                    <td :class="o.dc == 'D' ? 'text-red' : ''">{{ valorFormat(o.valor) }}</td>
                    <td :class="o.dc == 'D' ? 'text-red' : ''">{{ o.documento }}</td>
                </tr>
            </tbody>
        </v-table>
        <v-row>
            <v-col cols="6">
                <v-checkbox v-model="comSaldoAnterior" label="Calcular saldo com saldo anterior" @change="filtrar()"></v-checkbox>
            </v-col>
            <v-col cols="6" class="text-right">
                <h4>{{ totalCreditos }}</h4>
                <h4>{{ totalDebitos }}</h4>
                <h4>{{ saldo }}</h4>            
            </v-col>
        </v-row>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage" :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-btn v-show="temPermissao('03.01.01')" color="primary" :to="{ name: 'Caixa.Create' }">Novo Lançamento de Caixa</v-btn>&nbsp;&nbsp;
    <v-btn v-show="temPermissao('03.01.03')" color="primary" :to="{ name: 'Caixa.TransferenciaConta' }">Transferência para Conta Bancária</v-btn>
</template>