import Http from './http';

export class Caixa {

    static get = function(start, limit, sort, dc, dataInicial, dataFinal, comSaldoAnterior) {
        return Http.get('/Serv/Caixa/Caixa.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'sort':sort,
                'dc': dc,
                'dataInicial':dataInicial,
                'dataFinal':dataFinal,
                'comSaldoAnterior': comSaldoAnterior,
            }
        });
    };

    static save = function(dados) {
        return Http.post('/Serv/Caixa/Caixa.php?operacao=gravar', dados);
    };

    static excluir = function(dados) {
        return Http.post('/Serv/Caixa/Caixa.php?operacao=excluir', dados);
    };

    static transferenciaConta = function(dados) {
        return Http.post('/Serv/Caixa/Caixa.php?operacao=transferenciaconta', dados);
    };

}

