<script setup>
import { onMounted, inject, defineProps, defineEmits, ref } from "vue";

import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import Porcentagem from "../Utils/porcentagem.vue";
import { useRoute } from "vue-router";
import { PedidoCompra } from "../../resources/pedidoCompraResource";
import { Converte } from "../../utils/converte";


const snackBarApp = inject("snackBarApp");

const props = defineProps({
    propPedidoCompra: {
        type: Object,
    },
    operacao: {
        type: String,
    },
});

const realPorcentagem = ref([
    { text: "Reais", value: "R" },
    { text: "Porcentagem", value: "P" },
]);

const pedidoCompra = ref({});
const operacao = ref(props.operacao);
const route = useRoute();
const isReadOnly = ref(false);

const emit = defineEmits(['close-dialog', 'faturou']);

onMounted(() => {
    cleanData();
    document.getElementById("documento").focus();

    if(operacao.value == "C") {
       isReadOnly.value = true;
        getPedidoCompra(props.propPedidoCompra.idPedidoCompra);
    }
});



function cleanData() {
    pedidoCompra.value.idPedidoCompra = props.propPedidoCompra.idPedidoCompra;
    pedidoCompra.value.documento = props.propPedidoCompra.documento;
    pedidoCompra.value.dataEntrega = null;
    if(operacao.value == 'R') {
        pedidoCompra.value.dataEntrega = new Date().toLocaleDateString("pt-BR");
    }
    pedidoCompra.value.dataFaturamento = new Date().toLocaleDateString("pt-BR");
    pedidoCompra.value.valorTotalProdutos = 'R$ 0,00';
    pedidoCompra.value.descRP = 'R';
    pedidoCompra.value.desconto = 'R$ 0,00';
    pedidoCompra.value.acreRP = 'R';
    pedidoCompra.value.acrescimo = 'R$ 0,00';
    pedidoCompra.value.valorTotalAPagar = props.valorTotal;

    getTotais();

}

async function getTotais() {
    try {
        let response = await PedidoCompra.totais(pedidoCompra.value.idPedidoCompra);
        if (response.status == 200) {
            pedidoCompra.value.valorTotalProdutos = Converte.numberToCurrency(response.data.data.totalProdutos);
            if (route.name !== 'PedidosCompras.Show') {
                pedidoCompra.value.valorTotalAPagar = Converte.numberToCurrency(response.data.data.valorTotalAPagar);
            }
            calculaValorAPagar();
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar os totais do pedido de compra.');
        console.log(err);
    }
}


async function submit() {
    let valorTotalPComDesconto = Converte.stringToFloat(pedidoCompra.value.valorTotalAPagar);
    if(valorTotalPComDesconto < 0 ) {
        snackBarApp.value.open('O valor total dos produtos com desconto mais acréscimo deve ser maior ou igual a 0. Corrija o valor dos descontos dos produtos.', 6000);
        return;
    }

    let obj =  {};
    obj.idPedidoCompra = pedidoCompra.value.idPedidoCompra;
    obj.documento = pedidoCompra.value.documento;
    obj.descRP = pedidoCompra.value.descRP;
    obj.acreRP = pedidoCompra.value.acreRP;
    obj.desconto = Converte.stringToFloat(pedidoCompra.value.desconto);
    obj.acrescimo = Converte.stringToFloat(pedidoCompra.value.acrescimo);
    obj.valorTotalAPagar = Converte.stringToFloat(pedidoCompra.value.valorTotalAPagar);

    if(pedidoCompra.value.dataEntrega == '' || pedidoCompra.value.dataEntrega == 'Invalid date' || pedidoCompra.value.dataEntrega == null) {
        obj.dataEntrega = null;
    } else{
        obj.dataEntrega = Converte.datePtBrToIso(pedidoCompra.value.dataEntrega);
    }

    if(operacao.value == 'R' && obj.dataEntrega == null) {
        snackBarApp.value.open('Para fazer o recebimento do pedido é necessário preencher a data de entrega.', 6000);
        return;
    }

    if(pedidoCompra.value.dataFaturamento == '' || pedidoCompra.value.dataFaturamento == 'Invalid date') {
        obj.dataFaturamento = null;
    } else{
        obj.dataFaturamento = Converte.dateIsoToPtBr(pedidoCompra.value.dataFaturamento);
    }

    let dados = new FormData();
    dados.append('dados', JSON.stringify(obj));

    try {

        let response = await PedidoCompra.faturaAVista(dados);
        if (response.status == 200) {

            if (response.data.success == false) {
                snackBarApp.value.open('Não foi possível faturar pedido de compra a vista, '+response.data.message, 6000);
                return;
            }

            if(operacao.value == 'F') {
                snackBarApp.value.open('Pedido de compra faturado com sucesso.');
            }
            else {
                snackBarApp.value.open('Pedido de compra faturado e recebido com sucesso.');
            }
            

            emit('faturouPedidoCompra', 'CAI');
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao faturar pedido de compra a vista.');
        console.log(err);
    }
}
     

function changeDescRP() {
    if(pedidoCompra.value.descRP == 'P') {
        pedidoCompra.value.desconto = Converte.numberToPorcentagem(Converte.stringToFloat(pedidoCompra.value.desconto),2);
    }
    if(pedidoCompra.value.descRP == 'R') {
        pedidoCompra.value.desconto = Converte.numberToCurrency(Converte.stringToFloat(pedidoCompra.value.desconto));
    }

    calculaValorAPagar();
}

function changeAcreRP() {
    if(pedidoCompra.value.acreRP == 'P') {
        pedidoCompra.value.acrescimo = Converte.numberToPorcentagem(Converte.stringToFloat(pedidoCompra.value.acrescimo),2);
    }
    if(pedidoCompra.value.acreRP == 'R') {
        pedidoCompra.value.acrescimo = Converte.numberToCurrency(Converte.stringToFloat(pedidoCompra.value.acrescimo));
    }

    calculaValorAPagar();
}

function calculaValorAPagar() {
    let valorAPagar = 0;
    let desconto = Converte.stringToFloat(pedidoCompra.value.desconto);
    let acrescimo = Converte.stringToFloat(pedidoCompra.value.acrescimo);
    let descRP = pedidoCompra.value.descRP;
    let acreRP = pedidoCompra.value.acreRP;
    let valorTotalP = Converte.stringToFloat(pedidoCompra.value.valorTotalProdutos);

    if(descRP == 'R') {
        valorAPagar = valorTotalP - desconto;
    } else {
        valorAPagar = valorTotalP - (valorTotalP * desconto / 100);
    }

    if(acreRP == 'R') {
        valorAPagar = valorAPagar + acrescimo;
    } else {
        valorAPagar = valorAPagar +  (valorTotalP * acrescimo / 100);
    }

    pedidoCompra.value.valorTotalAPagar = Converte.numberToCurrency(valorAPagar);
}

async function getPedidoCompra(idPedidoCompra) {
    let dados = new FormData();
    dados.append('codigoModulo', '03.13.02');
    dados.append('idPedidoCompra', idPedidoCompra);

    try {

        let response = await PedidoCompra.getById(dados);
        if (response.status == 200) {

            pedidoCompra.value.documento = response.data.data.documento;

            if (route.name == 'PedidosCompras.Show' || response.data.data.formaPagamento !== null) {

                pedidoCompra.value.dataFaturamento = Converte.dateIsoToPtBr(response.data.data.dataFaturamento);
                pedidoCompra.value.dataEntrega = Converte.dateIsoToPtBr(response.data.data.dataEntrega);
                pedidoCompra.value.descRP = response.data.data.descRP;
                if (response.data.data.descRP == 'R') {
                    pedidoCompra.value.desconto = Converte.numberToCurrency(response.data.data.desconto);
                } else {
                    pedidoCompra.value.desconto = Converte.numberToPorcentagem(response.data.data.desconto, 2);
                }

                pedidoCompra.value.acreRP = response.data.data.acreRP;
                if (response.data.data.acreRP == 'R') {
                    pedidoCompra.value.acrescimo = Converte.numberToCurrency(response.data.data.acrescimo);
                } else {
                    pedidoCompra.value.acrescimo = Converte.numberToPorcentagem(response.data.data.acrescimo, 2);
                }
            }

            pedidoCompra.value.documento = response.data.data.documento;
            if(route.name !== 'PedidosCompras.Show' && operacao.value == 'R') {
                pedidoCompra.value.dataEntrega = new Date().toLocaleDateString("pt-BR");
            }

            calculaValorAPagar();
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar o pedido de compra.');
        console.log(err);
    }
}
</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="operacao !== 'C'">Faturamento do Pedido de Compra a Vista</h2>
            <h2 v-show="operacao == 'C'">Pedido de Compra Faturado a Vista</h2>
        </v-card-title>
        <form ref="formFaturaPedidoCompraAVista" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Documento" id="documento" v-model="pedidoCompra.documento" maxlength="10" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Data label="Data do Faturamento" id="dataFaturamento" v-model="pedidoCompra.dataFaturamento" :readonly="isReadOnly"/>
                    </v-col>    
                    <v-col cols="3">
                        <v-text-field label="Valor Total Produtos" id="valorTotalProdutos" v-model="pedidoCompra.valorTotalProdutos" readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-select
                                :items="realPorcentagem"
                                item-title="text"
                                item-value="value"
                                label="Desconto em R$ ou em %"
                                v-model="pedidoCompra.descRP"
                                :readonly="isReadOnly"
                                @update:modelValue="changeDescRP()"
                         ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" v-show="pedidoCompra.descRP == 'R'">
                        <Moeda label="Desconto em (R$)" id="desconto" v-model="pedidoCompra.desconto" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3" v-show="pedidoCompra.descRP == 'P'">
                        <Porcentagem label="Desconto em (%)" id="desconto" v-model="pedidoCompra.desconto" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <v-select
                                :items="realPorcentagem"
                                item-title="text"
                                item-value="value"
                                label="Acréscimo em R$ ou em %"
                                v-model="pedidoCompra.acreRP"
                                :readonly="isReadOnly"
                                @update:modelValue="changeAcreRP()"
                         ></v-select>
                    </v-col>
                    <v-col cols="3" v-show="pedidoCompra.acreRP == 'R'">
                        <Moeda label="Acréscimo em (R$)" id="acrescimo" v-model="pedidoCompra.acrescimo" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3" v-show="pedidoCompra.acreRP == 'P'">
                        <Porcentagem label="Acréscimo em (%)" id="acrescimo" v-model="pedidoCompra.acrescimo" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Total a Pagar" id="valorTotalAPagar" v-model="pedidoCompra.valorTotalAPagar" readonly tabindex="-1" />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="3" v-show="operacao == 'R' || route.name == 'PedidosCompras.Show'">
                        <Data label="Data da Entrega" id="dataEntrega" v-model="pedidoCompra.dataEntrega" :readonly="isReadOnly"/>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn v-show="operacao !== 'C'" color="primary" variant="elevated" @click.prevent="submit()" id="btnConfirmarAVista">Confirmar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="emit('close-dialog')">
                    Fechar
                    </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
