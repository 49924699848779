<script setup>
import { ref, onMounted, defineEmits, inject, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import { SubgrupoServicos } from '../../resources/subgrupoServicosResource';
import { PermissaoService } from '../../services/permissaoService';
import { GrupoServicos } from '../../resources/grupoServicosResource';
import { useField, useForm } from 'vee-validate'
import { object, string, number } from 'yup';
import { usePermissoes } from '../../store/permissoes';
import GrupoServicosCreate from '../GruposServicos/GrupoServicosCreate.vue';

const emit = defineEmits(['close-dialog', 'cadastrou-subgrupo-servicos']);
const snackBarApp = inject('snackBarApp');
const route = useRoute();
let dialog = ref(false);
let showBtnGravar = ref(true);
const permissoesStore = usePermissoes();

const validationSchema = object({
    subgrupoServicos: object({
        idSubgrupoServicos: undefined,
        idGrupoServicos: number().required('O grupo de serviços é obrigatório').label('O grupo de serviços'),
        descricao: string().required('A descrição é obrigatória').max(50)
    }).required()
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const gruposServicos = ref([]);

const subgrupoServicos = ref({
    idSubgrupoServicos: useField('subgrupoServicos.idSubgrupoServicos').value,
    idGrupoServicos: useField('subgrupoServicos.idGrupoServicos').value,
    descricao: useField('subgrupoServicos.descricao').value
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    getGruposServicos();
    if (route.name == 'SubgrupoServicos.Update') {
        getSubgrupoServicos(route.params.id);
    }
    else {
        subgrupoServicos.value.idSubgrupoServicos = null;
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == 'D' || tipo == 'P') && validade == 'N') {
        showBtnGravar.value = false;
    }
});
async function gravar(values, actions) {
    let dados = new FormData();
    dados.append('data', JSON.stringify(values.subgrupoServicos));

    try {
        const response = await SubgrupoServicos.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open('Subgrupo de serviços não pode ser cadastrado, mensagem de erro: ' + response.data.message, 6000);
                return;
            }
            if (route.name == 'SubgrupoServicos.Update') {
                snackBarApp.value.open('Subgrupo de serviços alterado com sucesso.');
            }
            else {
                snackBarApp.value.open('Novo subgrupo de serviços cadastrado com sucesso.');
                let idGrupoServicos = subgrupoServicos.value.idGrupoServicos;
                actions.resetForm();
                subgrupoServicos.value.idGrupoServicos = idGrupoServicos;
                subgrupoServicos.value.idSubgrupoServicos = null;
                emit('cadastrou-subgrupo-servicos');
                document.getElementById("descricao").focus();
            }
        }
    } catch (err) {
        snackBarApp.value.open('Erro, mensagem de erro: ' + err);
    }
}
function closeModalCadastroSubgrupoServicos() {
    emit('close-dialog');
}
async function getGruposServicos() {
    let start = 0;
    let limit = 999999;
    let sort = null;

    await GrupoServicos.get(start, limit, '', sort)
        .then(function (response) {
            gruposServicos.value = response.data.data;

            if (subgrupoServicos.value.idGrupoServicos == null) {
                subgrupoServicos.value.idGrupoServicos = response.data.data[0].idGrupoServicos;
                return;
            }

        })
        .catch(function () {

        });
}

async function getSubgrupoServicos(id) {
    let dados = new FormData();
    dados.append('codigoModulo', '01.13.02');
    dados.append('idSubgrupoServicos', id);
    try {
        const response = await SubgrupoServicos.getById(dados);
        if (response.status == 200) {
            setValues({ 'subgrupoServicos': response.data.data });
            document.getElementById("descricao").focus();
        }
    } catch (err) {
        console.log(err);
    }
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

document.addEventListener('keydown', handleKeyDown);

function handleKeyDown(event) {
    if(event.key === 'F7') {
        event.preventDefault();
    }

    if (event.key === 'F7' && document.activeElement.id === 'grupoServicos' ) {
        event.preventDefault();
        document.getElementById('btnNovoGrupoServicos').click();
    }
}

onUnmounted(() => {
    document.removeEventListener('keydown', handleKeyDown);
});

</script>


<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="$route.name !== 'SubgrupoServicos.Update'">Novo Subgrupo de Serviços</h2>
            <h2 v-show="$route.name == 'SubgrupoServicos.Update'">Alteração de Subgrupo de Serviços</h2>
        </v-card-title>
        <form ref="formSubgrupoServicos" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-select :items="gruposServicos" item-title="descricao" item-value="idGrupoServicos"
                            id="grupoServicos"
                            v-tooltip="'F7-Cadastra um novo grupo de serviços.'"
                            label="Grupo de Serviços" v-model='subgrupoServicos.idGrupoServicos'
                            :error-messages="errors['subgrupoServicos.idGrupoServicos']"
                            no-data-text="Nenhum grupo de serviços cadastrado.">

                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo grupo de serviços.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialog = true"
                                            id="btnNovoGrupoServicos" v-show="temPermissao('01.12.01')">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialog" transition="dialog-top-transition">
                                    <GrupoServicosCreate @close-dialog="dialog = false"
                                        @cadastrouGrupoServicos="getGruposServicos()" />
                                </v-dialog>
                            </template>

                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field label="Descrição" id="descricao" type="text" maxlength="50"
                            v-model='subgrupoServicos.descricao'
                            :error-messages="errors['subgrupoServicos.descricao']" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnSubgrupoServicosGravar">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click.prevent='$router.go(-1)' v-if="showBtnGravar"
                    v-show="$route.name == 'SubgrupoServicos.Create' || $route.name == 'SubgrupoServicos.Update'">Voltar</v-btn>
                <v-btn color="primary" variant="elevated" v-if="showBtnGravar"
                    v-show="$route.name !== 'SubgrupoServicos.Create' && $route.name !== 'SubgrupoServicos.Update'"
                    @click.prevent='closeModalCadastroSubgrupoServicos()'>Fechar</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>