export class ProdutoService {
    static calculaPVenda = function(pCusto, margem, margemPorcentagem='M') {
        let pVenda = 0;
        
        if(margemPorcentagem == 'M') {
            pVenda = 1 / ((100 - margem) / 100) * pCusto;
        }
        else {
            pVenda = pCusto * (1 + (margem / 100));
        }
        
        return pVenda;
    };

    static calculaMargem = function(pCusto, pVenda) {
        let margem;

        if(pCusto == pVenda || pVenda == 0) {
            return 0;
        }

        margem = ((pVenda - pCusto ) / pVenda) * 100;

        return margem;
    }

    // static calculaPCusto = function(pVenda, margem) {
    //     let pCusto = pVenda / (1 + (margem / 100));
    //     return pCusto;
    // }

    static calculaPorcentagemPCusto = function(pCusto, pVenda)  {
        let porcentagemPCusto;

        porcentagemPCusto = ((pVenda*100)/ pCusto)-100;

        if(pVenda == 0) {
            porcentagemPCusto = 0;
        }

        return porcentagemPCusto;
    }


}


