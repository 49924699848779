import { defineStore } from 'pinia';

export const useConfiguracoesProdutos = defineStore('configuracoesProdutos', {
    state: () => {
        return {
            jaCarregado: false,
            margemPorcentagem: '',
        }
    },
    actions : {
        setJaCarregado(jaCarregado) {
            this.jaCarregado = jaCarregado;
        },
        setMargemPorcentagem(margemPorcentagem) {
            this.margemPorcentagem  = margemPorcentagem;
        },
    }
});