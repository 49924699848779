<script setup>
import { ref, computed, onMounted, inject, onUnmounted } from "vue";
import { PedidoCompra } from "@/resources/pedidoCompraResource";
import { Relatorio } from "@/resources/relatoriosResource";
import { Fornecedor } from "@/resources/fornecedorResource";
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import { usePedidosCompras } from "../../store/pedidosCompras";
import { valorFormat } from "../../services/filtros";
import FornecedorBusca from "../Fornecedores/FornecedorBusca.vue";
import AlterarCodigoPedidoCompra from "./AlterarCodigoPedidoCompra.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";

let msgConfirm = ref(null);
let snackBarApp = inject("snackBarApp");

let dialogFornecedor = ref(false);
let dialogAlterarCodigoPedido = ref(false);

const pedidosComprasStore = usePedidosCompras();
let pedidosCompras = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(pedidosComprasStore.currentPage);
const abertos = ref(pedidosComprasStore.abertos);
const cancelados = ref(pedidosComprasStore.cancelados);
const recebidos = ref(pedidosComprasStore.recebidos);
const dataInicial = ref(pedidosComprasStore.dataInicial);
const dataFinal = ref(pedidosComprasStore.dataFinal);
const idFornecedor = ref(pedidosComprasStore.idFornecedor);
const codigoFornecedor = ref(pedidosComprasStore.codigoFornecedor);
const razaoFornecedor = ref(pedidosComprasStore.razaoFornecedor);
const filtrarPor = ref(pedidosComprasStore.filtrarPor);
const codigo = ref(pedidosComprasStore.codigo);
const documento = ref(pedidosComprasStore.documento);
const auxCodigoAntigo = ref(null);
const auxIdPedidoCompra = ref(null);
// const auxIdCodigo = ref(null);

const itemsFiltrarPor = ref([
    { value: 'data', label: 'Data de Emissão' },
    { value: 'prazoEntrega', label: 'Prazo de Entrega' },
    { value: 'dataFaturamento', label: 'Data do Faturamento' },
    { value: 'dataEntrega', label: 'Data da Entrega' },
]);

const direction = computed(() => {
    return pedidosComprasStore.sort[0].direction;
});

const orderBy = computed(() => {
    return pedidosComprasStore.sort[0].property;
});

onMounted(() => {
    filtrar('geral');
});

async function destroy(idPedidoCompra) {
    let dados = new FormData();
    dados.append("idPedidoCompra", JSON.stringify(idPedidoCompra));

    try {
        const response = await PedidoCompra.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Pedido de compra excluído com sucesso.");
                filtrar();
            }
        }
    } catch (err) {
        console.log(err);
    }
}

function openModalDelete(lancamento) {
    msgConfirm.value
        .open(
            "Confirma Exclusão ?",
            "Confirma a exclusão desse pedido de compra ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                destroy(lancamento.idPedidoCompra);
            }
        });
}

async function filtrar(tipoFiltro, mudouPagina = false) {
    let start = pedidosComprasStore.start;
    let limit = pedidosComprasStore.limit;
    let filtro = '';
    let sort = JSON.stringify(pedidosComprasStore.sort);

    if(tipoFiltro == null || tipoFiltro == "" || tipoFiltro == undefined) {
        tipoFiltro = pedidosComprasStore.tipoFiltro;
    }
    pedidosComprasStore.setIdFornecedor(idFornecedor.value);
    pedidosComprasStore.setCodigo(codigo.value);
    pedidosComprasStore.setDocumento(documento.value);
    pedidosComprasStore.setCodigoFornecedor(codigoFornecedor.value);
    pedidosComprasStore.setRazaoFornecedor(razaoFornecedor.value);
    pedidosComprasStore.setDataInicial(dataInicial.value);
    pedidosComprasStore.setDataFinal(dataFinal.value);
    pedidosComprasStore.setAbertos(abertos.value);
    pedidosComprasStore.setCancelados(cancelados.value);
    pedidosComprasStore.setRecebidos(recebidos.value);
    pedidosComprasStore.setTipoFiltro(tipoFiltro);
    pedidosComprasStore.setFiltrarPor(filtrarPor.value);
            

    let auxDataInicial = null;
    let auxDataFinal = null;
    if (dataInicial.value != "") {
        auxDataInicial = Converte.datePtBrToIso(dataInicial.value); 
    }

    if (dataFinal.value != "") {
        auxDataFinal = Converte.datePtBrToIso(dataFinal.value); 
    }
    if(tipoFiltro == 'codigo') {
        filtro = codigo.value;
    }
    if(tipoFiltro == 'documento') {
        filtro = documento.value;
    }

    if(mudouPagina == false) {
        handleCurrentChange(1);
        return;
    }

    try {
        const response = await PedidoCompra.listar(start, limit, sort, auxDataInicial, auxDataFinal, abertos.value, 
            cancelados.value, recebidos.value, idFornecedor.value, filtro, tipoFiltro, filtrarPor.value);
        pedidosCompras.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}
function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    pedidosComprasStore.setCurrentPage(val);
    pedidosComprasStore.setStart(start);
    currentPage.value = val;
    filtrar(null, true);
}
function setSort(property) {
    let sort = pedidosComprasStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [
        {
            property: property,
            direction: direction,
        },
    ];

    pedidosComprasStore.setSort(aSort);
    filtrar();
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

function selecionouFornecedor(fornecedor) {
    if (fornecedor == null) {
        return;
    }

    codigoFornecedor.value = fornecedor.codigo;
    getFornecedor();
    dialogFornecedor.value = false;
}

async function getFornecedor() {
    idFornecedor.value = null;
    razaoFornecedor.value = "";

    if (codigoFornecedor.value.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", codigoFornecedor.value);
    dados.append("getInativos", true);
    dados.append("codigoModulo", "03.13");

    try {
        let response = await Fornecedor.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                document.getElementById("codigoFornecedor").focus();
                snackBarApp.value.open("O fornecedor não foi encontrado, tente outro código ou faça uma busca.");
                codigoFornecedor.value = "";
                return;
            }
            idFornecedor.value = response.data.data.idFornecedor;
            codigoFornecedor.value = response.data.data.codigo;
            razaoFornecedor.value = response.data.data.razao;
        }
    } catch (err) {
        console.log("Erro ao buscar o fornecedor: " + err);
    }
}

function openModalCancelar(lancamento) {
    msgConfirm.value
        .open(
            "Confirma Cancelamento ?",
                "Confirma o cancelamento desse pedido de compra ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                cancelar(lancamento.idPedidoCompra);
            }
        });
}

async function cancelar(idPedidoCompra) {
    let dados = new FormData();
    dados.append("idPedidoCompra", idPedidoCompra);

    try {
        const response = await PedidoCompra.cancelar(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("pedido de compra cancelado.");
                filtrar();
            } else {
                snackBarApp.value.open("Não foi possível cancelar o pedido de compra.");
            }
        }
    } catch (err) {
        console.log(err);
    }
}

async function imprimir(idPedidoCompra) {
    let nomeRel = 'Pedido Compra';

    let response = await Relatorio.get(nomeRel, idPedidoCompra);
    if (response.status == 200) {
        if(response.data.success == true) {
            let nomeArquivo = response.data.nomeArquivo;
            let url = config.baseUrlRel+nomeArquivo;

            window.open(url, '_blank');
            return;

        }
        if(response.data.success == false ) {
            snackBarApp.value.open(response.data.message);
            return;
        }

        snackBarApp.value.open("Não foi possível fazer a impressão do pedido de compra, tente novamente.");
    }
}


async function duplicar(idPedidoCompra) {
    let dados = new FormData();
    dados.append("idPedidoCompra", idPedidoCompra);

    let response = await PedidoCompra.duplica(dados);
    if (response.status == 200) {
        if(response.data.success == true) {
            snackBarApp.value.open("Pedido de compra duplicado com sucesso. O código do novo pedido é: " + response.data.codigo);
            filtrar();
            return; 
        }
        if(response.data.success == false ) {
            snackBarApp.value.open(response.data.message);
            return;
        }

        snackBarApp.value.open("Não foi possível duplicar o pedido de compra, tente novamente.");
    }
}

function estiloLancamento(situacao) {
    if(situacao == 'A') {
        return 'text-green';
    }
    if(situacao == 'C') {
        return 'text-red';
    }
}

document.addEventListener('keydown', handleKeydown);

function handleKeydown(event) {
    if(event.key === 'F5') {
        event.preventDefault();
    }

    if (event.key === 'F5' && document.activeElement.id === 'codigoFornecedor' ) {
        event.preventDefault();
        document.getElementById('btnBuscaFornecedor').click();
    }
}

onUnmounted(() => {
    document.removeEventListener('keydown', handleKeydown);
});


</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-row>
        <v-col>
            <h1>Pedidos de Compras de Fornecedores</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.13.01')" color="primary" :to="{ name: 'PedidosCompras.Create' }">Novo Pedido de Compra</v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="4">
            <v-card elevation="16" density="compact">
                <v-card-title>Filtrar Por</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select density="compact" label="Filtrar Por" v-model="filtrarPor" :items="itemsFiltrarPor"
                                item-title="label" item-value="value"></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="8">
            <v-card elevation="16" density="compact">
                <v-card-title color="primary">Mostrar</v-card-title>
                <v-card-text density="compact">
                    <v-row>
                        <v-col cols="4">
                            <v-checkbox density="compact" v-model="abertos" label="Em Aberto" @change="filtrar()"></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox density="compact" v-model="cancelados" label="Cancelados" @change="filtrar()"></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox density="compact" v-model="recebidos" label="Recebidos" @change="filtrar()"></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card elevation="16">
                <v-card-title>Filtro</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="2">
                            <Data compact label="Data Inicial" type="text" v-model="dataInicial"></Data>
                        </v-col>
                        <v-col cols="2">
                            <Data label="Data Final" type="text" v-model="dataFinal"></Data>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field id="codigoFornecedor" label="Cód.Fornecedor" type="text" 
                                v-model="codigoFornecedor" @blur="getFornecedor()"
                                v-tooltip="'F5-Abre a busca de fornecedores'"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Razão Social/Nome do Fornecedor" type="text" v-model="razaoFornecedor" readonly tabindex="-1">
                                <template v-slot:append>
                                    <v-tooltip text="Faz a busca de um fornecedor por nome fantasia/CNPJ.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" color="primary" @click="dialogFornecedor = true" id="btnBuscaFornecedor">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                    <v-dialog v-model="dialogFornecedor" transition="dialog-top-transition">
                                        <FornecedorBusca @selecionouFornecedor="selecionouFornecedor" 
                                            @close-dialog="dialogFornecedor = false" :select-inativo-bloqueado=true />
                                    </v-dialog>

                                    &nbsp;&nbsp;
                                    <v-btn color="primary" @click.prevent="filtrar('geral')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-card elevation="16">
                <v-card-title>Busca Rápida</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="5">
                            <v-text-field label="Código" type="text" v-model="codigo">
                                <template v-slot:append>
                                    <v-btn color="primary" @click.prevent="filtrar('codigo')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field label="Documento" type="text" v-model="documento">
                                <template v-slot:append>
                                    <v-btn color="primary" @click.prevent="filtrar('documento')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <p></p>
    <v-card elevation="16" class="mt-4">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>Situação</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                            Código
                            <v-icon v-if="orderBy == 'codigo'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('data')">
                            D.Emissão
                            <v-icon v-if="orderBy == 'data'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('prazoEntrega')">
                            Prazo Entrega
                            <v-icon v-if="orderBy == 'prazoEntrega'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('dataFaturamento')">
                            Data Faturamento
                            <v-icon v-if="orderBy == 'dataFaturamento'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('dataEntrega')">
                            Data Entrega
                            <v-icon v-if="orderBy == 'dataEntrega'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Valor Total</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigoFornecedor')">
                            Cód.Fornecedor
                            <v-icon v-if="orderBy == 'codigoFornecedor'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('razaoFornecedor')">
                            Razão Social/Nome do Fornecedor
                            <v-icon v-if="orderBy == 'razaoFornecedor'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('documento')">
                            Documento
                            <v-icon v-if="orderBy == 'documento'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>F.Pagamento</th>  
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in pedidosCompras" :key="o">
                    <td :class="estiloLancamento(o.situacao)">
                        <v-tooltip text="Continua o Pedido de Compra.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-invoice-edit-outline"
                                    v-show="temPermissao('03.13.02') && o.situacao !== 'R' && o.situacao !== 'C'"
                                    :to="{ name: 'PedidosCompras.Continuar', params: { id: o.idPedidoCompra } }">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Cancela o pedido de compra.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-cancel"
                                    v-show="temPermissao('03.13.04') && (o.situacao == 'R' || o.situacao == 'A' )"
                                    @click.prevent="openModalCancelar(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui o pedido de compra.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-trash-can"
                                    v-show="temPermissao('03.13.05')"
                                    @click.prevent="openModalDelete(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Consulta o pedido de compra.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-tab-search"
                                    v-show="temPermissao('03.13.03')"
                                    :to="{ name: 'PedidosCompras.Show', params: { id: o.idPedidoCompra } }"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Imprime o pedido de compra.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-printer-outline"
                                    v-show="temPermissao('03.13.08') && o.situacao !== 'C'"
                                    @click.prevent="imprimir(o.idPedidoCompra)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Duplica o pedido de compra.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-content-duplicate"
                                    v-show="temPermissao('03.13.06')"
                                    @click.prevent="duplicar(o.idPedidoCompra)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Altera o código do pedido de compra.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-pencil"
                                    v-show="temPermissao('03.13.07')"
                                    @click.prevent = "dialogAlterarCodigoPedido = true; auxIdPedidoCompra = o.idPedidoCompra; auxCodigoAntigo = o.codigo"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>
                    <td :class="estiloLancamento(o.situacao)">{{ o.situacaoExtenso }}</td>
                    <td :class="estiloLancamento(o.situacao)">{{ o.codigo }}</td>
                    <td :class="estiloLancamento(o.situacao)">{{ o.data }}</td>
                    <td :class="estiloLancamento(o.situacao)">{{ o.prazoEntrega }}</td>
                    <td :class="estiloLancamento(o.situacao)">{{ o.dataFaturamento }}</td>
                    <td :class="estiloLancamento(o.situacao)">{{ o.dataEntrega}}</td>
                    <td :class="estiloLancamento(o.situacao)">{{ valorFormat(o.valorTotal) }}</td>
                    <td :class="estiloLancamento(o.situacao)">{{ o.codigoFornecedor }}</td>
                    <td :class="estiloLancamento(o.situacao)">{{ o.razaoFornecedor }}</td>
                    <td :class="estiloLancamento(o.situacao)">{{ o.documento }}</td>
                    <td :class="estiloLancamento(o.situacao)">{{ o.formaPagamentoExtenso }}</td>
                </tr>
            </tbody>
        </v-table>
        <v-dialog v-model="dialogAlterarCodigoPedido" transition="dialog-top-transition" width="auto">
            <AlterarCodigoPedidoCompra :idPedidoCompra="auxIdPedidoCompra" :codigoAntigo="auxCodigoAntigo" 
                @close-dialog="dialogAlterarCodigoPedido = false" 
                @alterou-codigo="dialogAlterarCodigoPedido = false;filtrar()" />
        </v-dialog> 

    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage" :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.13.01')" color="primary" :to="{ name: 'PedidosCompras.Create' }">Novo Pedido de Compra</v-btn>
        </v-col>
    </v-row>
</template>