<script setup>
import { ref, onMounted, inject } from "vue";
import { Caixa } from "../../resources/caixaResource";
import {Conta } from "../../resources/contaResource";
import { useField, useForm } from "vee-validate";
import { string, object, addMethod } from "yup";
import { usePermissoes } from "../../store/permissoes";
import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";

const showBtnGravar = ref(true);
const snackBarApp = inject("snackBarApp");
const permissoesStore = usePermissoes();
const detalhesContaDestino = ref('');
const contasDestino = ref([]);

addMethod(string, "valor_maior_zero", function valorMaiorZero(message) {
    return string().test(
        "valor-maior-zero", // Name
        message, // Msg
        function (valor) {
            if(Converte.stringToFloat(valor) <= 0) {
                return false;
            }
            return true;
        }
    );
});

const validationSchema = object({
    transferenciaConta: object({
        idContaDestino: undefined, 
        data: string().required("A data é obrigatória"),
        valor: string().required("O valor é obrigatório").valor_maior_zero("O valor deve ser maior que zero"),
    }).required(),
});
const { handleSubmit, errors } = useForm({ validationSchema });

const transferenciaConta = ref({
    idContaDestino: useField("transferenciaConta.idContaDestino").value,
    data: useField("transferenciaConta.data").value,
    valor: useField("transferenciaConta.valor").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();

    getContas();

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    transferenciaConta.value.data = new Date().toLocaleDateString("pt-BR");
    transferenciaConta.value.valor = "R$ 0,00";
}

async function gravar(values) {
    let dados = new FormData();

    values.transferenciaConta.data = Converte.datePtBrToIso(values.transferenciaConta.data); 

    dados.append("dados", JSON.stringify(values.transferenciaConta));

    try {
        const response = await Caixa.transferenciaConta(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("A transferência não pode ser efetuada, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            snackBarApp.value.open("Transferência efetuada com sucesso.");
            cleanForm();
            getContas();
            
        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}

async function getContas() {
    const response = await Conta.getContas();
    contasDestino.value = response.data.data;

    if (contasDestino.value.length > 0 && (transferenciaConta.value.idContaDestino == null || transferenciaConta.value.idContaDestino == 0)) {
        transferenciaConta.value.idContaDestino = response.data.data[0].idConta;
    }

    changeConta();
}

async function changeConta() {
    let dados = new FormData();
    dados.append('codigoModulo', '03.01.03');
    dados.append('idConta', transferenciaConta.value.idContaDestino);
    try {
        const response = await Conta.getById(dados);
        if (response.status == 200) {
            let conta = response.data.data;

            detalhesContaDestino.value = 'Banco:'+conta.nomeBanco+' agência:'+conta.agencia+' nro.conta:'+conta.conta+' saldo atual:'+Converte.numberToCurrency(conta.saldo);
        }
    }
    catch (err) {
        console.log(err);
    }
}


</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2>Transferência do Caixa para Conta Bancária</h2>
        </v-card-title>
        <form ref="formTransferenciaContas" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <v-select
                            :items="contasDestino"
                            item-title="apelido"
                            item-value="idConta"
                            label="Conta de Destino"
                            v-model="transferenciaConta.idContaDestino"
                            @update:modelValue="changeConta"
                        ></v-select>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field
                            label="Detalhes da Conta de Destino"
                            id="detalhesContaDestino"
                            v-model="detalhesContaDestino"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <Data
                            label="Data"
                            id="data"
                            v-model="transferenciaConta.data"
                            :error-messages="errors['transferenciaConta.data']"
                            maxlength="10"
                        />
                    </v-col>
                    <v-col cols="3">
                        <moeda
                            label="Valor"
                            id="valor"
                            v-model="transferenciaConta.valor"
                            :error-messages="errors['transferenciaConta.valor']"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" v-show="showBtnGravar" type="submit" id="btnTransferencaContaGravar">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated"  @click.prevent="$router.go(-1)">Voltar</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>